import { useStore } from '#/stores'
import { fetchUserIdentity } from '#/utils/server'
import useSWR, { useSWRConfig } from 'swr'
import { unstable_serialize } from 'swr/infinite'
import { Avatar } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import LC from 'leancloud-storage';

interface RequestItem {
  requestId: string
  fromId: string
  avatarURL: string
  username: string
}

export default function FriendRequestList() {
  const User = LC.User.current()
  const { data: requests, isLoading } = useSWR(
    'friend-request-list',
    async () => {
      const requests = (
        await new LC.Query('_FriendshipRequest')
          .equalTo('friend', User)
          .equalTo('status', 'pending')
          .find()
      ).map(async request => {
        const fromId = request.get('user').id
        const { avatarURL, username } = await fetchUserIdentity(fromId)
        return {
          requestId: request.id!,
          fromId: request.get('user').id,
          avatarURL,
          username,
        }
      })
      return await Promise.all(requests)
    },
    { suspense: false },
  )

  return requests && requests.length && !isLoading ? (
    <div className='flex flex-col items-stretch gap-2 bg-orchid/10 p-4 rounded-3xl mb-4'>
      {requests.map(item => (
        <RequestListItem {...item} key={item.fromId} />
      ))}
    </div>
  ) : null
}

function RequestListItem({
  requestId,
  fromId,
  avatarURL,
  username,
}: RequestItem) {
  const sendMessage = useStore(state => state.sendMessage)

  const { mutate } = useSWRConfig()

  async function handleAccept() {
    await LC.Friendship.acceptRequest(requestId)
    console.log('接受好友请求成功')

    const content = '我们已经成为超级好友了，开始分享动态吧！🥳🥳'
    await sendMessage({
      toUserId: fromId,
      content: `<UserMessage>${content}</UserMessage>`,
    })
    await refreshList()
  }

  async function handleDecline() {
    await LC.Friendship.declineRequest(requestId)
    console.log('拒绝好友请求成功')
    await refreshList()
  }

  async function refreshList() {
    await Promise.all([
      mutate('friend-request-list'),
      mutate(unstable_serialize(index => `chatList-${index}`)),
    ])
  }

  return (
    <div className="flex justify-between items-center gap-4 w-full">
      <Link
        to={`/profile/${fromId}/?no-button=true`}
        className="z-0 shadow-neumorphism rounded-full"
      >
        <Avatar src={avatarURL} alt={username} size="lg" zoomed />
      </Link>

      <section className="flex justify-between w-full h-full text-sm">
        <div className="flex-1 flex flex-col justify-center items-start h-full w-28">
          <p className="font-semibold text-xs">{username}</p>
          <button className="text-[#338BF8] truncate text-xs ">
            申请加你为超级好友
          </button>
        </div>
        <div className="flex justify-center items-center gap-3 text-gray-50">
          <motion.button
            onTap={handleAccept}
            whileTap={{ scale: 0.8 }}
            className="rounded-full p-1 bg-[#66a9fc] shadow-neumorphism"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </motion.button>

          <motion.button
            onTap={handleDecline}
            whileTap={{ scale: 0.8 }}
            className="rounded-full p-1 bg-gray-400/50 shadow-neumorphism"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </motion.button>
        </div>
      </section>
    </div>
  )
}
