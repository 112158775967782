import { useCallback, useMemo } from 'react'
import { Avatar, Image } from '@nextui-org/react'
import clsx from 'clsx'
import { type PropsWithChildren } from 'react'
import { UserIdentity } from '#/types'
import type { MessageUnion, NotificationMessage, Quote, ReferMessage } from '../../types'
import LC from 'leancloud-storage'
import useSWRImmutable from 'swr/immutable'
import { motion } from 'framer-motion'
import { fetchUserIdentity } from '#/utils/server'

type LayoutPlace = 'center' | 'left' | 'right'

interface BoxProps<T extends MessageUnion> {
  message: T
  userIdentity?: UserIdentity
}

export default function DialogBox({ message }: { message: MessageUnion }) {
  const currentUser = LC.User.current()

  const { data: userIdentities } = useSWRImmutable<{ [memberId: string]: UserIdentity }>(
    currentUser ? `user-identities-${message.conversation.id}` : null
  )

  const layoutPlace = useMemo<LayoutPlace>(() => {
    if (!currentUser || !userIdentities || message.type === 'notification') return 'center'
    return message.sender === currentUser.id ? 'right' : 'left'
  }, [currentUser, userIdentities])

  const ContentPart = useCallback(() => {
    if (!userIdentities) return null

    switch (message.type) {
      case 'primary':
      case 'image':
        return (
          <BaseBox
            message={message}
            userIdentity={
              message.sender !== currentUser.id
                ? userIdentities[message.sender]
                : undefined
            }
          />
        )
      case 'refer':
        return (
          <ReferBox
            message={message}
            userIdentity={
              message.sender !== currentUser.id
                ? userIdentities[message.sender]
                : undefined
            }
          />
        )
      case 'notification':
        return (
          <NotificationBox kind={message.kind} content={message.content} />
        )
    }
  }, [userIdentities, currentUser])

  return (
    <LayoutWrapper place={layoutPlace} >
      <ContentPart />
    </LayoutWrapper>
  )
}

function BaseBox<T extends Exclude<MessageUnion, NotificationMessage>>({
  message,
  userIdentity,
  children: referPart
}: PropsWithChildren<BoxProps<T>>) {
  const isSelf = !userIdentity
  const isGroup = message.conversation.chatType !== 'private'
  const isImage = message.type === 'image'
  const showingFromAI = message.type === 'primary' && message.tag === 'fromAI'
  const showingAtAI = message.type === 'primary' && message.tag === 'toAI'
  const showUsername = !isSelf && isGroup
  const quote = (message.type === 'primary' || message.type === 'image') && message.quote

  return (
    <>
      {!isSelf ? <UserAvatar userIdentity={userIdentity} fromAI={showingFromAI} /> : null}
      <section className="relative flex flex-col gap-1 z-0 max-w-[72vw]">
        {referPart}
        {showUsername ? <p className="text-xs text-gray-400">{userIdentity.username}</p> : null}
        <motion.div
          whileTap={{ scale: .95, opacity: .6 }}
          datatype='dialog'
          data-kind={message.type}
          data-message={JSON.stringify(message)}
          className={clsx(
            isSelf
              ? 'bg-orchid/80 backdrop-blur'
              : 'bg-gray-50/80 ring-1 ring-orchid/10',
            isImage
              ? 'overflow-hidden max-w-[40vw]'
              : 'px-3 py-2',
            'relative rounded-2xl flex flex-col justify-between gap-2 shadow-neumorphism',
          )}
        >
          {quote ? <QuotePart quote={quote} isSelf={isSelf} /> : null}
          {isImage
            ? <ChatImage src={message.content} alt='img' unTouchable />
            : <p className={clsx(
              isSelf ? "text-gray-50" : "text-gray-800",
              "text-sm align-middle whitespace-pre-line pointer-events-none"
            )}>
              {message.content}
              {showingAtAI ? (
                <span className={clsx(
                  isSelf
                    ? 'text-gray-50/90 bg-gray-50/20'
                    : 'text-gray-800/50 bg-gray-800/10',
                  "rounded-full px-1.5 py-0.5 ml-1 text-xs"
                )}>
                  @AI
                </span>
              ) : null}
            </p>
          }
        </motion.div>
      </section>
    </>
  )
}

function ReferBox({ userIdentity, message }: BoxProps<ReferMessage>) {
  const isSelf = !userIdentity

  return (
    <BaseBox
      message={message}
      userIdentity={userIdentity}
    >
      <div className={clsx({ 'self-end': isSelf }, 'w-[24vw] space-y-2')}>
        <p className="text-xs text-gray-800/80 text-center">
          回应了{isSelf ? ' Ta ' : '你'}的动态
        </p>
        <motion.div whileTap={{ scale: .95 }}>
          <ChatImage src={message.referTo.imageURL} alt='refer' />
        </motion.div>
      </div>
    </BaseBox>
  )
}

function NotificationBox({
  kind,
  content,
}: {
  kind: NotificationMessage['kind']
  content: string
}) {
  return (
    <p
      className={clsx(
        {
          'bg-gray-500/50': kind === 'invite' || kind === 'init',
          'bg-red-800/50': kind === 'remove',
        },
        'text-xs text-gray-50/80 px-2.5 py-1 rounded-xl backdrop-blur',
      )}
    >
      {content}
    </p>
  )
}

function LayoutWrapper(
  {
    place,
    children
  }: PropsWithChildren<{
    place: 'center' | 'left' | 'right'
  }>
) {
  return (
    <main className={clsx({
      'justify-end': place === 'right',
      'justify-center items-center': place === 'center'
    },
      'w-full flex gap-2')}
    >
      {children}
    </main>
  )
}

function UserAvatar({
  userIdentity,
  fromAI
}: {
  userIdentity: UserIdentity
  fromAI: boolean
}) {
  return (
    <section
      datatype='avatar'
      data-user={userIdentity.id}
      className="relative self-start flex flex-col gap-0.5 justify-center items-center mr-[0.3rem]">
      <Avatar
        css={{ 'z-index': 0 }}
        src={userIdentity.avatarURL}
        alt="name"
        size="md"
        zoomed
        className='pointer-events-none'
      />
      {fromAI ? (
        <span className='absolute -bottom-2 -right-2 grid place-content-center h-6 aspect-square z-10 rounded-full font-mono text-xs align-middle text-center text-gray-50 bg-orchid/30 backdrop-blur-3xl ring-2 ring-orchid/10 pointer-events-none'>
          AI
        </span>
      ) : null}
    </section>
  )
}

function ChatImage({ src, alt, unTouchable }: { src: string, alt: string, unTouchable?: boolean }) {
  return (
    <div datatype='fullscreen-image' data-src={src} className={unTouchable ? 'pointer-events-none' : ''}>
      <Image
        className="w-full ring-1 ring-slate-50/20 rounded-2xl pointer-events-none"
        src={src}
        alt={alt}
        objectFit="cover"
        autoResize
        showSkeleton
      />
    </div>
  )
}

function QuotePart({ quote, isSelf }: { quote: Quote, isSelf: boolean }) {
  const { data: userIdentity } = useSWRImmutable(`user-identities-${quote.sender}`, async () => await fetchUserIdentity(quote.sender))
  if (!userIdentity) return null

  return (
    <div className={clsx(isSelf ? 'text-gray-50' : 'text-gray-500', 'flex gap-1 rounded-xl pointer-events-none')}>
      <div className={clsx(isSelf ? 'bg-gray-50/20' : 'bg-gray-800/10', 'w-1 my-1 rounded-full')} />
      <div className='flex-1 p-1 rounded-lg space-y-1'>
        <p className='text-xs font-medium'>
          {userIdentity.id === LC.User.current().id ? '自己' : userIdentity.username} :
        </p>
        {quote.type === 'image' ? (
          <div className='pointer-events-auto max-w-[30vw]'>
            <ChatImage src={quote.content} alt='quote' />
          </div>
        ) : (
          <p className='text-xs whitespace-pre-line'>{quote.content}</p>
        )}
      </div>
    </div>
  )
}