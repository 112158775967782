import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { fetchUserIdentity, fetchUserProfile } from '#/utils/server'
import { useStore } from '#/stores'
import { Checkbox, Modal, Loading, Input } from '@nextui-org/react'
import { ConversationBase, TextMessage, Event } from 'leancloud-realtime'
import type { UserIdentity, UserProfile } from '#/types'
import BottomUpModal from '#/components/bottom-up-modal'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import UserProfileCard from '#/components/user-profile-card'
import RequestFriendButton from '#/components/request-friend-button'
import useSWRImmutable from 'swr/immutable'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import uniqBy from 'lodash/uniqBy'
import Image from '#/components/next-image'
import LC from 'leancloud-storage';
import PageLoading from '#/components/page-loading'
import { UserMinusIcon, UserPlusIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

const EMPTY_CANDIDATE_MSG = '没有更多能拉进群的好友啦！'
const NO_ONE_TO_DELETE_MSG = '群里没其他真人啦！'

export default function GroupSettingsModal({
  className,
  conversation,
  modalVisible,
  printLocalMessage,
  setModalVisible,
}: {
  className?: string
  conversation: ConversationBase
  modalVisible: boolean
  printLocalMessage: (message: TextMessage) => Promise<void>
  setModalVisible: (visible: boolean) => void
}) {
  const myProfile = useStore(state => state.myProfile)

  const currentUser = LC.User.current()
  const isOwner = useMemo(() => currentUser.id === conversation.creator, [conversation])

  const navigate = useNavigate()

  const [userProfile, setUserProfile] = useState<UserProfile>()

  async function friendProfileListFetcher() {
    const query = new LC.Query('_Followee')
    query.equalTo('user', currentUser)
    query.equalTo('friendStatus', true)
    const results = await query.find()
    return await Promise.all(
      results.map(result => fetchUserIdentity(result.get('followee').id)),
    )
  }

  async function memberProfileListFetcher() {
    return await Promise.all(
      conversation.members.map(member => fetchUserIdentity(member)),
    )
  }

  const { data: friendProfileList, isLoading } = useSWRImmutable(
    `friendProfileList-${conversation.id}`,
    friendProfileListFetcher,
  )

  const { data: memberProfileList, mutate: mutateMemberList } = useSWRImmutable(
    `memberProfileList-${conversation.id}`,
    memberProfileListFetcher,
    { suspense: true },
  )

  const memberIds = useMemo(() => {
    return memberProfileList?.map(member => member.id)
  }, [memberProfileList])

  const groupCandidate = useMemo(() => {
    return friendProfileList?.filter(friend => !memberIds?.includes(friend.id))
  }, [friendProfileList, memberIds])

  const AIs = useMemo(() => {
    return conversation._attributes.attributes.AIs
  }, [conversation])

  const deleteCandidate = useMemo(() => {
    return memberProfileList?.filter(
      member => member.id !== currentUser.id && !AIs.includes(member.id),
    )
  }, [memberProfileList, AIs])

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  useEffect(() => {
    if (!conversation) return

    conversation.on(
      Event.MEMBERS_JOINED,
      async (payload: { members: string[]; invitedBy: string }) => {
        mutateMemberList(
          async () => {
            return [
              ...memberProfileList,
              ...(await Promise.all(
                payload.members.map(member => fetchUserIdentity(member)),
              )),
            ]
          },
          { revalidate: false },
        )
      },
    )

    conversation.on(
      Event.MEMBERS_LEFT,
      async (payload: { members: string[]; kickedBy: string }) => {
        mutateMemberList(
          async () => {
            return memberProfileList.filter(
              member => !payload.members.includes(member.id),
            )
          },
          { revalidate: false },
        )
      },
    )

    conversation.on(Event.KICKED, () => {
      toast('你已被移出本群')
      navigate('/chat-list', { replace: true })
    })

    return () => {
      conversation.off(Event.MEMBERS_JOINED)
      conversation.off(Event.MEMBERS_LEFT)
      conversation.off(Event.KICKED)
    }
  }, [conversation, memberProfileList])

  async function addMembers() {
    await conversation.add(selectedIds)

    // send add message to group chat
    const userNames = selectedIds
      .map(id => friendProfileList?.find(f => f.id === id)?.username)
      .join('、')

    const msg = new TextMessage(
      `<Notification kind="invite">「${myProfile.username}」邀请「${userNames}」加入了群聊</Notification>`,
    )
    const rawMessage = await conversation.send(msg)
    await printLocalMessage(rawMessage)

    setSelectedIds([])
  }

  async function removeMembers() {
    await conversation.remove(selectedIds)

    // send remove message to group chat
    const userNames = selectedIds
      .map(id => friendProfileList?.find(f => f.id === id)?.username)
      .join('、')
    const msg = new TextMessage(
      `<Notification kind="remove">「${userNames}」离开了群聊</Notification>`,
    )
    const rawMessage = await conversation.send(msg)
    await printLocalMessage(rawMessage)
    setSelectedIds([])
  }

  async function quitConversation() {
    await conversation.quit()
    navigate('/chat-list', { replace: true })
  }

  async function deleteConversation() {
    await conversation.remove(conversation.members)
    navigate('/chat-list', { replace: true })
  }

  async function handleDeleteConfirmSubmit() {
    setModalVisible(false)
    isOwner ? deleteConversation() : quitConversation()
  }

  const [bottomUpModalVisible, setBUMVisible] = useState(false)
  type ModalType = '' | 'AddMember' | 'RemoveMember' | 'Delete' | 'ChangeGroupName' | 'Profile'
  const [modalType, setModalType] = useState<ModalType>('')

  const ButtonModalContent = useCallback((modalType: ModalType) => {
    switch (modalType) {
      case 'ChangeGroupName':
        async function handleChangeGroupName(newValue: string) {
          if (conversation.name === newValue) return
          conversation.name = newValue
          await conversation.save()
        }

        return (
          <section className="p-5 flex flex-col justify-center gap-4">
            <title className="text-base grid place-items-center">
              设置群名称
            </title>
            <Input
              id='change-group-name'
              autoFocus
              onKeyDown={(e) => {
                if (e.key !== 'Enter') return
                setBUMVisible(false)
                handleChangeGroupName(e.currentTarget.value)
              }}
              enterKeyHint='done'
              onFocus={(e) => {
                e.currentTarget.value = conversation.name
              }}
              aria-label='groupName'
              className='contrast-150 shadow-neumorphism focus:shadow-neumorphism-inner'
            />
            <div className='self-end space-x-2'>
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => setBUMVisible(false)}
                className="bg-gray-300/80 text-sm text-gray-800 w-20 py-2 rounded-2xl"
              >
                取消
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setBUMVisible(false)
                  const input = document.querySelector('#change-group-name') as HTMLInputElement
                  if (!input) return
                  handleChangeGroupName(input.value)
                }}
                className="w-20 py-2 bg-orchid text-gray-50 rounded-2xl text-sm"
              >
                确认
              </motion.button>
            </div>
          </section>
        )
      case 'Profile':
        return (
          <Suspense fallback={<PageLoading />}>
            {userProfile ? (
              <section className="relative w-full h-full overflow-y-scroll scrollbar-hide text-left">
                <UserProfileCard userProfile={userProfile} noPaddingBottom userId={userProfile.User.objectId} />
                <section className="absolute bottom-10 w-full px-10">
                  <RequestFriendButton
                    className="w-full"
                    userId={userProfile.User.objectId}
                  />
                </section>
              </section>
            ) : null}
          </Suspense>
        )
      case 'Delete':
        return (
          <section className="p-5 flex flex-col justify-center gap-4">
            <title className="text-base grid place-items-center">
              确认{isOwner ? '解散' : '退出'}本群?
            </title>
            <div className="flex justify-center items-center gap-6">
              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={() => setBUMVisible(false)}
                className="bg-gray-300/80 text-sm text-gray-800 w-20 py-2 rounded-2xl"
              >
                取消
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={handleDeleteConfirmSubmit}
                className="bg-red-200 text-sm text-red-500 w-20 py-2 rounded-2xl"
              >
                确认
              </motion.button>
            </div>
          </section>
        )
      case 'AddMember':
      case 'RemoveMember':
        let candidate: UserIdentity[] = groupCandidate || []
        let msg: string = EMPTY_CANDIDATE_MSG
        let confirmMsg = '确认添加'
        let setMembers = addMembers
        if (modalType === 'RemoveMember') {
          candidate = deleteCandidate
          msg = NO_ONE_TO_DELETE_MSG
          confirmMsg = '确认移除'
          setMembers = removeMembers
        }
        return (
          <section className="px-5 py-5 flex flex-col justify-center gap-4">
            <title className="text-base grid place-items-center">
              {modalType === 'AddMember' ? '选择好友' : '移除群友'}
            </title>
            {candidate.length === 0 ? (
              <p className="w-full text-center">{msg}</p>
            ) : (
              <>
                <div className='w-full rounded-2xl overflow-y-auto scrollbar-hide'>
                  <Checkbox.Group
                    css={{
                      height: '50vh',
                      paddingLeft: '0.1rem',
                      borderRadius: '$xl',
                    }}
                    value={selectedIds}
                    onChange={setSelectedIds}
                  >
                    {candidate.map(({ id, avatarURL, username }) => (
                      <div className='w-full flex items-center gap-2 rounded-2xl p-4 mb-2 bg-gray-200'>
                        <Checkbox key={id} value={id} css={{ w: 'stretch' }}>
                          <Image
                            src={avatarURL}
                            alt="user avatar"
                            className="w-[30px] aspect-square rounded-full border-[1px] border-white object-cover mr-2"
                          />
                          <p className="text-base">{username}</p>
                        </Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </div>
                <div className='self-end space-x-2'>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setBUMVisible(false)}
                    className="bg-gray-300/80 text-sm text-gray-800 w-20 py-2 rounded-2xl"
                  >
                    取消
                  </motion.button>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      setBUMVisible(false)
                      setMembers()
                    }}
                    className="w-20 py-2 bg-orchid text-gray-50 rounded-2xl text-sm"
                  >
                    {confirmMsg}
                  </motion.button>
                </div>
              </>
            )}
          </section>
        )
      default:
        return null
    }
  }, [setBUMVisible, setSelectedIds, selectedIds, userProfile])

  async function handleOpenProfile(id: string) {
    const userProfile = await fetchUserProfile(id)
    setUserProfile(userProfile)
    setModalType('Profile')
    setBUMVisible(true)
  }

  if (isLoading)
    return (
      <section className="absolute top-3 right-4 min-w-fit grid place-items-center rounded-full p-2 bg-gradient-to-tr from-[#8F9BB3] to-[#B7DBFF] active:shadow-neumorphism-inner text-gray-50 text-center">
        <Loading color="currentColor" size="xs" />
      </section>
    )

  return (
    <main className={className}>
      <Modal
        aria-labelledby="modal-title"
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        css={{ margin: '1rem', background: '#f7fbff',borderRadius:'1.5rem' }}
      >
        <Modal.Header css={{ padding: 0 }}>
          <motion.div
            onTap={() => {
              if (!isOwner) return
              setModalType('ChangeGroupName')
              setBUMVisible(true)
            }}
            whileTap={{ scale: 0.95 }}
            className='h-10 pt-2 flex items-center gap-1 text-gray-800'
          >
            <p className='truncate max-w-[50vw]'>{conversation.name}</p>
            {isOwner ? <PencilSquareIcon className='h-5' /> : null}
          </motion.div>
        </Modal.Header>
        <Modal.Body
          css={{
            padding: '.6rem .7rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '.2rem',
          }}
        >
          <section className="relative overflow-hidden px-2 pt-4 rounded-3xl shadow-neumorphism bg-white bg-gradient-to-br from-cloud/30 to-cloud/10 flex flex-col justify-center items-center">
            <MemberSlide
              memberList={memberProfileList}
              handleOpenProfile={handleOpenProfile}
            />
            <EditMemberButtonGroup
              hasRemoveButton={isOwner && deleteCandidate.length > 0}
              onAddClick={() => {
                setModalType('AddMember')
                setSelectedIds([])
                setBUMVisible(true)
              }}
              onRemoveClick={() => {
                setModalType('RemoveMember')
                setSelectedIds([])
                setBUMVisible(true)
              }}
            />
          </section>

          <motion.section
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              setModalType('Delete')
              setBUMVisible(true)
            }}
            className="w-full py-[6px] rounded-2xl bg-red-200 text-red-500 text-center text-sm"
          >
            {isOwner ? '解散群聊' : '退出群聊'}
          </motion.section>
        </Modal.Body>
        <BottomUpModal visible={bottomUpModalVisible} setVisible={setBUMVisible}>
          {ButtonModalContent(modalType)}
        </BottomUpModal>
      </Modal>
    </main>
  )
}

function MemberSlide({
  memberList,
  handleOpenProfile,
}: {
  memberList: UserIdentity[]
  handleOpenProfile: (id: string) => Promise<void>
}) {
  // member
  const memberPerPage = 10

  const [memberPages, setMemberPages] = useState<UserIdentity[][]>([])

  useEffect(() => {
    const tempMemberPages: UserIdentity[][] = []
    const deduplicatedMemberList = uniqBy(memberList, el => el.id)
    let currPage: UserIdentity[] = []
    for (let member of deduplicatedMemberList) {
      if (currPage.length === memberPerPage) {
        tempMemberPages.push(currPage)
        currPage = []
      }
      currPage.push(member)
    }
    tempMemberPages.push(currPage)
    setMemberPages(tempMemberPages)
  }, [memberList])

  return (
    <Swiper
      className="w-full"
      style={{
        height:
          102 +
          (memberList.length > 5 ? 72 : 0) +
          (memberPages.length > 1 ? 15 : 0),
      }}
      pagination={true}
      modules={[Pagination]}
    >
      {memberPages.map((page, index) => (
        <SwiperSlide key={index}>
          <section className="grid grid-cols-5 grid-flow-row place-items-center w-full gap-y-2">
            {page.map(({ id, avatarURL, username }) => (
              <div
                key={id}
                className="flex flex-col justify-center items-center gap-1"
              >
                <Image
                  className="w-[45px] h-[45px] rounded-2xl object-cover"
                  onClick={() => handleOpenProfile(id)}
                  src={avatarURL}
                  alt="user avatar"
                />
                <p className="text-xs w-[45px] truncate text-center">
                  {username}
                </p>
              </div>
            ))}
          </section>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

function EditMemberButtonGroup({
  hasRemoveButton,
  onAddClick,
  onRemoveClick,
}: {
  hasRemoveButton: boolean
  onAddClick: () => void
  onRemoveClick: () => void
}) {
  return (
    <main className="absolute z-50 right-0 bottom-0 flex items-center gap-2 p-2">
      <motion.button
        onClick={onAddClick}
        whileTap={{ scale: .95, opacity: .8 }}
        className='grid place-content-center h-8 aspect-square rounded-full bg-orchid/20'
      >
        <UserPlusIcon className='h-5 text-orchid' />
      </motion.button>
      {hasRemoveButton ? (
        <motion.button
          onClick={onRemoveClick}
          whileTap={{ scale: .95, opacity: .8 }}
          className='grid place-content-center h-8 aspect-square rounded-full bg-red-400/20'
        >
          <UserMinusIcon className='h-5 text-red-400' />
        </motion.button>
      ) : null}
    </main>
  )
}
