import { Status } from '#/types'
import { useSWRConfig } from 'swr'
import { unstable_serialize } from 'swr/infinite'

export default function getKey(
  pageIndex: number,
  previousPageData: Status[],
  callback?: () => void
) {
  if (previousPageData && !previousPageData.length) {
    callback && callback()
    return null
  }

  if (pageIndex === 0) return `/moment?limit=10`

  const maxId = previousPageData[previousPageData.length - 1]?.messageId
  return `/moment?maxId=${maxId}&limit=10`
}

export function useMutateStatus() {
  const { mutate } = useSWRConfig()
  async function mutateStatus() {
    await mutate(unstable_serialize(getKey))
  }
  return mutateStatus
}