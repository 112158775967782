import Image from '#/components/next-image'
import React from 'react'
import ShareTutorialModal from '#/components/share-tutorial-modal'

import { motion } from 'framer-motion'

export default function ShareProfileButton({
  className,
}: {
  className?: string
}) {
  const [visible, setVisible] = React.useState(false)
  const handler = () => setVisible(true)
  const closeHandler = () => {
    setVisible(false)
  }
  return (
    <>
      <motion.button
        whileTap={{ scale: 0.95 }}
        onTap={handler}
        className={className}
      >
        <Image src="/share/share-banner.png" alt="share" />
      </motion.button>
      <ShareTutorialModal visible={visible} closeHandler={closeHandler} />
    </>
  )
}
