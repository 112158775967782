import { HistoryItem } from './types';
import { useStore } from '#/stores'
import type { ConversationDisplayInfo } from '#/app/chat-list/@history-list/types'
import type { ConvAttributes, GroupConvAttributes } from '#/types'
import { XML2JSON } from '#/utils'
import { fetchUserIdentity } from '#/utils/server'
import type { TextMessage } from 'leancloud-realtime'
import useSWRInfinite from 'swr/infinite'
import { useMemo } from 'react'
import LC from 'leancloud-storage';

export default function useHistoryList(fetchPerPage: number = 35) {
  const realtimeClient = useStore(state => state.realtimeClient)

  const userId = LC.User.current().id!

  const {
    data: lists,
    mutate: mutateChatList,
    setSize: setListSize,
    isValidating
  } = useSWRInfinite(
    pageIndex => {
      if (!realtimeClient || !userId) return null
      return `chatList-${pageIndex}`
    },
    chatListFetcher,
    {
      suspense: true,
      parallel: true,
    },
  )

  const chatList = useMemo(() => lists?.flat(), [lists])

  async function chatListFetcher(params: string) {
    const [, pageIndex] = params.split('-')

    const conversations = await realtimeClient
      .getQuery()
      .containsMembers([userId!])
      .notContainsIn('m', [`${userId!}--ai`])
      .notEqualTo('attributes.type', 'meme')
      .addDescending('lm')
      .skip(Number(pageIndex) * fetchPerPage)
      .limit(fetchPerPage)
      .withLastMessagesRefreshed(true)
      .find()

    const promiseList = conversations.map(async conversation => {
      const {
        members,
        lastMessage: raw,
        unreadMessagesCount,
        name,
        _attributes,
        id: conversationId,
      } = conversation

      if (!raw) return null
      const { text } = XML2JSON((raw as TextMessage).text)

      let content: string
      switch (true) {
        case text === '#test:starter#':
          content = '[ AI 正在自动对话 ]'
          break
        case (raw as TextMessage).text.endsWith('</Image>'):
          content = '[ 图片 ]'
          break
        default:
          content = text
          break
      }

      const lastMessage = {
        content,
        at: raw ? raw.timestamp : new Date(),
        unread: unreadMessagesCount.valueOf(),
      }

      const attributes = _attributes.attributes as ConvAttributes

      let displayInfo: ConversationDisplayInfo
      if (attributes.type === 'private') {
        const [toUserId] = members.filter(id => !id.startsWith(userId!))
        const { avatarURL, username } = await fetchUserIdentity(
          toUserId.replace('--ai', ''),
        )
        displayInfo = {
          id: toUserId,
          coverURL: avatarURL,
          displayName: username,
          type: 'private',
        }
      } else {
        displayInfo = {
          id: conversationId,
          coverURL: (attributes as GroupConvAttributes).coverURL,
          displayName: name,
          type: attributes.type,
        }
      }

      return { displayInfo, lastMessage }
    })

    return( await Promise.all(promiseList)).filter(i => i) as HistoryItem[]
  }

  return {
    chatList,
    mutateChatList,
    setListSize,
    isValidating,
  }
}
