import Image from '#/components/next-image'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

import onboardingBanner1 from '#/assets/onboarding/banner1.png'
import onboardingBanner2 from '#/assets/onboarding/banner2.png'
import onboardingBanner3 from '#/assets/onboarding/banner3.png'
import onboardingBanner4 from '#/assets/onboarding/banner4.png'

const images = [
  onboardingBanner1,
  onboardingBanner2,
  onboardingBanner3,
  onboardingBanner4,
]

export default function Page() {
  const navigate = useNavigate()

  function handleReachedEnd() {
    navigate('/login', { replace: true })
  }

  return (
    <Swiper
      className="w-full h-full !pt-safe"
      pagination={{
        renderBullet(_, className) {
          return `<span class="${className} !mb-[calc(env(safe-area-inset-bottom)+1rem)] last:hidden"></span>`
        },
      }}
      navigation
      modules={[Pagination]}
      onReachEnd={handleReachedEnd}
    >
      <section className="w-full h-full">
        {images.map((image, index) => (
          <SwiperSlide className="w-screen snap-start snap-always" key={image}>
            <Image src={image} alt={index.toString()} />
          </SwiperSlide>
        ))}
        <SwiperSlide className="w-screen snap-start snap-always">
          &nbsp;
        </SwiperSlide>
      </section>
      <SwiperButtonNext />
    </Swiper>
  )
}

function SwiperButtonNext() {
  const swiper = useSwiper()

  return (
    <motion.button
      onTap={() => swiper.slideNext()}
      whileTap={{ scale: 0.8 }}
      className="absolute bottom-12 right-6 z-40 rounded-full text-gray-50 bg-black p-3 mb-safe"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
        />
      </svg>
    </motion.button>
  )
}
