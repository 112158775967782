import { Avatar, Badge } from '@nextui-org/react'
import type { ConversationDisplayInfo } from '../types'
import ConditionalWrapper from '#/components/conditional-wrapper'
import LogoSingleColor from '#/components/logo-single-color'

export default function AvatarWithBadge({
  unread,
  displayInfo: {
    coverURL,
    displayName,
    type
  },
}: {
  unread: number
  displayInfo: ConversationDisplayInfo
}) {
  return (
    <ConditionalWrapper
      condition={!!unread}
      wrapper={children => (
        <Badge
          isInvisible={!unread}
          shape="circle"
          color="error"
          content={unread}
          disableOutline
          size="xs"
          css={{ fontWeight: '$normal', fontSize: '$xs' }}
        >
          {children}
        </Badge>
      )}
    >
      {coverURL
        ? <Avatar
          src={coverURL}
          alt={displayName}
          color={type !== 'private' ? 'gradient' : 'default'}
          bordered={type !== 'private'}
          size="xl"
          zoomed
        />
        : <div style={{ filter: `hue-rotate(${displayName.length * 30}deg)` }} className='grid place-content-center text-gray-100 border-2 border-gray-300 bg-orchid/50 h-[64px] aspect-square rounded-full'>
          <LogoSingleColor className='w-8' />
        </div>
      }

    </ConditionalWrapper>
  )
}
