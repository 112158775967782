export default function Loading() {
  return (
    <div className="w-full h-full p-6 pt-0 space-y-2">
      {new Array(10).fill('').map((_, index) => (
        <div key={index} className="flex gap-4 w-full pb-3 first:pt-2 border-b-[0.5px] border-gray-500/10">
          <div className="p-8 rounded-full bg-gradient-to-tr from-slate-200/80 to-slate-300/70 animate-pulse duration-300" />
          <div className="flex-1 rounded-2xl bg-gradient-to-tr from-slate-300/70 to-slate-200/80 animate-pulse duration-150" />
        </div>
      ))}
    </div>
  )
}
