import { useEffect, useRef } from 'react'
import { Network, Node } from 'vis-network'

interface Prop {
  nodes: Node[]
  handleClick?: (nodeId: string) => void
  handleOnDrag?: (nodeId: string) => void
  handleDragging?: (nodeId: string, nodePos: { x: number; y: number }) => void
  handleOffDrag?: (nodeId: string, nodePos: { x: number; y: number }) => void
  handleOnHold?: (nodeId: string) => void
  offset?: { x: number; y: number }
}

export const Bubbles = ({
  nodes,
  handleClick,
  handleOnDrag,
  handleDragging,
  handleOffDrag,
  handleOnHold,
  offset,
}: Prop) => {
  const container = useRef(null)

  const bubbleMargin = 20

  const options = {
    nodes: {
      borderWidth: 0,
      shadow: {
        enabled: true,
        x: 6,
        y: 6,
        size: 16,
        color: '#C9D9E8',
      },
      shape: 'circle',
      color: {
        background: 'rgba(255, 255, 255, 0.8)',
        highlight: { background: 'white', border: 'grey' },
      },
      font: { color: '#000' },
      widthConstraint: {
        maximum: 80,
      },
      margin: {
        top: bubbleMargin,
        right: bubbleMargin,
        bottom: bubbleMargin,
        left: bubbleMargin,
      },
      labelHighlightBold: false,
    },
    groups: {
      fixed: {
        fixed: {
          x: true,
          y: true,
        },
      },
    },

    interaction: {
      zoomView: false,
      dragView: false,
      multiselect: true,
    },

    physics: {
      stabilization: false,
      minVelocity: 0.05,
      solver: 'forceAtlas2Based',
      forceAtlas2Based: {
        gravitationalConstant: -10,
        centralGravity: 0.01,
        avoidOverlap: 1,
      },
    },
  }

  useEffect(() => {
    let network: Network

    if (container.current) {
      network = new Network(container.current, { nodes, edges: [{}] }, options)
      if (offset) network.moveTo({ offset })

      if (handleClick) {
        network.on('click', ({ nodes }) => {
          if (nodes.length) {
            handleClick(nodes[0])
          }
        })
      }

      if (handleOnDrag) {
        network.on('dragStart', ({ nodes }) => {
          if (nodes.length) handleOnDrag(nodes[0])
        })
      }

      if (handleDragging) {
        network.on('dragging', ({ nodes }) => {
          if (nodes.length) {
            handleDragging(
              nodes[0],
              network.canvasToDOM(network.getPosition(nodes[0])),
            )
          }
        })
      }

      if (handleOffDrag) {
        network.on('dragEnd', ({ nodes }) => {
          if (nodes.length) {
            handleOffDrag(
              nodes[0],
              network.canvasToDOM(network.getPosition(nodes[0])),
            )
          }
        })
      }

      if (handleOnHold) {
        network.on('hold', ({ nodes }) => {
          if (nodes.length) {
            console.log('hold')
            handleOnHold(nodes[0])
          }
        })
      }
    }

    return () => {
      if (network) network.destroy()
    }
  }, [container, nodes])

  return (
    <div className="ui-w-full ui-h-full ui-border-none" ref={container}></div>
  )
}
