import LikeAndComment from './components/like-and-comment'
import StatusImage from './components/status-image'
import EmptyStatusNotification from './components/empty-status-notification'
import { requestAPI } from '#/utils/useAPIResponse'
import useSWRInfinite from 'swr/infinite'
import React, { useMemo, useState } from 'react'
import getKey from '../status-swr'
import useFetchMore from '#/hooks/use-fetch-more'
import BoxLoading from '#/components/box-loading'
import { motion, AnimatePresence } from 'framer-motion'

export default function TimeLine() {
  const [reachedLast, setReachedLast] = useState(false)

  const { data, setSize, isValidating } = useSWRInfinite(
    (...args) => getKey(...args, () => setReachedLast(true)
    ),
    async path => await requestAPI(path,{needSession:true}),
    {
      suspense: true,
      revalidateOnMount: true,
    },
  )

  const statuses = useMemo(() => data?.flat(), [data])

  const fetchMoreRef = useFetchMore(async () => {
    await setSize(size => size + 1)
  })

  return (
    <main className='pt-safe'>
      <AnimatePresence initial={false} mode="popLayout" presenceAffectsLayout>
        {statuses?.map(status => (
          <motion.section
            className="w-full snap-always snap-start px-6 pb-12"
            key={status.id}
            initial={{ y: 200}}
            animate={{ y: 0 }}
            transition={{ duration: .4 }}
            exit={{ opacity: 0, scale: .5 }}
          >
            <StatusImage key={status.id} status={status} />
            <LikeAndComment status={status} />
          </motion.section>
        ))}
      </AnimatePresence>
      {!reachedLast ? (
        <div
          ref={fetchMoreRef}
          className="w-full h-[calc(100vw-3rem)] pb-12 grid place-content-center"
        >
          {isValidating ? <BoxLoading /> : null}
        </div>
      ) : (
        <EmptyStatusNotification />
      )}
    </main>
  )
}
