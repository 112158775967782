'use client'
import Image from '#/components/next-image'
import ShareTutorialModal from '#/components/share-tutorial-modal'
import clsx from 'clsx'
import React from 'react'
import { RoundedButton } from 'ui'

import shareIcon from '#/assets/icons/share.svg'

export default function ShareProfileButton({
  className,
}: {
  className?: string
}) {
  const [visible, setVisible] = React.useState(false)

  return (
    <>
      <RoundedButton
        className={clsx('py-3 px-5', className)}
        color="gradient"
        onClick={() => setVisible(true)}
      >
        <div className="w-full flex justify-center items-center gap-2">
          <Image src={shareIcon} alt="share" className="w-4 h-4" />
          <p className="text-sm align-middle">分享我的镜身</p>
        </div>
      </RoundedButton>
      <ShareTutorialModal
        visible={visible}
        closeHandler={() => {
          setVisible(false)
        }}
      />
    </>
  )
}
