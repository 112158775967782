import clsx from "clsx"
import { motion } from "framer-motion"
import { PhotoIcon } from '@heroicons/react/24/outline'
import React, { useState } from "react"
import LC from 'leancloud-storage'
import { toast } from "react-hot-toast"
import { readFileAsDataURL } from "#/utils"
import { Loading } from "@nextui-org/react"

export function CallAIButton({
  onTap,
  isActive,
}: {
  onTap: () => void
  isActive: boolean
}) {
  return (
    <motion.button
      onTap={onTap}
      whileTap={{ scale: .8 }}
      className={clsx(
        isActive
          ? 'bg-orchid-gradient text-gray-50 shadow-neumorphism'
          : 'bg-gray-50 text-orchid',
        'h-9 aspect-square p-1 rounded-full duration-300',
      )}
    >
      <svg
        className="h-full w-full fill-current"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillOpacity='.25'
          d="M15.4119 3.704C11.5719 3.704 8.46788 4.888 6.09988 7.32C3.85988 9.56 2.77188 12.376 2.77188 15.704C2.77188 19.288 3.89188 22.168 6.16388 24.28C8.43588 26.392 11.6039 27.448 15.6359 27.448C18.0679 27.448 20.3079 26.968 22.2919 26.072C24.4679 25.048 26.0999 23.512 27.2199 21.528H24.3719C23.5399 22.68 22.4519 23.608 21.0759 24.28C19.5719 24.952 17.7479 25.304 15.6359 25.304C12.4999 25.304 10.0999 24.536 8.40388 23.032C6.54788 21.368 5.65188 18.968 5.65188 15.8C5.65188 12.92 6.51588 10.584 8.27588 8.76C10.0359 6.904 12.3719 5.976 15.2519 5.976C17.9399 5.976 20.0839 6.744 21.6839 8.28C23.1239 9.688 23.8599 11.448 23.8599 13.496C23.8599 15.448 23.3159 17.112 22.2599 18.488C21.3639 19.672 20.4359 20.28 19.5079 20.28C18.9959 20.28 18.7719 20.056 18.7719 19.608C18.7719 19.288 18.8999 18.584 19.2199 17.496L21.4279 9.56H18.7399L18.2919 11.192C17.7479 9.688 16.7559 8.952 15.3479 8.952C13.4279 8.952 11.7639 9.816 10.3879 11.576C8.97988 13.304 8.27588 15.288 8.27588 17.528C8.27588 18.968 8.69188 20.152 9.58788 21.112C10.4839 22.072 11.6359 22.552 13.0439 22.552C14.5799 22.552 15.8599 21.848 16.8199 20.472C17.0119 21.816 17.7799 22.488 19.1239 22.488C20.9159 22.488 22.5479 21.56 23.9879 19.704C25.3959 17.784 26.1319 15.704 26.1319 13.464C26.1319 10.808 25.2679 8.568 23.5399 6.776C21.5559 4.728 18.8679 3.704 15.4119 3.704ZM15.8599 11.544C16.3399 11.544 16.7559 11.704 17.1079 12.088C17.4279 12.408 17.6199 12.792 17.6199 13.304C17.6199 13.496 17.4919 14.072 17.2359 14.968L16.5959 17.336C16.3399 18.168 15.8919 18.84 15.1879 19.384C14.5479 19.896 13.9079 20.152 13.2679 20.152C12.5639 20.152 12.0199 19.928 11.6999 19.48C11.3479 19.032 11.1879 18.424 11.1879 17.656C11.1879 16.056 11.6359 14.648 12.5959 13.4C13.5239 12.152 14.6119 11.544 15.8599 11.544Z"
        />
        <path d="M7.27437 24.1094C7.57125 24.1094 7.83948 24.0365 8.07906 23.8906C8.31865 23.7396 8.50875 23.5417 8.64937 23.2969C8.79521 23.0469 8.86812 22.7734 8.86812 22.4766C8.86812 22.1745 8.79521 21.901 8.64937 21.6562C8.50875 21.4115 8.31865 21.2161 8.07906 21.0703C7.83948 20.9245 7.57125 20.8516 7.27437 20.8516C6.98271 20.8516 6.71448 20.9245 6.46969 21.0703C6.2301 21.2161 6.0374 21.4115 5.89156 21.6562C5.75094 21.901 5.68062 22.1745 5.68062 22.4766C5.68062 22.7734 5.75094 23.0469 5.89156 23.2969C6.0374 23.5417 6.2301 23.7396 6.46969 23.8906C6.71448 24.0365 6.98271 24.1094 7.27437 24.1094ZM16.9062 14.7031H16.9844L19.5391 24H21.5625L18.0859 12.7266H15.9141L12.4375 24H14.3281L16.9062 14.7031ZM18.8203 19.5391H15.0781L14.7656 21.0391H19.1641L18.8203 19.5391ZM29.9834 24V22.375H27.6709V14.3516H29.9834V12.7266H23.4678V14.3516H25.7803V22.375H23.4678V24H29.9834Z" />
      </svg>
    </motion.button>
  )
}

export function SendImageButton({ handleSend }: { handleSend: (payload: string) => Promise<void> }) {
  const [preview, setPreview] = useState('')

  async function handleInputImage(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target
    if (!files || !files.length) return
    const [imageFile] = files
    const preview = await readFileAsDataURL(imageFile)
    setPreview(preview)

    try {
      const LCFile = await new LC.File(imageFile.name, imageFile)
        .save({
          keepFileName: true,
          user: LC.User.current(),
          key: 'conversation',
        })
      setTimeout(() => {
        setPreview('')
        handleSend(LCFile.url())
      }, 1000)
    } catch {
      setPreview('')
      toast.error('图片发送失败')
    }
  }

  return (
    <main className="relative">
      <motion.button whileTap={{ scale: .8 }} className="relative overflow-hidden h-9 grid place-content-center aspect-square bg-gray-50 shadow-neumorphism p-1 rounded-full">
        <PhotoIcon strokeWidth={2.5} className="h-6 w-6 text-orchid" aria-hidden />
        <input
          className="absolute inset-0 p-0 m-0 z-10 opacity-0"
          type="file"
          multiple={false}
          accept="image/png, image/jpeg, image/jpg, image/svg"
          onInput={handleInputImage}
        />
      </motion.button>
      {preview ? (
        <motion.section
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ duration: .5 }}
          className="absolute -top-24 left-0 h-20 aspect-square rounded-2xl overflow-hidden" >
          <img src={preview} alt='preview img' className="h-full w-full object-cover" />
          <div className="absolute inset-0 grid place-content-center font-mono text-xl text-gray-50/50 bg-gray-500/50">
            <Loading type="spinner" size="lg" color='currentColor' />
          </div>
        </motion.section>
      ) : null}
    </main>
  )
} 