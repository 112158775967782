import { Textarea, styled } from "@nextui-org/react"
import clsx from "clsx"
import { useRef, useState } from "react"

export default function TextareaWithSendButton({
  handleSend,
  placeholder,
  className,
}: {
  handleSend: (payload: string) => Promise<void>
  placeholder: string
  className?: string
}) {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [isBlur, setIsBlur] = useState(true)
  const [isShowingButton, setIsShowingButton] = useState(false)

  function handleSubmit() {
    if (!inputRef.current) return
    inputRef.current.focus()
    const content = inputRef.current.value
    if (!content.trim()) return
    handleSend(content)
    inputRef.current.value = ''
    setIsShowingButton(false)
  }

  return (
    <section
      className={clsx(
        'flex justify-center items-end gap-2 w-full rounded-3xl overflow-hidden',
        className,
      )}
    >
      <div
        className={clsx(
          { 'shadow-neumorphism': isBlur },
          'flex-1 rounded-3xl overflow-hidden',
          className,
        )}
      >
        <Textarea
          aria-label="Textarea with send button"
          ref={inputRef}
          placeholder={placeholder}
          onFocus={() => { setIsBlur(false) }}
          onBlur={() => { setIsBlur(true) }}
          onChange={e => setIsShowingButton(!!e.target.value)}
          bordered
          inputMode="text"
          shadow={false}
          fullWidth
          animated={false}
          minRows={1}
          maxRows={4}
          className={clsx(
            { 'shadow-neumorphism-inner': !isBlur },
            'bg-gray-50 text-gray-800/90 text-left border-0 focus:outline-none',
          )}
        />
      </div>
      <SendButton
        handleSubmit={handleSubmit}
        className={clsx(
          { 'hidden': !isShowingButton },
          'text-orchid delay-300',
        )}
      />
    </section>
  )
}

function SendButton({
  handleSubmit,
  className,
}: {
  handleSubmit?: () => void
  className?: string
}) {
  return (
    <div onClick={handleSubmit} className={className}>
      <SendButtonStyle>
        <svg
          data-name="Iconly/Curved/Lock"
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
        >
          <g transform="translate(2 2)">
            <path
              d="M19.435.582A1.933,1.933,0,0,0,17.5.079L1.408,4.76A1.919,1.919,0,0,0,.024,6.281a2.253,2.253,0,0,0,1,2.1L6.06,11.477a1.3,1.3,0,0,0,1.61-.193l5.763-5.8a.734.734,0,0,1,1.06,0,.763.763,0,0,1,0,1.067l-5.773,5.8a1.324,1.324,0,0,0-.193,1.619L11.6,19.054A1.91,1.91,0,0,0,13.263,20a2.078,2.078,0,0,0,.25-.01A1.95,1.95,0,0,0,15.144,18.6L19.916,2.525a1.964,1.964,0,0,0-.48-1.943"
              fill="currentColor"
            />
          </g>
        </svg>
      </SendButtonStyle>
    </div>
  )
}

const SendButtonStyle = styled('button', {
  border: 'none',
  padding: 0,
  margin: 0,
  width: 30,
  height: 30,
  flex: 'center',
  background: 'transparent',
  borderRadius: '$rounded',
  cursor: 'pointer',
  transition: 'opacity 0.25s ease 0s, transform 0.25s ease 0s',
  svg: {
    size: '100%',
    padding: '4px',
    transition: 'transform 0.25s ease 0s, opacity 200ms ease-in-out 50ms',
    // boxShadow: '0 5px 20px -5px rgba(0, 0, 0, 0.1)',
  },
  '&:hover': {
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.9)',
    svg: {
      transform: 'translate(24px, -24px)',
      opacity: 0,
    },
  },
})
