import Image from '#/components/next-image'
import emptyStatusImg from '#/assets/empty-status.png'
import shareButton from '#/assets/share-button.png'
import ShareTutorialModal from '#/components/share-tutorial-modal'
import { useState } from 'react'
import { motion } from 'framer-motion'

export default function EmptyStatusNotification() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className="w-full h-screen snap-always snap-start px-6 pb-20 flex flex-col justify-center items-center">
        <Image
          src={emptyStatusImg}
          alt="empty status"
          width={250}
          className="pb-10"
        />
        <div className="space-y-2 text-center">
          <p className="text-xl font-semibold">没有更多动态了!</p>
          <p className="text-sm text-gray-400">
            前往邀请好友成为你的 Mirro 超级好友
          </p>
        </div>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => setShowModal(true)}
          className="grid place-content-center"
        >
          <Image src={shareButton} alt="empty status" width={100} />
        </motion.button>
      </div>
      <ShareTutorialModal
        visible={showModal}
        closeHandler={() => setShowModal(false)}
      />
    </>
  )
}
