import { Link } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

export default function Layout() {
  return (
    <Link
      to="/chat-list"
      replace
      className="p-2 rounded-full overflow-hidden grid place-items-center bg-gradient-to-tr from-[#8F9BB3]/40 to-[#B7DBFF]/40 active:shadow-neumorphism-inner shadow-neumorphism backdrop-blur text-gray-50 text-center"
    >
      <ChevronLeftIcon className='h-5' />
    </Link>
  )
}
