'use client;'

import Image from '#/components/next-image'

export default function FlashLight({ className }: { className: string }) {
  return (
    <Image
      className={className}
      src="/camera/camera-flash-light.png"
      alt="flash light module"
    />
  )
}
