import publicImage from '#/assets/public-image.json'
import BoxLoading from '#/components/box-loading'
import Image from '#/components/next-image'
import UserProfileCard from '#/components/user-profile-card'
import { useStore } from '#/stores'
import type { UserProfile } from '#/types'
import clsx from 'clsx'
import { AnimatePresence, motion, useWillChange } from 'framer-motion'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import type { MatchLog } from './types'
import LC from 'leancloud-storage'
import { ChevronDownIcon, XCircleIcon } from '@heroicons/react/24/solid'
import BottomUpModal from '#/components/bottom-up-modal'

const LOOKING_FOR_MAP = ['兴趣交流', '寻求合作', '亲密关系'] as const
const LOOKING_FOR_MAP_XA = {
  XA: 'linear-gradient(to right, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%)',
  TechX: 'linear-gradient( 135deg, #52E5E7 10%, #130CB7 100%)',
  BioX: 'linear-gradient( 135deg, #70F570 10%, #49C628 100%)',
  DesignX: 'linear-gradient( 135deg, #FCCF31 10%, #F55555 100%)',
  SocioX: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
  HumanitieX: 'linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%)',
  SportX: 'radial-gradient( circle 325px at 19.2% 64.8%,  rgb(254,62,101) 15%, rgb(166,24,146) 85% )',
  FusionX: 'linear-gradient( 103.3deg,  rgb(230,203,187) 30%, rgb(230,156,193) 55.7%, rgb(146,167,221) 81.8% )'
} as const

type LookingFor = typeof LOOKING_FOR_MAP[number] | keyof typeof LOOKING_FOR_MAP_XA

export default function Page() {
  const [myProfile, sendMessage, getEditableACL] = useStore(state => [
    state.myProfile,
    state.sendMessage,
    state.getEditableACL,
  ])

  const currentUser = LC.User.current()
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([])
  const [swipeFlag, setSwipeFlag] = useState(0)
  const [isFetching, setIsFetching] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: isXAer } = useSWRImmutable<boolean>(currentUser ? 'isXAer' : null)

  const [isChangingLookingFor, setIsChangingLookingFor] = useState(false)

  const showingUser = useMemo<UserProfile['User']>(() => {
    if (userProfiles.length) {
      const { User } = userProfiles[0]
      return User
    } else {
      return {} as UserProfile['User']
    }
  }, [userProfiles])

  const { data: matchCount, mutate: mutateMatchCount } = useSWR<number>(currentUser ? 'matchCount' : null)

  const { data: lookingFor, mutate: mutateLookingFor } = useSWRImmutable<LookingFor>(
    myProfile?.objectId ? 'myLookingFor' : null,
    async () => {
      const myProfileQuery = await new LC.Query('UserProfile').get(
        myProfile.objectId,
      )
      return myProfileQuery?.get('lookingFor')
    },
  )

  // fetch List
  useEffect(() => { fetchUsers() }, [lookingFor])

  async function fetchUsers() {
    try {
      if (!matchCount) throw new Error('matchCount is null')

      setIsFetching(true)
      const data: UserProfile[] = await fetch(
        `${import.meta.env.VITE_PUBLIC_HOST}/match`,
        {
          headers: { 'session-token': currentUser.getSessionToken() },
          cache: 'no-cache',
        },
      ).then(res => res.json())

      setUserProfiles(data)
    } catch (error) {
      setUserProfiles([])
      console.log(error)
    } finally {
      setIsFetching(false)
    }
  }

  // swipe card
  useEffect(() => {
    if (!userProfiles.length) return
    setUserProfiles(userProfiles => userProfiles.slice(1))
  }, [swipeFlag])

  async function handleDislike() {
    if (isSubmitting) return
    setIsSubmitting(true)
    try {
      const editableACL = getEditableACL()
      await new LC.Object('Dislike')
        .set('fromUser', currentUser)
        .set('toUser', showingUser)
        .setACL(editableACL)
        .save()
      await updateMatchLog()
      setSwipeFlag(flag => (flag > 0 ? flag * -1 - 1 : flag - 1))
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  async function handleLike() {
    if (isSubmitting) return
    setIsSubmitting(true)
    try {
      // await currentUser.follow(showingUser.objectId);
      await LC.Friendship.request(showingUser.objectId)
      await updateMatchLog()
      await sendMessage({
        toUserId: showingUser.objectId,
        content: `<UserMessage need-ai-reply="true" hide="true">#test:starter#</UserMessage>`,
      })
      setSwipeFlag(flag => (flag < 0 ? flag * -1 + 1 : flag + 1))
      console.log('follow successful')
    } catch (error) {
      const err = error as any
      console.log('fail to follow\n', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  async function updateMatchLog() {
    await mutateMatchCount(async () => {
      const matchQuery = await new LC.Query('Match')
        .equalTo('User', currentUser)
        .first()
      if (!matchQuery) return
      const { count } = matchQuery.get('matchLog') as MatchLog
      const newCount = count - 1
      await matchQuery
        .set('matchLog', { timestamp: new Date(), count: newCount })
        .save()
      return newCount
    })
  }

  async function handleChangeLookingFor(newValue: LookingFor) {
    await mutateLookingFor(async () => {
      await LC.Object.createWithoutData('UserProfile', myProfile.objectId)
        .set('lookingFor', newValue)
        .save()
      return newValue
    })
  }

  const ChangeLookingForButton = useCallback(() => isXAer ? (
    <motion.button
      whileTap={{ scale: 0.95, opacity: .9 }}
      onAnimationComplete={() => setIsChangingLookingFor(true)}
      {...(lookingFor ? { style: { background: LOOKING_FOR_MAP_XA[lookingFor] } } : null)}
      className='relative w-32 py-2 px-3 rounded-3xl rounded-r-none bg-orchid ring-2 ring-gray-50/50 origin-right'
    >
      <p className='select-none text-center text-sm text-gray-50 font-semibold'>{lookingFor}</p>
      <ChevronDownIcon className='absolute right-2 inset-y-0 w-3 h-full pointer-events-none stroke-2' />
    </motion.button>
  ) : (
    <motion.button
      whileTap={{ scale: 0.95, opacity: .9 }}
      onAnimationComplete={() => setIsChangingLookingFor(true)}
      className='relative w-32 py-2 px-3 rounded-3xl rounded-r-none bg-orchid ring-2 ring-gray-50/50 origin-right'
    >
      <p className='select-none text-center text-sm text-gray-50 font-semibold'>{lookingFor}</p>
      <ChevronDownIcon className='absolute right-2 inset-y-0 w-3 h-full pointer-events-none stroke-2' />
    </motion.button>
  ), [lookingFor])

  const LookingForOptions = useCallback(() => (
    <div className='w-full grid grid-cols-3 flex-wrap gap-4'>
      {isXAer ? Object.entries(LOOKING_FOR_MAP_XA).map(([text, background]) => (
        <motion.button
          key={text}
          whileTap={{ scale: 0.95, background, color: 'white' }}
          onAnimationComplete={
            () => {
              if (text === lookingFor) return
              setIsChangingLookingFor(false)
              handleChangeLookingFor(text as LookingFor)
            }
          }
          style={{ background: text === lookingFor ? background : '' }}
          className={clsx(
            text === lookingFor
              ? "text-gray-50"
              : "bg-gray-300/50 text-gray-800",
            "w-24 py-2 px-3 rounded-3xl"
          )}
        >
          <p className={clsx({ 'font-semibold': text === lookingFor }, 'pointer-events-none text-sm')}>{text}</p>
        </motion.button>
      )
      ) : LOOKING_FOR_MAP.map(l => (
        <motion.button
          key={l}
          whileTap={{ scale: 0.95 }}
          onAnimationComplete={() => {
            if (l === lookingFor) return
            setIsChangingLookingFor(false)
            handleChangeLookingFor(l)
          }}
          className={clsx(
            l === lookingFor
              ? "bg-orchid text-gray-50"
              : "bg-gray-300/50 text-gray-800",
            "w-24 py-2 px-3 rounded-3xl"
          )}
        >
          <p className='pointer-events-none text-sm'>{l}</p>
        </motion.button>
      ))
      }
    </div>
  ), [isXAer, lookingFor, setIsChangingLookingFor])

  if (isFetching) return (
    <main className="fixed inset-0 z-40 flex items-center justify-center text-orchid">
      <BoxLoading />
    </main>
  )

  return (
    <main className="relative h-full w-full pt-safe">
      {userProfiles.length > 0 ? (
        <>
          <ul className="h-full w-full overflow-y-hidden m-0">
            <AnimatePresence mode="popLayout" presenceAffectsLayout>
              {userProfiles.map(userProfile => (
                <UserProfileLi
                  isSubmitting={isSubmitting}
                  userProfile={userProfile}
                  isLiked={swipeFlag > 0}
                  key={userProfile?.User?.objectId || userProfile.username}
                />
              ))}
            </AnimatePresence>
          </ul>

          <section className="fixed bottom-28 mb-safe w-full flex flex-col justify-center items-end gap-6 text-gray-50">
            <ChangeLookingForButton />
            <div className="w-full h-12 px-4 flex justify-center items-stretch gap-4">
              <motion.button
                onTap={handleDislike}
                whileTap={{ scale: .95, opacity: .9 }}
                className="py-3 px-6 gap-1 rounded-full flex justify-center items-center bg-gradient-to-tr from-[#8F9BB3] to-[#B7DBFF] shadow-neumorphism"
              >
                <p className='text-sm'>不感兴趣</p>
                <XCircleIcon className='w-6 h-6' />
              </motion.button>

              <motion.button
                onTap={handleLike}
                whileTap={{ scale: .95, opacity: .9 }}
                className="flex-1 py-3 px-6 rounded-full bg-orchid-gradient shadow-neumorphism flex justify-center items-center gap-2 origin-right"
              >
                <Image src="/icons/profile-friend.svg" alt="profile-friend" />
                <p className='text-sm'>使用 AI 化身一键搭讪</p>
              </motion.button>
            </div>
          </section>

          <BottomUpModal visible={isChangingLookingFor} setVisible={setIsChangingLookingFor}>
            <section className="p-5 flex flex-col justify-center gap-4">
              <title className="text-base grid place-items-center">
                你对什么感兴趣
              </title>
              <LookingForOptions />
            </section>
          </BottomUpModal>
        </>
      ) : (
        <NoMatchesLayer>
          {matchCount ? (
            <>
              <p className="w-full flex flex-col text-gray-800/50 text-center align-middle pointer-events-none">
                <span>这个板块下的人刷完啦</span>
                <span className='mb-4'>你可以再看看其他的</span>
              </p>
              <LookingForOptions />
            </>
          ) : (
            <p className="text-gray-800/50 text-center align-middle pointer-events-none">
              今日份 AI 给你推荐的潜在好友刷完啦
              <br />
              请明天再来试试咯！
            </p>
          )}
        </NoMatchesLayer>
      )}
    </main>
  )
}

function NoMatchesLayer({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-full h-full flex flex-col justify-between items-center pb-32">
      <Link to="/mirro">
        <Image src={publicImage.bannerInMatch} alt="banner in match" />
      </Link>
      <div className="flex flex-col items-center justify-center gap-3">
        {children}
      </div>
      <div />
    </div>
  )
}

function UserProfileLi({
  userProfile,
  isLiked,
  isSubmitting,
}: {
  userProfile: UserProfile
  isLiked?: boolean
  isSubmitting: boolean
}) {
  const willChange = useWillChange()

  return (
    <motion.li
      layout
      initial={{ scale: 0.8, opacity: 0, y: 800 }}
      animate={{ scale: 1, opacity: 1, y: 0 }}
      exit={{ opacity: 0.5, x: isLiked ? 400 : -400 }}
      transition={{
        type: 'spring',
        damping: 15,
        stiffness: 100,
        duration: 0.2,
        bounce: 0.25,
      }}
      style={{ willChange }}
      className={clsx(
        { 'animate-pulse': isSubmitting },
        'h-full w-full overflow-y-scroll scroll-smooth scrollbar-hide',
      )}
    >
      <UserProfileCard userProfile={userProfile} />
    </motion.li>
  )
}
