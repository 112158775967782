import EnToCn from '#/assets/column-title-map.json'
import Image from '#/components/next-image'
import { UserDeleteRequestDialogButton } from '#/components/user-delete-request'
import { useStore } from '#/stores'
import { UserProfile } from '#/types'
import { Input, Modal, Radio, Textarea } from '@nextui-org/react'
import dayjs from 'dayjs'
import { File as LCFile } from 'leancloud-storage'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BoxCard, RoundedButton } from 'ui'
import LC from 'leancloud-storage';
import Paragraph from '#/components/paragraph'

interface ProfileItem {
  key: string
  type: 'text' | 'date' | 'radio'
  required?: boolean
}

interface ProfileHook {
  profile: UserProfile
  isLoading: boolean
  updateProfile: (data: Partial<UserProfile>) => Promise<void>
}

const thumbnailSize = 200

const BasicProfileKeys: ProfileItem[] = [
  { key: 'username', type: 'text', required: true },
  { key: 'birthDay', type: 'date', required: true },
  { key: 'gender', type: 'radio', required: true },
  { key: 'school', type: 'text', required: true },
  { key: 'hometown', type: 'text', required: true },
  { key: 'mbti', type: 'text', required: true },
]

const DetailProfileKeys: ProfileItem[] = [
  { key: 'about', type: 'text' },
  { key: 'wish', type: 'text' },
  { key: 'more', type: 'text' },
]

export default function Page() {
  const [profile, isLoading, updateProfile] = useStore(state => [
    state.myProfile,
    state.isMyProfileLoading,
    state.updateProfile,
  ])

  const basicInfRef = React.useRef<HTMLDivElement>(null)

  const profileHook: ProfileHook = { profile, isLoading, updateProfile }

  // const [isBasicProfileFilled, setIsBasicProfileFilled] = React.useState(false);

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const firstEnter = searchParams.get('first')

  // useEffect(() => {
  //   if (!profile) return;

  //   const userId = LC.User.current().id;
  //   if (!userId) return;

  //   // determine whether all the basic-profile-keys are filled
  //   const filled =
  //     BasicProfileKeys.every((item) => (
  //       profile[item.key] && profile[item.key].length
  //     )) && profile.pictures.length != 0;

  //   setIsBasicProfileFilled(filled);

  // }, [profile, LC]);

  async function handleSubmit() {
    await handleSendFriendRequest()
    navigate('/discover', { replace: true })
  }

  async function handleSendFriendRequest() {
    const inviterId = localStorage.getItem('inviterId')
    if (!inviterId) return
    await LC.Friendship.request(inviterId)
    localStorage.removeItem('inviterId')
  }

  return profile ? (
    <main className="w-full overflow-y-scroll scroll-smooth scrollbar-hide flex flex-col gap-5 px-5 py-5 pb-24">
      <ImageCard
        profileHook={profileHook}
        instruction={
          <p className="w-full text-center text-sm text-gray-800/30 pt-5">
            请至少上传一张照片
            <span className="text-red-500/50 text-base">*</span>
          </p>
        }
      />

      <div ref={basicInfRef}>
        <InfoCard
          title={'基本信息'}
          display={'input'}
          profileKeys={BasicProfileKeys}
          profileHook={profileHook}
        />
      </div>

      <InfoCard
        title={'详细信息'}
        display={'textarea'}
        profileKeys={DetailProfileKeys}
        profileHook={profileHook}
        instruction="建议多于20字"
      />

      {firstEnter ? (
        <RoundedButton className="py-3" color="gradient" onClick={handleSubmit}>
          <p className="text-sm">确认资料，开启探索</p>
        </RoundedButton>
      ) : (
        <div>
          <UserDeleteRequestDialogButton />
        </div>
      )}
    </main>
  ) : null
}

function ImageCard({
  profileHook,
  instruction,
}: {
  profileHook: ProfileHook
  instruction: React.ReactNode
}) {
  const { profile, isLoading, updateProfile } = profileHook
  const [images, setImages] = React.useState<LCFile[]>([])
  const [fullImageURL, setFullImageURL] = React.useState<string>('')
  const [showModal, setShowModal] = React.useState(false)
  const suffixes = ['-', '-', '-', '-', '-', '+']

  React.useEffect(() => {
    if (!isLoading) {
      const pictures = profile.pictures as LCFile[]
      setImages(pictures)
    }
  }, [profile])

  async function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newImages = e.target.files

    async function fileMap(): Promise<LCFile[]> {
      if (!newImages) return []

      if (images.length + newImages.length > 6) {
        alert('最多只能上传6张图片')
        return []
      }

      const files: LCFile[] = new Array()
      for (let i = 0; i < newImages.length; i++) {
        const image = newImages[i]
        const file = await new LC.File(image.name, image).save()
        if (file) files.push(file)
      }
      return files
    }

    if (profile) {
      const files = await fileMap()
      let data = {} as Partial<UserProfile>
      if (images.length == 0 && files.length > 0) {
        data.avatarURL = files[0].thumbnailURL(thumbnailSize, thumbnailSize)
      }
      setImages(images => [...images, ...files])
      // NOTE - Hack to fix the type error that profile has no get function
      data.pictures = [...profile.pictures, ...files] as UserProfile['pictures']
      await updateProfile(data)
    }
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLImageElement
    if (target.tagName === 'IMG') {
      setFullImageURL(target.dataset.url!!)
      setShowModal(true)
    }
  }

  function handleChangeAvatar() {
    const fullImage = images.find(i => i.url() === fullImageURL)!!
    const thumbnailURL = fullImage.thumbnailURL(thumbnailSize, thumbnailSize)
    updateProfile({
      avatarURL: thumbnailURL,
    })
    setShowModal(false)
  }

  async function handleDeletePhoto() {
    try {
      const deletedImage = images.find(i => i.url() === fullImageURL)!!
      const remainImages = images.filter(i => i.url() !== fullImageURL)
      setImages(remainImages)
      await updateProfile({
        pictures: [...remainImages],
      })
      deletedImage.destroy()
      setShowModal(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Modal
        fullScreen
        aria-labelledby="full-screen-profile-picture"
        aria-describedby="modal-description"
        noPadding
        open={showModal}
        css={{
          borderRadius: 0,
          backgroundColor: 'rgba(0,0,0,0.9)',
          backdropFilter: 'blur(10px)',
          paddingBottom: '2.25rem',
        }}
      >
        <Modal.Body onClick={() => setShowModal(false)}>
          <div className="w-full h-full flex justify-center items-center">
            <Image src={fullImageURL} alt="full profile image" />
          </div>
        </Modal.Body>
        <Modal.Footer
          css={{ paddingLeft: '0.75rem', paddingRight: '0.75rem' }}
          className="gap-3"
        >
          <button
            className="w-full bg-white text-gray-800 py-4 rounded-2xl"
            onClick={handleChangeAvatar}
          >
            设置为头像
          </button>
          <button
            className="w-full bg-brandred/50 text-red-100 py-4 rounded-2xl"
            onClick={handleDeletePhoto}
          >
            删除此照片
          </button>
        </Modal.Footer>
      </Modal>
      <BoxCard isPressable={false}>
        <p className="text-sm text-gray-800 pl-1 pb-5">个人照片</p>
        <div
          className="grid grid-cols-3 grid-flow-row place-items-center gap-3"
          onClick={handleClick}
        >
          {images
            .filter(i => i)
            .map((image, index) => (
              <div key={index} className="relative w-24 h-24">
                <Image
                  className="rounded-2xl w-24 h-24 object-cover justify-self-center"
                  src={`${image.thumbnailURL(200, 200)}`}
                  alt="profile image"
                  data-url={`${image.url()}`}
                />
                {profile.avatarURL.split('?')[0] === image.url() && (
                  <div className="absolute z-10 right-0 top-0 translate-x-[10%] -translate-y-[10%] rounded-full flex justify-center items-center p-1 bg-[#8898D9]">
                    <Image
                      className="w-3 h-3"
                      src="/icons/profile.svg"
                      alt="profile"
                    />
                  </div>
                )}
              </div>
            ))}
          {suffixes.slice(images.filter(i => i).length).map((symbol, index) => {
            return (
              <div
                key={index}
                className="relative bg-transparent border-dashed border-2 border-black/10 rounded-2xl h-24 w-24 justify-self-center items-self-center flex justify-center items-center"
              >
                {symbol === '+' && (
                  <>
                    <form action=""></form>
                    <input
                      className="absolute opacity-[0.1%] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-20 h-20 overflow-hidden bg-transparent border-2"
                      type="file"
                      accept="image/*"
                      onInput={handleImageChange}
                    />
                    <Image
                      className="pointer-events-none w-14 h-14 opacity-10"
                      src="/icons/plus.svg"
                      alt="plus"
                    />
                  </>
                )}
              </div>
            )
          })}
        </div>
        {/* <div className="w-full text-center text-sm text-gray-800/30 pt-5"> */}
        {instruction}
        {/* </div> */}
      </BoxCard>
    </>
  )
}

function InfoBox<T>({
  title,
  profile,
  required,
  setProfile,
  setCanSubmit,
  handleSubmit,
  // isEditing,
  display,
  type,
}: {
  title: string
  profile: T
  required?: boolean
  setProfile: (profile: T) => void
  setCanSubmit: (canSubmit: boolean) => void
  handleSubmit: () => void
  // isEditing?: boolean;
  display: 'input' | 'textarea'
  type: 'text' | 'date' | 'radio'
}) {
  // NOTE - use useRef not useState to handle on change ON PURPOSE!!!
  const inputRef = React.useRef<HTMLTextAreaElement>(null)
  let content = profile[title] || ''
  if (type === 'date') {
    content = dayjs(new Date(content).getTime()).format('YYYY-MM-DD')
  }

  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = content
      if (isEditing) {
        inputRef.current.focus()
      }
    }
  }, [isEditing])

  function handleTextChange(
    e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    let value: string | Date = typeof e === 'string' ? e : e.target.value
    if (type === 'date') {
      value = dayjs(value).toDate()
    }
    setProfile({ ...profile, [title]: value })
    setCanSubmit(true)
  }

  function handleSubmitWrapper() {
    setIsEditing(false)
    handleSubmit()
  }

  return (
    <div className="row-auto grid grid-row-2 gap-1 min-h-11 text-sm">
      <p className="text-[#8F9BB3]">
        {EnToCn[title]}
        {required ? <span className="text-red-500/50">*</span> : null}
      </p>

      {isEditing ? (
        display === 'input' ? (
          type === 'radio' ? (
            <Radio.Group
              className="scale-90 saturate-0"
              orientation="horizontal"
              size="sm"
              value={content}
              onChange={c => {
                handleTextChange(c)
              }}
            >
              <div className="flex" onBlur={handleSubmitWrapper}>
                <Radio css={{ paddingRight: '1rem' }} value="男">
                  男
                </Radio>
                <Radio value="女">女</Radio>
              </div>
            </Radio.Group>
          ) : (
            <Input
              ref={inputRef}
              size="md"
              type={type}
              onChange={handleTextChange}
              onBlur={handleSubmitWrapper}
            />
          )
        ) : (
          <Textarea
            ref={inputRef}
            size="md"
            minRows={5}
            maxRows={7}
            onChange={handleTextChange}
            onBlur={handleSubmitWrapper}
          />
        )
      ) : (
        <div
          className="text-gray-800/90 min-h-[2rem]"
          onClick={() => setIsEditing(true)}
        >
          <Paragraph content={content} />
        </div>
      )}
    </div>
  )
}

interface InfoCardProps {
  title: string
  display: 'input' | 'textarea'
  profileKeys: ProfileItem[]
  profileHook: ProfileHook
  instruction?: string
}

function InfoCard({
  title,
  display,
  profileKeys,
  profileHook,
  instruction,
}: InfoCardProps) {
  const [canSubmit, setCanSubmit] = React.useState(false)

  const {
    profile: fullProfile,
    isLoading: isFullProfileLoading,
    updateProfile: updateFullProfile,
  } = profileHook

  const [workingProfile, setWorkingProfile] =
    React.useState<Partial<UserProfile>>()

  React.useEffect(() => {
    if (!isFullProfileLoading) {
      const newProfile = profileKeys.reduce((acc, { key }) => {
        acc[key] = fullProfile[key]
        return acc
      }, {})
      setWorkingProfile(newProfile as Partial<UserProfile>)
    }
  }, [fullProfile])

  function handleSubmit() {
    if (workingProfile && canSubmit) {
      updateFullProfile(workingProfile)
      setCanSubmit(false)
    }
  }

  return (
    <BoxCard isPressable={false}>
      <div className="px-2 text-base">
        <div className="w-full text-gray-800/ pb-3">{title}</div>
        <div
          className={`grid ${
            display === 'input' ? 'grid-cols-2' : ''
          } gap-x-4 gap-y-4 mt-3`}
        >
          {profileKeys.map(({ key, type, required }) => {
            return (
              workingProfile && (
                <InfoBox<Partial<UserProfile>>
                  key={key}
                  required={required}
                  title={key}
                  profile={workingProfile!!}
                  setProfile={setWorkingProfile}
                  setCanSubmit={setCanSubmit}
                  handleSubmit={handleSubmit}
                  type={type}
                  display={display}
                />
              )
            )
          })}
        </div>
      </div>
      {instruction && (
        <div className="w-full text-center text-sm text-gray-800/30 pt-5">
          {instruction}
        </div>
      )}
    </BoxCard>
  )
}
