import Eggplant from "#/components/eggplant-blob"
import { sigmoidModel } from "#/utils"

export default function IntimacyDisplay({
  disable,
  className,
  messageCount
}: {
  disable: boolean
  className: string
  messageCount?: number
}) {
  if (disable) return null

  const process = sigmoidModel((messageCount||0) * 2 / 15) * 0.012 + 0.1

  return (
    <section className={className}>
      <Eggplant
        progress={ process }
        className="w-24 aspect-square"
      />
    </section>
  )
}