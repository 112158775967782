import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useStore } from '#/stores'
import { getIsAppleFan, readFileAsDataURL } from '#/utils'
import clsx from 'clsx'
import { motion, useAnimate, useInView } from 'framer-motion'
import usePikaso from 'pikaso-react-hook'
import Webcam from 'react-webcam'
import useSWR from 'swr'
import { Loading } from '@nextui-org/react'
import { File as LCFile } from 'leancloud-storage'
import { type ConversationBase, Event, TextMessage } from 'leancloud-realtime'
import dayjs from 'dayjs'

import FlashLight from './components/flash-light'
import RedButton from './components/red-button'
import RedLight from './components/red-light'
import ViewFinder from './components/view-finder'

import styles from './index.module.css'
import checkIcon from '#/assets/icons/webcam-check.svg'
import toast from 'react-hot-toast'
import NextImage from '#/components/next-image'
import LC from 'leancloud-storage'
import { useMutateStatus } from '../status-swr'

type ClipConfig = [string, number, number, number, number]

const aspectRatio = 1
const cameraHeight = 720
const cameraWidth = cameraHeight * aspectRatio

export default function SnapCamera() {
  const [realtimeClient] = useStore(state => [
    state.realtimeClient,
  ])

  const User = LC.User.current()
  const [memeConversation, setMemeConversation] = useState<ConversationBase>()
  const requestMemeTimestamp = useRef<Date>()

  const needMemeTutorial = useRef(
    localStorage.getItem('needMemeTutorial') === 'true',
  )
  const [showMemeTutorial, setShowMemeTutorial] = useState(false)

  const mutateStatus = useMutateStatus()

  const { data: unread, isLoading: isLoadingStatusCount } = useSWR(
    'status-unread',
    async () => {
      try {
        // get yesterday as a Date object
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)

        const msgs = await LC.Status.inboxQuery(User)
          .notEqualTo('source', User)
          .greaterThan('createdAt', yesterday)
          .find()

        return msgs.length
      } catch (error) {
        console.log(error)
      }
    },
    { suspense: false }
  )

  const webcamRef = useRef<Webcam>(null)
  const finderCamRef = useRef<Webcam>(null)
  const [pikasoRef, pikasoEditor] = usePikaso({
    selection: {
      transformer: {
        borderStrokeWidth: 0,
        anchorFill: '#ffffff00',
        anchorStrokeWidth: 0,
        keepRatio: true,
      },
      zone: {
        fill: '#ffffff00',
        stroke: '#ffffff00',
      },
    },
  })

  const [hasScanLayer, setHasScanLayer] = useState(true)

  const [
    allowCameraOpen, setAllowCameraOpen
  ] = useState<'neither' | 'both' | 'finder'>('both')

  const [previewImage, setPreviewImage] = useState<{
    low: LCFile
    high: string
  }>()
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment')
  const [zoom, setZoom] = useState(1)
  const [loadingToastId, setLoadingToastId] = useState('')
  const [memes, setMemes] = useState<{
    imageContent: string
    memeTexts: string[]
  }>()
  const memeIndex = useRef(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isSending, setIsSending] = useState(false)

  const [scope, animate] = useAnimate()

  const videoConstraints = useMemo<MediaTrackConstraints>(
    () => ({
      width: cameraWidth,
      height: cameraHeight,
      aspectRatio,
      facingMode,
    }),
    [facingMode],
  )

  const containerRef = useRef<HTMLElement>(null)
  const isInView = useInView(containerRef)

  useEffect(() => {
    const isAppleFan = getIsAppleFan()
    if (!isAppleFan) return

    const intervalId = setInterval(() => {
      try {
        webcamRef.current?.video?.play()
        finderCamRef.current?.video?.play()
      } catch (e) {
        console.warn(e)
      }
    }, 500)

    return () => {
      clearInterval(intervalId)
      setAllowCameraOpen('neither')
    }
  }, [webcamRef, finderCamRef])

  useEffect(() => {
    setAllowCameraOpen(() => (isInView ? 'both' : 'neither'))
  }, [isInView])

  useEffect(() => {
    return () => {
      loadingToastId && toast.dismiss(loadingToastId)
    }
  }, [loadingToastId])

  // initialize conversation;
  useEffect(() => {
    if (!realtimeClient) return
    realtimeClient
      .createConversation({
        members: ['meme-handler'],
        unique: true,
        attributes: { type: 'meme' },
        tempConv: true,
      })
      .then(conversation => {
        setMemeConversation(conversation)
      })
  }, [realtimeClient])

  useEffect(() => {
    if (
      !memeConversation ||
      !previewImage?.low ||
      !loadingToastId
    ) return

    memeConversation.on(Event.MESSAGE, async (message: TextMessage) => {
      const timestamp = message.timestamp

      if (dayjs(timestamp).isBefore(requestMemeTimestamp.current)) return

      const content = JSON.parse(message.text) as
        | { imageContent: string }
        | { memeTexts: string }
      if ('imageContent' in content) {
        toast.loading('正在生成配字...', { id: loadingToastId })
        setMemes({
          imageContent: content.imageContent,
          memeTexts: [],
        })
      } else {
        const memeTexts: string[] = JSON.parse(content.memeTexts)

        setMemes(memes => ({
          imageContent: memes!.imageContent,
          memeTexts,
        }))

        insertTextToCanvas(memeTexts[0])
        toast.dismiss(loadingToastId)
        setLoadingToastId('')
        await previewImage.low?.destroy()
        setShowMemeTutorial(true)
      }
    })

    return () => {
      memeConversation.off(Event.MESSAGE)
    }
  }, [memeConversation, previewImage, loadingToastId, setMemes])

  async function handleInputImage(e: React.ChangeEvent<HTMLInputElement>) {
    const rawImageFile = e.target.files?.[0]
    if (!rawImageFile) return

    const img = new Image()

    img.src = await readFileAsDataURL(rawImageFile)
    img.onload = async () => {
      const { width, height } = img
      const rawAspectRatio = width / height
      const sourceSize = { width, height, sx: 0, sy: 0 }

      if (rawAspectRatio > aspectRatio) {
        sourceSize.width = height * aspectRatio
        sourceSize.sx = (width - sourceSize.width) / 2
      } else {
        sourceSize.height = width / aspectRatio
        sourceSize.sy = (height - sourceSize.height) / 2
      }

      await drawImage(img.src, sourceSize)
      setTimeout(() => {
        setShowMemeTutorial(true)
      }, 1500)
    }
  }

  async function handleSendMemeRequest() {
    if (
      !previewImage ||
      !pikasoRef.current ||
      !pikasoEditor ||
      !memeConversation ||
      loadingToastId
    ) return

    setMemes(undefined)
    setShowMemeTutorial(false)
    const toastId = toast.loading('正在分析图片...')
    setLoadingToastId(toastId)

    try {
      const memeImage = JSON.stringify({ image: previewImage.low?.url() })
      const res = await memeConversation.send(new TextMessage(memeImage))
      requestMemeTimestamp.current = res.timestamp
    } catch (error) {
      console.error(error)
    }
  }

  function insertTextToCanvas(text: string) {
    pikasoEditor?.shapes.text.insert({
      text,
      fontFamily: 'Smiley Sans',
      fontSize: 50,
      fontStyle: '',
      verticalAlign: 'middle',
      align: 'center',
      fill: 'white',
      wrap: 'word',
      width: cameraWidth,
      height: cameraHeight,
      padding: 50,
      stroke: '#555555',
      strokeWidth: 0.2,
      opacity: 0.9,
    })
  }

  function handleUpdateText() {
    if (
      !previewImage ||
      !pikasoRef.current ||
      !pikasoEditor ||
      !memes?.memeTexts
    ) return

    const textShape = pikasoEditor.board.shapes.find(
      shape => shape.type === 'text',
    )
    if (!textShape) return
    if (memeIndex.current > 4) {
      memeIndex.current = 0
    } else {
      memeIndex.current++
    }

    setShowMemeTutorial(false)
    textShape.update({ text: memes?.memeTexts[memeIndex.current] })
  }

  async function handleRetake() {
    setMemes(undefined)
    pikasoEditor?.reset()
    await previewImage?.low?.destroy()
    setPreviewImage(undefined)
    setLoadingToastId('')
    setAllowCameraOpen('both')
    setHasScanLayer(true)
  }

  async function handleSendPost() {
    if (!pikasoEditor) return
    if (isSending) return
    if (loadingToastId) return

    setIsSending(true)

    const imageSrc = pikasoEditor.export.toImage()
    try {
      const session: string = User.getSessionToken()
      if (!session) throw new Error('no session token')

      const imageFile = await url2File(
        imageSrc,
        `snapshot.jpg`,
        'image/jpg'
      )
      const imageURL = (
        await new LC.File(imageFile.name, imageFile)
          .save(
            {
              keepFileName: true,
              user: LC.User.current(),
            }
          )
      ).url()

      const ok = await fetch(`${import.meta.env.VITE_PUBLIC_HOST}/moment`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'session-token': session,
        },
        body: JSON.stringify({
          userId: User.id,
          imageURL,
          imageContent: memes?.imageContent,
        }),
      })

      await handleRetake()
      setIsSending(false)
      console.log(
        ok ? 'upload one status successfully' : 'failed to upload status',
      )
      await mutateStatus()
    } catch (error) {
      console.log(error)
    } finally {
      if (needMemeTutorial) {
        localStorage.removeItem('needMemeTutorial')
        needMemeTutorial.current = false
      }
    }
  }

  async function handleCapture() {
    if (previewImage) return

    const rawSrc = webcamRef.current?.getScreenshot({
      width: cameraWidth,
      height: cameraHeight,
    })

    if (!rawSrc) return

    const sWidth = cameraWidth / zoom
    const sHeight = cameraHeight / zoom
    const sx = (cameraWidth - sWidth) / 2
    const sy = (cameraHeight - sHeight) / 2

    await drawImage(rawSrc, { width: sWidth, height: sHeight, sx, sy })
    flashAnimation()
    playSoundEffect()
    setAllowCameraOpen('finder')
    setShowMemeTutorial(true)
  }

  function flashAnimation() {
    animate(
      '#flash',
      {
        opacity: [0, 0.8, 0.1],
        scale: [0, 4, 4],
        filter: 'blur(1px)',
      },
      { duration: 0.2, repeat: 1 },
    )
    animate(
      '#light',
      {
        opacity: [0, 1, 0],
        scale: [1, 1, 1],
      },
      {
        duration: 0.2,
        repeat: 1,
      },
    )
  }

  async function drawImage(
    imgSrc: string,
    sourceSize: {
      sx: number
      sy: number
      width: number
      height: number
    },
  ) {
    const clipConfig: ClipConfig = [
      imgSrc,
      sourceSize.sx,
      sourceSize.sy,
      sourceSize.width,
      sourceSize.height
    ]
    const highQualityImg = await clipImage(
      ...clipConfig,
      cameraWidth,
      cameraHeight,
    )
    pikasoEditor?.loadFromUrl(highQualityImg)

    const lowQualityImg = await clipImage(
      ...clipConfig,
      360,
      360,
    ).then(
      async base64 => await new LC.File('meme-temp.jpg', { base64 })
        .save({
          keepFileName: true,
          user: LC.User.current(),
        })
    )

    setPreviewImage({ high: highQualityImg, low: lowQualityImg })
  }

  function playSoundEffect() {
    audioRef.current?.play()
  }

  function TutorialText() {
    if (!needMemeTutorial.current) return null
    if (!previewImage) return null
    if (!showMemeTutorial) return null

    const isMemeIcon = !memes?.memeTexts

    return (
      <div className="absolute inset-0 z-20 flex flex-col justify-center items-center gap-1 bg-black/80 text-gray-50 animate-pulse">
        <div className="flex justify-center items-center gap-1">
          <p>点击</p>
          <NextImage
            className="w-5 h-5"
            src={
              isMemeIcon ? '/icons/webcam-meme.svg' : '/icons/webcam-dice.svg'
            }
            alt="memes icon"
          />
        </div>
        <p>{`${isMemeIcon ? '生成' : '更换'}智能配字`}</p>
      </div>
    )
  }

  return (
    <main
      ref={containerRef}
      className="w-full h-full p-6 flex flex-col justify-between items-center text-[#8F9BB3] select-none"
    >
      <section className="w-full min-h-fit flex justify-between items-start">
        <div className="relative">
          <div ref={scope}>
            <motion.div
              id="flash"
              initial={{ x: '-58px', y: '-30px' }}
              className="absolute z-[998] rounded-full w-40 h-40 bg-[#fffaf3]/80 opacity-0"
            ></motion.div>
            <motion.div
              id="light"
              initial={{ x: '14px', y: '14px' }}
              className="absolute z-[999] w-[15px] h-[60px] bg-[#fffaf3]/80 opacity-0"
            ></motion.div>
          </div>
          <FlashLight className="h-20" />
        </div>
        <ViewFinder>
          {allowCameraOpen !== 'neither' ? (
            <Webcam
              ref={finderCamRef}
              screenshotFormat="image/jpeg"
              mirrored={facingMode === 'user'}
              imageSmoothing={true}
              onUserMedia={() => setHasScanLayer(false)}
              videoConstraints={videoConstraints}
              className="w-full h-full object-cover rounded-sm"
            />
          ) : null}
        </ViewFinder>
      </section>

      <section className="w-full">
        <section
          style={{ aspectRatio }}
          className={clsx(
            hasScanLayer
              ? 'bg-gradient-to-tr from-slate-200 to-slate-300'
              : previewImage?.high
                ? ''
                : 'bg-black',
            'relative w-full rounded-xl overflow-hidden shadow-neumorphism border-[7px] border-[#555555] bg-[#555555]',
          )}
        >
          <div
            ref={pikasoRef}
            className="absolute inset-0 z-10 bg-transparent"
          />
          <TutorialText />

          {loadingToastId && <div className={clsx(styles.scan, 'z-30')} />}
          {!previewImage && allowCameraOpen === 'both' ? (
            <>
              <Webcam
                ref={webcamRef}
                style={{
                  transform: `scale(${zoom})`,
                  transition: 'transform .3s ease-out',
                }}
                screenshotFormat="image/jpeg"
                mirrored={facingMode === 'user'}
                imageSmoothing={true}
                onUserMedia={() => setHasScanLayer(false)}
                videoConstraints={videoConstraints}
                className="w-full h-full object-cover rounded-sm"
              />

              <section className="absolute z-20 bottom-0 w-full p-4 flex justify-between items-center">
                <div className="w-10" />
                <motion.button
                  whileTap={{ background: 'black', scale: 0.95 }}
                  onTap={() => setZoom(zoom => (zoom >= 2 ? 1 : zoom + 0.5))}
                  className="w-10 h-10 bg-black/30 backdrop-blur-lg rounded-full text-gray-50 flex items-center justify-center"
                >
                  <p className="text-sm">{zoom}x</p>
                </motion.button>
                <motion.button
                  whileTap={{ background: 'black', scale: 0.95 }}
                  onTap={() =>
                    setFacingMode(mode =>
                      mode === 'user' ? 'environment' : 'user',
                    )
                  }
                  className="w-10 h-10 bg-black/30 backdrop-blur-lg rounded-full text-gray-50 flex items-center justify-center"
                >
                  <NextImage
                    src="/icons/webcam-switch.svg"
                    alt="facing mode icon"
                  />
                </motion.button>
              </section>
            </>
          ) : null}
        </section>

        <section className="relative w-full flex justify-between items-start mt-6">
          <div className="absolute -right-3 top-9">
            <RedLight />
          </div>
          {!previewImage ? (
            <>
              <motion.button
                whileTap={{ scale: 0.9 }}
                className={clsx(
                  'w-20 h-10 rounded-full grid place-content-center',
                  'flex items-center justify-center outline-none border-[1px] border-[#8f9092] font-sans text-[14px] font-[600] text-[#606060]',
                  'bg-gradient-to-t from-transparent via-[#ffffff80] to-[#fdfdfd]',
                  'shadow-white-button',
                  'active:shadow-white-button-active',
                  'focus:shadow-white-button-active',
                )}
              >
                <NextImage src="/icons/webcam-gallery.svg" alt="gallery icon" />
                <input
                  className="opacity-0 absolute inset-0 z-10 w-20 h-10"
                  type="file"
                  multiple={false}
                  accept="image/png, image/jpeg, image/jpg, image/svg"
                  onInput={handleInputImage}
                />
              </motion.button>
              <MainButton onTap={handleCapture} />
            </>
          ) : (
            <>
              <motion.button
                whileTap={{ scale: 0.9 }}
                onTap={handleRetake}
                className={clsx(
                  'w-20 h-10 rounded-full grid place-content-center',
                  'flex items-center justify-center outline-none border-[1px] border-[#8f9092] font-sans text-[14px] font-[600] text-[#606060]',
                  'bg-gradient-to-t from-[#d8d9db00] via-[#ffffff80] to-[#fdfdfd]',
                  'transition-all duration-200 ease-in-out',
                  'shadow-white-button',
                  'active:shadow-white-button-active',
                  'focus:shadow-white-button-active',
                )}
              >
                <NextImage src="/icons/webcam-back.svg" alt="back icon" />
              </motion.button>
              <MainButton onTap={handleSendPost}>
                {isSending ? (
                  <div className="text-[#681414]">
                    <Loading type="spinner" size="xl" color="currentColor" />
                  </div>
                ) : (
                  <NextImage src={checkIcon} alt="check icon" />
                )}
              </MainButton>
            </>
          )}
          <div className="flex flex-col gap-1 items-center">
            <motion.button
              whileTap={{ scale: 0.9 }}
              onTap={
                !memes?.memeTexts ? handleSendMemeRequest : handleUpdateText
              }
              className={clsx(
                'w-20 h-10 rounded-full grid place-content-center',
                'flex items-center justify-center outline-none border-[1px] border-[#8f9092] font-sans text-[14px] font-[600] text-[#606060]',
                'bg-gradient-to-t from-[#00000080] to-[#2c2b2b]',
                'shadow-black-button',
              )}
            >
              <NextImage
                className="w-5 h-5"
                src={
                  !memes?.memeTexts
                    ? '/icons/webcam-meme.svg'
                    : '/icons/webcam-dice.svg'
                }
                alt="memes icon"
              />
            </motion.button>
          </div>
        </section>
      </section>
      {!isLoadingStatusCount && (
        <section className="flex flex-col items-center">
          <NextImage src="/icons/webcam-up.svg" alt="up" />
          <p className="text-center text-xs">{unread} 条今日动态</p>
        </section>
      )}
      <section className="hidden">
        <audio ref={audioRef}>
          <source src="/music/camera-click.mp3" type="audio/mp3" />
        </audio>
      </section>
    </main>
  )
}

function MainButton({
  onTap,
  children,
}: {
  onTap: () => void
  children?: React.ReactNode
}) {
  const [isPressed, setIsPressed] = useState(false)

  function handleClick() {
    setIsPressed(true)
    onTap()
  }

  return (
    <main
      onPointerDown={handleClick}
      onPointerLeave={() => setIsPressed(false)}
      className="relative w-[122px] h-[122px] -mt-5"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-[48.5%] -translate-y-[48.5%] z-10 select-none">
        <RedButton isPressed={isPressed}></RedButton>
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-20 h-20 opacity-20 select-none pointer-events-none">
        <NextImage src="/camera/camera-button-noise.png" alt="noise" />
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30 select-none pointer-events-none opacity-70">
        {children}
      </div>
    </main>
  )
}

// A helper function that clip a base64 image to a specific size
async function clipImage(
  base64: string,
  sx: number,
  sy: number,
  sWidth: number,
  sHeight: number,
  dWidth: number,
  dHeight: number,
): Promise<string> {
  const img = new Image()
  img.src = base64

  const canvas = document.createElement('canvas')
  canvas.width = dWidth
  canvas.height = dHeight

  const ctx = canvas.getContext('2d')

  return new Promise(resolve => {
    img.onload = () => {
      ctx?.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, dWidth, dHeight)
      const base64data = canvas.toDataURL()
      resolve(base64data)
    }
  })
}

async function url2File(url: string, filename: string, mimeType: string) {
  const res = await fetch(url)
  const buf = await res.arrayBuffer()
  return new File([buf], filename, { type: mimeType })
}
