import { useState } from 'react'
import {
  TextMessage,
  type ConversationBase,
} from 'leancloud-realtime'
import { motion } from 'framer-motion'
import GroupSettings from './group-settings'
import { UsersIcon } from '@heroicons/react/24/solid'

export default function GroupManagement({
  disable,
  conversation,
  printLocalMessage,
}: {
  disable: boolean
  conversation: ConversationBase
  printLocalMessage: (message: TextMessage) => Promise<void>
}) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return !disable ? (
    <main>
      <section className="rounded-full overflow-hidden grid place-items-center bg-gradient-to-tr from-[#8F9BB3]/40 to-[#B7DBFF]/40 active:shadow-neumorphism-inner shadow-neumorphism backdrop-blur text-gray-50 text-center">
        <motion.button
          className="p-2"
          whileTap={{ scale: 0.9, rotate: 180 }}
          onClick={() => setIsModalVisible(true)}
        >
          <UsersIcon className='h-5' />
        </motion.button>
      </section>
      <GroupSettings
        conversation={conversation}
        printLocalMessage={printLocalMessage}
        modalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}
      />
    </main>
  ) : null
}