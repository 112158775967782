import publicImage from '#/assets/public-image.json'
import Image from '#/components/next-image'
import { Link } from 'react-router-dom'
import { useTitle } from '#/utils/useTitle'

export default function Layout({ children }: { children?: React.ReactNode }) {
  useTitle('探索')
  return (
    <main className="w-full h-full pb-28 overflow-y-auto scrollbar-hide pt-safe">
      <section className="bg-gradient-to-b from-white/80 via-white z-40 w-full">
        <Link to="/mirro">
          <Image
            src={publicImage.banner}
            alt="poster"
            height={500}
            width={500}
          />
        </Link>
      </section>
      {children}
    </main>
  )
}
