import useSWR, { useSWRConfig } from 'swr'
import LC from 'leancloud-storage'
import { fetchUserIdentity } from '#/utils/server'
import { Avatar } from '@nextui-org/react'
import type { UserIdentity } from '#/types'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { toast } from 'react-hot-toast'
import { useMemo } from 'react'
import { useTitle } from '#/utils/useTitle'
import FriendRequestList from '../@friend-list/components/friend-request-list'

export default function Page() {
  useTitle('好友列表')

  const { data: friends } = useSWR('friend-list', async () => {
    const result = await new LC.Query('_Followee')
      .equalTo('user', LC.User.current())
      .equalTo('friendStatus', true)
      .find()

    const friendsPromise = result.map(async r => {
      const friendId = r.get('followee').id
      return await fetchUserIdentity(friendId)
    })
    return await Promise.all(friendsPromise)
  })

  const count = useMemo(() => friends?.length, [friends])

  return (
    <main className="w-full h-full p-6 pt-0 space-y-2 overflow-y-scroll scrollbar-hide scroll-smooth">
      <FriendRequestList />
      {friends?.length === 0 && (
        <p className="text-center text-gray-800/60 py-8">暂时还没有任何好友</p>
      )}
      {friends?.map(f => (
        <FriendItem friend={f} key={f.id} />
      ))}
      {count ? (
        <div className="w-full pb-36">
          <p className="text-center text-sm text-gray-800">
            - 共 <span className="font-mono">{count}</span> 个好友 -
          </p>
        </div>
      ) : null}
    </main>
  )
}

function FriendItem({ friend: { id, avatarURL, username } }: { friend: UserIdentity }) {
  const { mutate } = useSWRConfig()

  async function handleDeleteFriend() {
    await LC.User.current().unfollow(id)
    await mutate<UserIdentity[]>('friend-list', (prev) => {
      if (!prev) return []
      prev.filter(f => f.id !== id)
      return prev
    })
    toast.success(`已解除与${username}的好友关系`)
  }
  return (
    <main className='grid grid-flow-col snap-x scrollbar-hide scroll-smooth overflow-x-scroll pb-3 first:pt-2 border-b-[0.5px] border-gray-500/10'>
      <Link to={`/profile/${id}`} className='z-0 w-screen snap-start'>
        <div className="w-full flex items-center gap-4">
          <Avatar
            src={avatarURL}
            alt={username}
            size="xl"
            zoomed
          />
          <p className="text-gray-800 font-medium">{username}</p>
        </div>
      </Link>
      <motion.button onTap={handleDeleteFriend} whileTap={{ scale: 0.9 }} className='snap-start bg-red-500 rounded-xl text-gray-50 w-24 h-full flex items-center justify-center'>
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
        </svg>
      </motion.button>
    </main>
  )
}

