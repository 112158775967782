import Image from '#/components/next-image'
import { useUserTags } from '#/hooks/use-user-tags'
import { useStore } from '#/stores'
import { sigmoidModel } from '#/utils'
import { Input, Modal } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import React from 'react'
import { MirroAvatar, RoundedButton } from 'ui'
import LC from 'leancloud-storage';
import { Bubbles } from '#/app/profile-settings/Bubbles'

const Offset = { x: 0, y: 40 }
const tagLimit = 10
const deleteRadius = 110

export default function Page() {
  const [profile, isProfileLoading] = useStore(state => [
    state.myProfile,
    state.isMyProfileLoading,
  ])

  const currentUser = LC.User.current()

  const [tags, _, __, addTag, deleteTag] = useUserTags(
    currentUser.id!!,
  )
  const [showAddTagModal, setShowAddTagModal] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(0)
  const setNextPage = () => {
    setPageNumber(
      pageNumber + 1 < Math.ceil(tags.length / tagLimit) ? pageNumber + 1 : 0,
    )
  }
  const limitedTags = React.useMemo(() => {
    let subTags = [...tags]
    if (subTags.length >= tagLimit) {
      subTags = subTags.slice(
        pageNumber * tagLimit,
        (pageNumber + 1) * tagLimit,
      )
      subTags.push({ id: 'more', label: '🔄' })
    }
    return subTags
  }, [tags, pageNumber])

  const [syncRate, setSyncRate] = React.useState('0')

  const triggerRef = React.useRef<HTMLDivElement>(null)
  const binRef = React.useRef<HTMLDivElement>(null)

  // NOTE - Unfinished Avatar Editor
  // const [avatarURL, setAvatarURL] = React.useState<string>("");
  // const [showAvatarModal, setShowAvatarModal] = React.useState(false);
  // const inputRef = React.useRef<HTMLInputElement>(null);

  const [buttonX, setButtonX] = React.useState<number>(0)
  const [deleteOpacity, setDeleteOpacity] = React.useState<number>(0)

  React.useEffect(() => {
    if (!isProfileLoading) {
      setSyncRate(sigmoidModel(tags.length).toFixed(1))
    }
  }, [tags])

  function distance(x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
  }

  function handleClick(nodeId: string) {
    if (nodeId == 'more') setNextPage()
  }

  function handleDragging(nodeId: string, nodePos: { x: number; y: number }) {
    if (triggerRef.current && nodeId != 'more') {
      const { x, y, height } = triggerRef.current.getBoundingClientRect()

      const dist = distance(nodePos.x, nodePos.y, x, y + height)

      const radius = 180

      if (dist < radius) {
        setButtonX(-200)
        setDeleteOpacity(1)
      }
    }
  }

  function handleOffDrag(nodeId: string, nodePos: { x: number; y: number }) {
    if (binRef.current && nodeId != 'more') {
      const { x, y, width, height } = binRef.current.getBoundingClientRect()
      const [targetX, targetY] = [x + width / 2, y + height / 2]

      const dist = distance(nodePos.x, nodePos.y, targetX, targetY)

      if (dist < deleteRadius) deleteTag(nodeId)

      setButtonX(0)
      setDeleteOpacity(0)
    }
  }

  // NOTE - Unfinished Avatar Editor
  // function handleImgClick(e: React.MouseEvent<HTMLDivElement>) {
  //   inputRef.current?.click();
  // }

  // function handleImgChange(e: React.ChangeEvent<HTMLInputElement>) {
  //   const newImages = e.target.files;
  //   if (newImages) {
  //     setAvatarURL(URL.createObjectURL(newImages[0]));
  //     setShowAvatarModal(true);
  //   }
  // }
  return profile ? (
    <main className="relative w-full h-full pt-safe">
      <section className="fixed top-6 right-6 justify-end w-auto h-auto z-10 pt-safe">
        <div className="relative w-36 h-36 bg-gray-200 bg-center bg-no-repeat bg-[url('/icons/trash.svg')] bg-[length:60px_60px] rounded-[32px] ">
          {/* NOTE - Unfinished Avatar Editor */}
          {/* <input
            className="hidden"
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImgChange}
          /> */}

          <Link to="/profile/self">
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <MirroAvatar avatarURL={profile ? profile.avatarURL : ''} />
            </motion.div>
          </Link>

          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[40%]">
            <RoundedButton color="gradient">
              <p className="px-4 py-1 whitespace-nowrap text-xs">
                同步率: {`${syncRate}%`}
              </p>
            </RoundedButton>
          </div>
        </div>
      </section>

      <motion.div className="opacity-0" animate={{ opacity: deleteOpacity }}>
        <section
          ref={binRef}
          className="fixed bg-red-400 bottom-28 left-0 translate-x-[-55%] rounded-full w-[220px] h-[220px] z-0 flex justify-center items-center shadow-neumorphism"
        >
          <Image
            className="ml-20"
            width="40"
            height="40"
            src="/icons/trash.svg"
            alt="trash icon"
          ></Image>
        </section>
      </motion.div>

      <section className="fixed inset-0 p-6 pb-28 mb-safe flex flex-col justify-between items-start z-10 pointer-events-none">
        <div className="backdrop-blur-xl rounded-2xl space-y-3 pointer-events-auto pt-safe">
          <p className="font-semibold text-2xl ml-[2px] mb-2">
            {profile ? profile.username : null}
          </p>
          <Link to="/profile-settings">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="bg-transparent rounded-2xl border-[1.3px] text-xs text-gray-400 border-gray-400"
            >
              <div className="w-full h-full px-3 py-2">编辑对外资料</div>
            </motion.button>
          </Link>
        </div>

        <div
          ref={triggerRef}
          className="flex flex-col gap-4 pointer-events-auto"
        >
          <motion.div animate={{ x: buttonX }}>
            <RoundedButton
              color="white"
              className="w-[7rem] flex justify-center items-center px-4 py-[.85rem] text-sm"
              onClick={() => {
                setShowAddTagModal(true)
              }}
            >
              <p className="text-sm">+ 喂入标签</p>
            </RoundedButton>
          </motion.div>
          <motion.div animate={{ x: buttonX }} transition={{ delay: 0.05 }}>
            <RoundedButton
              color="gradient"
              className="w-[9rem] flex justify-center items-center gap-2 px-4 py-[.85rem]"
            >
              <Image src="/icons/chat-with-mirro.svg" alt="chat icon" />
              <Link to="/chat/private/self" className="text-gray-50">
                <p className="text-sm">和镜身对话</p>
              </Link>
            </RoundedButton>
          </motion.div>
        </div>
      </section>

      <section className="absolute top-0 w-full h-full z-0">
        <Bubbles
          nodes={limitedTags}
          offset={Offset}
          {...{ handleClick, handleDragging, handleOffDrag }}
        />
      </section>

      {/* <section
        ref={triggerRef}
        className="hidden fixed bottom-0 w-full h-[7.5rem] bg-red-600/90 z-0 text-gray-50/80 justify-center items-center"
      >
        <p className="">拖拽到此处删除</p>
      </section> */}

      {/* NOTE - Unfinished Avatar Editor */}
      {/* <AvatarModal
        imageURL={avatarURL}
        visible={showAvatarModal}
        setVisible={setShowAvatarModal}
      /> */}

      <AddTagModal
        visible={showAddTagModal}
        setVisible={setShowAddTagModal}
        addTag={addTag}
      />
    </main>
  ) : null
}

// NOTE - Unfinished Avatar Editor
// function AvatarModal({
//   imageURL,
//   visible,
//   setVisible,
// }: {
//   imageURL: string;
//   visible: boolean;
//   setVisible: (visible: boolean) => void;
// }) {
//   const cropRef = React.useRef(null);

//   const [scaleValue, setScaleValue] = React.useState(10);

//   const handleSave = async () => {
//     if (cropRef.current) {
//     }
//   };

//   return (
//     <Modal
//       blur
//       aria-labelledby="modal-title"
//       open={visible}
//       onClose={() => setVisible(false)}
//       css={{
//         borderRadius: 0,
//         backgroundColor: "rgba(0,0,0,0.5)",
//         boxShadow: "none",
//       }}
//     >
//       <Modal.Body>
//         <AvatarEditor
//           style={{ width: "100%", height: "100%" }}
//           ref={cropRef}
//           image={imageURL}
//           scale={scaleValue / 10}
//         />

//         <div className="w-full flex justify-center">
//           <Slider
//             value={scaleValue}
//             setValue={setScaleValue}
//             min={10}
//             max={40}
//           />
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }

function AddTagModal({
  visible,
  setVisible,
  addTag,
}: {
  visible: boolean
  setVisible: (visible: boolean) => void
  addTag: (tag: string) => void
}) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  function handleSubmit() {
    if (!inputRef.current) return

    const value = inputRef.current.value.trim()
    if (!value) {
      inputRef.current.value = ''
      return
    }

    if (value.length > 15) {
      //TODO - Change alert styles
      alert('标签长度不能超过15个字符')
    } else {
      addTag(value)
      inputRef.current.value = ''
      setVisible(false)
    }
  }

  return (
    <Modal
      width="80%"
      closeButton
      blur
      aria-labelledby="modal-title"
      open={visible}
      onClose={() => setVisible(false)}
    >
      <Modal.Header className="flex items-center flex-col">
        <h5 className="text-xs font-light leading-none opacity-60">
          Add Your Mirro Tags
        </h5>
        <p className="text-base leading-none">喂入你的新标签</p>
      </Modal.Header>
      <Modal.Body>
        <Input
          ref={inputRef}
          enterKeyHint="done"
          clearable
          fullWidth
          size="md"
        />
      </Modal.Body>
      <Modal.Footer>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8 }}
          className="bg-transparent rounded-2xl border text-xs text-gray-400 px-3 py-1 border-gray-400"
          onTap={() => handleSubmit()}
        >
          <p>提交</p>
        </motion.button>
      </Modal.Footer>
    </Modal>
  )
}
