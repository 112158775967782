import clsx from 'clsx'
import { Link } from 'react-router-dom'
import Image from '#/components/next-image'
import { motion } from 'framer-motion'

export default function MomentButton({ className }: { className: string }) {
  return (
    <motion.button
      whileTap={{ scale: .95, opacity: .9 }}
      className={clsx('py-3 px-5 bg-orchid-gradient shadow-neumorphism rounded-full', className)}
    >
      <Link
        to="/moment"
        className="w-full h-full flex justify-center items-center gap-2"
      >
        <Image
          src="/icons/nav-moment-white.svg"
          alt="mirro"
          width={20}
          height={20}
        />
        <div className="text-sm align-middle text-gray-50 leading-none flex whitespace-nowrap flex-nowrap items-center">
          <span>好友动态</span>
          <span className="text-base animate-pulse leading-none">&nbsp;🔥</span>
        </div>
      </Link>
    </motion.button>
  )
}
