import { Link } from 'react-router-dom'
import { useStore } from '#/stores'
import Image from '#/components/next-image'

export default function ToHomeButton() {
  const isAuthenticated = useStore(state => state.isAuthenticated)

  if (!isAuthenticated) return null
  return (
    <Link
      to="/discover"
      className="min-w-fit grid place-items-center gap-2 rounded-full py-3 px-6 bg-gradient-to-tr from-[#8F9BB3] to-[#B7DBFF] active:shadow-neumorphism-inner"
    >
      <Image className="w-5 h-5" src="/icons/home.svg" alt="home page icon" />
    </Link>
  )
}
