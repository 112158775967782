import { AnimatePresence, motion } from "framer-motion"
import { MessageUnion, NotificationMessage, Quote } from "../../types"
import LC from 'leancloud-storage'
import DialogBox from "./dialog-box"
import { type PropsWithChildren } from "react"
import clsx from "clsx"
import toast from "react-hot-toast"

export default function DialogWhilePressed({
  pressedMessage,
  handleCancel,
  handleSetQuote
}: {
  pressedMessage?: Exclude<MessageUnion, NotificationMessage>
  handleCancel: () => void
  handleSetQuote:(quote: Quote) => void
}) {
  const currentUser = LC.User.current()

  return (
    <AnimatePresence>
      {pressedMessage ? (
        <motion.main
          onTap={handleCancel}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: .2, ease: 'easeOut' }}
          className={clsx(
            pressedMessage.sender === currentUser.id ? 'justify-end' : 'justify-start',
            'fixed inset-0 px-4 backdrop-blur z-50 flex items-center'
          )}
        >
          <div className="flex flex-col justify-center gap-4">
            <motion.div layoutId={pressedMessage.id} className="pointer-events-none">
              <DialogBox message={pressedMessage} />
            </motion.div>

            <motion.section
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 100, opacity: 0 }}
              transition={{ delay: .2, ease: 'easeOut' }}
              className="flex justify-end items-center gap-2"
            >
              <FeatureButton handleTap={() => {
                handleSetQuote({
                  messageId: pressedMessage.id,
                  content: pressedMessage.content,
                  type: pressedMessage.type,
                  sender: pressedMessage.sender,
                })
              }}>回应</FeatureButton>

              <FeatureButton handleTap={() => {
                navigator.clipboard.writeText(pressedMessage.content)
                toast.success('复制成功！')
              }}>复制</FeatureButton>
            </motion.section>
          </div>

        </motion.main>
      ) : null}
    </AnimatePresence>
  )
}

function FeatureButton({
  handleTap,
  children
}: PropsWithChildren<{
  handleTap: () => void
}>) {
  return (
    <motion.button
      onTap={handleTap}
      whileTap={{ scale: .9, backgroundColor: 'white' }}
      className="w-20 px-3 py-2 rounded-2xl bg-gray-50/80 shadow-neumorphism"
    >
      <p className="text-sm text-center text-gray-800/80">{children}</p>
    </motion.button>
  )
}