import type React from 'react'
import { useStore } from '#/stores'
import { useNavigate } from 'react-router-dom'
import { GroupConvAttributes, GroupConfig } from '#/types'
import { TextMessage } from 'leancloud-realtime'

interface Props {
  groupConfig: GroupConfig
  children: React.ReactNode
  className?: string
}

const INIT_TEXT =
  '欢迎来到沙盒，你可以发送一条消息开启对话！也可以随时邀请你的 Mirro 好友或微信好友一起进行体验！随时拉他们入群可看到之前的聊天记录噢~'

export default function CreateGroupButton({
  groupConfig: { id, coverURL, displayName, AIs },
  children,
  className,
}: Props) {
  const navigate = useNavigate()
  const [realtimeClient, myProfile] = useStore(state => [
    state.realtimeClient,
    state.myProfile,
  ])

  async function handleCreateGroup() {
    const conversation = await realtimeClient.createConversation({
      members: AIs,
      unique: false,
      name: `${myProfile.username}的${displayName}`,
      attributes: {
        type: id,
        AIs: AIs.join(','),
        coverURL,
      } as GroupConvAttributes,
    })

    const msg = new TextMessage(
      `<Notification kind="init">${INIT_TEXT}</Notification>`,
    )
    await conversation.send(msg)

    navigate(`/chat/${id}/${conversation.id}`, { replace: true })
  }

  return (
    <button onClick={handleCreateGroup} className={className}>
      {children}
    </button>
  )
}
