import useHistoryList from '#/app/chat-list/@history-list/use-history-list'
import { getTimeAgo } from '#/utils'
import { Link } from 'react-router-dom'
import PullToRefresh from 'react-simple-pull-to-refresh'
import AvatarWithBadge from './components/avatar-with-badge'
import type { HistoryItem } from './types'
import { useTitle } from '#/utils/useTitle'
import useFetchMore from '#/hooks/use-fetch-more'
import FetchMoreLoading from '#/components/fetch-more-loading'

const CHAT_PER_PAGE = 14

export default function Page() {
  useTitle('聊天列表')

  const {
    chatList,
    mutateChatList,
    setListSize,
    isValidating
  } = useHistoryList(CHAT_PER_PAGE)

  const fetchMoreRef = useFetchMore(async () => {
    await setListSize(size => size + 1)
  })

  return (
    <PullToRefresh
      onRefresh={mutateChatList}
      pullingContent=""
      refreshingContent={<FetchMoreLoading />}
    >
      <main className="w-full h-full p-6 pt-0 space-y-2 overflow-y-scroll scrollbar-hide scroll-smooth">
        {chatList?.length === 0 ? (
          <p className="text-center text-gray-800/60 py-8">暂时还没有任何聊天记录</p>
        ) : null}
        {chatList?.map(item =>
          <HistoryItem {...item} key={item.displayInfo.id} />
        )}
        <div className="relative h-36" ref={fetchMoreRef} >
          {isValidating ? <FetchMoreLoading className='absolute inset-x-0 top-0' /> : null}
        </div>
      </main>
    </PullToRefresh>
  )
}

function HistoryItem({ displayInfo, lastMessage }: HistoryItem) {
  const timeAgo = getTimeAgo(lastMessage.at.toString())
  return (
    <section className="flex justify-between items-center gap-4 w-full pb-3 first:pt-2 border-b-[0.5px] border-gray-500/10">
      <Link
        to={
          displayInfo.type !== 'private'
            ? '/chat-list'
            : `/profile/${displayInfo.id}`
        }
        className="z-0 shadow-neumorphism rounded-full"
      >
        <AvatarWithBadge
          unread={lastMessage.unread}
          displayInfo={displayInfo}
        />
      </Link>

      <Link
        to={`/chat/${displayInfo.type}/${displayInfo.id}`}
        className="flex justify-between gap-4 w-full h-full text-sm"
      >
        <div className="flex-1 flex flex-col justify-between h-full w-28">
          <p className="truncate">
            <span className="font-medium text-gray-800">{displayInfo.displayName}</span>
            <span className="text-gray-500 text-xs scale-90">
              {displayInfo.type !== 'private' ? ' [沙盒]' : ''}
            </span>
          </p>
          <p className="text-gray-800/60 truncate text-xs">
            {lastMessage.content || '还没有消息...'}
          </p>
        </div>
        <p className="text-gray-800/60 text-xs">{timeAgo}</p>
      </Link>
    </section>
  )
}