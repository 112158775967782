import { StatusDisplay } from '#/types'
import Image from '#/components/next-image'

export default function MemeSlider({
  userMemes,
}: {
  userMemes: StatusDisplay[]
}) {
  return (
    <main className="grid grid-flow-col grid-rows-1 gap-3 w-full overflow-x-scroll scrollbar-hide snap-x scroll-smooth pt-3">
      {userMemes.map(meme => (
        <section
          className="snap-start w-48 rounded-2xl overflow-hidden"
          key={meme.objectId}
        >
          <Image
            className="w-full aspect-square object-cover"
            src={meme.imageURL}
            alt="User Meme"
          />
        </section>
      ))}
    </main>
  )
}
