import clsx from 'clsx'
import styles from './index.module.css'

export default function BoxLoading({ className }: { className?: string }) {
  return (
    <div className={clsx(className, styles.spinner)}>
      <div className="font-semibold">AI</div>
      <div></div>
      <div></div>
      <div>@</div>
      <div></div>
      <div className="p-1">
        <svg
          className="w-full h-full"
          width="328"
          height="222"
          viewBox="0 0 328 222"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.3455 8.07319C81.9109 5.55387 85.4275 4 89.3071 4H131.961C139.813 4 146.179 10.3656 146.179 18.2179V60.8714C146.179 68.7237 139.813 75.0893 131.961 75.0893C100.552 75.0893 75.0899 100.551 75.0895 131.96L75.0893 146.18V203.05C75.0893 210.902 68.7237 217.268 60.8714 217.268H18.2179C10.3655 217.268 4 210.902 4 203.05L4 160.396C4 158.777 4.27091 157.22 4.76989 155.769C4.77418 155.757 4.76054 155.746 4.74922 155.753C4.73739 155.76 4.72344 155.747 4.72905 155.735L5.12568 154.842C5.18692 154.698 5.25047 154.555 5.31628 154.414L8.97625 146.18L73.5752 16.6334C74.5825 14.6133 75.7707 12.6885 77.1251 10.8827C77.6205 10.0617 78.1965 9.29488 78.8422 8.59317L79.2664 8.02752C79.2823 8.00636 79.3155 8.02396 79.307 8.04897C79.299 8.07222 79.328 8.09041 79.3455 8.07319ZM153.998 75.0893L150.818 78.8001C147.967 81.4002 146.179 85.1448 146.179 89.3071V146.179V203.05C146.179 210.902 152.544 217.268 160.396 217.268H203.05C210.902 217.268 217.268 210.902 217.268 203.05V146.179V112.056L217.268 89.3064L217.268 89.2854C217.279 81.4907 223.563 75.1666 231.341 75.0893H254.234H274.139C281.992 75.0893 288.357 68.7237 288.357 60.8714V18.2179C288.357 10.3656 281.992 4 274.139 4H231.486C227.886 4 224.599 5.33749 222.095 7.54259L217.268 11.4644L153.998 75.0893ZM287.991 146.911C268.36 146.911 252.446 162.825 252.446 182.456C252.446 202.087 268.36 218.001 287.991 218.001C307.621 218.001 323.535 202.087 323.535 182.456C323.535 162.825 307.621 146.911 287.991 146.911Z"
          />
        </svg>
      </div>
    </div>
  )
}
