import { Modal } from "@nextui-org/react";
import { motion } from "framer-motion";

export default function FullscreenImageModal(
  {
    imageURL,
    handleClose
  }: {
    imageURL: string
    handleClose: () => void
  }
) {
  return (
    <Modal
      noPadding
      scroll
      fullScreen
      aria-labelledby="image"
      aria-describedby="fullscreen image"
      open={!!imageURL}
      onPointerUp={handleClose}
      css={{ borderRadius: 0, backgroundColor: 'black' }}
      className='grid place-content-center'
    >
      {imageURL ?
        <motion.img
          src={imageURL}
          alt="fullscreen image"
          initial={{ scale: .5 }}
          animate={{ scale: 1 }}
          transition={{ duration: .3, ease: 'backOut' }}
        /> : null
      }
    </Modal>
  )
}