import clsx from 'clsx'
import Image from '#/components/next-image'

export default function ViewFinder({
  children,
  className,
}: {
  children?: React.ReactNode
  className?: string
}) {
  return (
    <main className={clsx(className, 'relative w-[90px] h-[90px]')}>
      <Image
        className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        src="/camera/camera-view-finder.svg"
        alt="view finder"
      />
      <section className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-8 h-8 overflow-hidden rounded-lg">
          <Image
            className="absolute z-50 w-8 h-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            src="/camera/camera-view-glass.svg"
            alt="view glass"
          />
          <div className="absolute w-8 h-8 z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {children}
          </div>
        </div>
      </section>
    </main>
  )
}
