import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useGlobalUI, useStore } from '#/stores'
import type { Object as LCObject } from 'leancloud-storage'
import { Input, Loading } from '@nextui-org/react'
import { motion, AnimatePresence } from 'framer-motion'
import { Status } from '#/types'
import LC from 'leancloud-storage'
import clsx from 'clsx'

const MAX_AVATAR_DISPLAYED = 5
import Image from '#/components/next-image'
import { fetchUserIdentity } from '#/utils/server'
import { toast } from 'react-hot-toast'

export default function LikeAndComment({ status }: { status: Status }) {
  const [statusLikes, setStatusLikes] = useState<LCObject>()
  const [likerAvatarURLs, setLikerAvatarURLs] = useState<string[]>([])

  useEffect(() => {
    fetchStatusLikes()

    async function fetchStatusLikes() {
      let statusLikes = (await new LC.Query('StatusLikes')
        .equalTo('statusId', status.id)
        .first()) as LCObject
      if (!statusLikes) {
        statusLikes = await createStatusLikes()
      }

      const likers = (statusLikes?.get('likes') as LCObject[]) || []
      const likersProfiles = await Promise.all(
        likers.map(async liker =>
          await fetchUserIdentity(liker.id!)
        ),
      )
      setLikerAvatarURLs(likersProfiles.map(p => p.avatarURL))
      setStatusLikes(statusLikes)
    }

    async function createStatusLikes() {
      const StatusLikes = LC.Object.extend('StatusLikes')
      const statusLikes = new StatusLikes() as LCObject
      statusLikes.set('statusId', status.id)
      statusLikes.set('likes', [])
      await statusLikes.save()
      return statusLikes
    }
  }, [])

  return (
    <section className="w-full h-9 flex items-center mt-4">
      {!statusLikes ? (
        <div className="p-5 bg-gradient-to-tr from-slate-200 to-slate-300 animate-pulse duration-150" />
      ) : (
        <>
          <AvatarTag likerAvatarURLs={likerAvatarURLs} />
          <div className="flex-1">
            <InteractionTag status={status} statusLikes={statusLikes!} setLikerAvatarURLs={setLikerAvatarURLs} />
          </div>
        </>
      )}
    </section>
  )
}

function AvatarTag({ likerAvatarURLs }: { likerAvatarURLs: string[] }) {
  return (
    <div className="flex items-center">
      {likerAvatarURLs.length > 0 && (
        <Image
          key={'heart'}
          className="mr-1"
          src="/icons/grey-heart.svg"
          alt="heart icon"
        />
      )}
      {likerAvatarURLs
        .slice(0, MAX_AVATAR_DISPLAYED)
        .map((url, index) => (
          <Image
            key={url}
            className={clsx(
              index ? '-ml-3' : '',
              'w-[30px] h-[30px] rounded-full border-[1px] border-white object-cover',
            )}
            src={url}
            alt="liker"
            width={30}
            height={30}
          />
        ))}
      {likerAvatarURLs.length > MAX_AVATAR_DISPLAYED ? (
        <div className="w-[30px] h-[30px] font-sans rounded-full -ml-3 bg-white/40 backdrop-blur-md text-gray-400 text-center text-sm leading-[28px] tracking-tight">{`+${likerAvatarURLs.length - MAX_AVATAR_DISPLAYED
          }`}</div>
      ) : null}
    </div>
  )
}

function InteractionTag({
  status,
  statusLikes,
  setLikerAvatarURLs
}: {
  status: Status
  statusLikes: LCObject
  setLikerAvatarURLs: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const [sendMessage, myProfile] = useStore(state => [state.sendMessage, state.myProfile])

  const setIsHideNavBar = useGlobalUI(state => state.setIsHideNavBar)

  const inputRef = useRef<HTMLInputElement>(null)
  const [isOpenComment, setIsOpenComment] = useState(false)
  const [isCommentSubmitLoading, setIsCommentSubmitLoading] = useState(false)
  const [liked, setLiked] = useState(false)

  const toUserId = status.data.source.objectId
  const isSelf = useMemo(
    () => toUserId === LC.User.current().id,
    [LC, toUserId],
  )

  useEffect(() => {
    const likes = statusLikes?.get('likes') as LCObject[]
    if (likes.filter(like => like.id === LC.User.current().id).length) {
      setLiked(true)
    }
  }, [statusLikes])

  useEffect(() => {
    isOpenComment || setIsHideNavBar(false)
  }, [isOpenComment])

  async function handleSubmitComment() {
    if (isSelf) return
    if (isCommentSubmitLoading) return
    if (!inputRef.current) return
    const content = inputRef.current.value.trim()
    if (!content) return

    setIsCommentSubmitLoading(true)
    await sendMessage({
      toUserId,
      content: `<ReferTimeline statusId='${status.id}' imageURL='${status.data.imageURL}' >${content}</ReferTimeline>`,
    })
    setTimeout(() => {
      setIsCommentSubmitLoading(false)
      if (inputRef.current) {
        inputRef.current.blur()
        inputRef.current.value = '🎉 评论成功'
      }
      setTimeout(() => {
        setIsOpenComment(false)
      }, 1000)
    }, 1000)
  }

  async function handleEnterKeyUp(event) {
    if (event && event.key !== 'Enter') return
    await handleSubmitComment()
  }

  async function handleLikeButton() {
    if (!statusLikes) return

    if (liked) {
      setLiked(false)
      setLikerAvatarURLs(urls => urls.filter(url => url !== myProfile.avatarURL))
      await statusLikes.remove('likes', LC.User.current()).save()
    } else {
      setLiked(true)
      setLikerAvatarURLs(urls => urls.concat(myProfile.avatarURL))
      
      await statusLikes
        .addUnique('likes', LC.User.current())
        .save()
        .then(() => toast.success('点赞成功！'))
        .catch(() => toast.error('请重试！'))
      
      if (isSelf) return;
      await sendMessage({
        toUserId,
        content: `<ReferTimeline statusId='${status.id}' imageURL='${status.data.imageURL}'>我喜欢你的这条动态！❤️</ReferTimeline>`,
      })
    }
  }

  return (
    <section className="w-full flex gap-2 justify-end items-center">
      <>
        <div className="relative flex-1 flex justify-end">
          {isSelf || (
            <CommentButton
              isActive={isOpenComment}
              onTap={() => setIsOpenComment(bool => !bool)}
            />
          )}
          <AnimatePresence>
            {isOpenComment ? (
              <motion.div
                key={status.id}
                initial={{ x: -300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ type: 'spring', duration: 0.4 }}
                className="absolute right-12 w-[calc(100vw-9rem)] h-full contrast-150 shadow-sm rounded-full"
              >
                <Input
                  ref={inputRef}
                  onKeyUp={handleEnterKeyUp}
                  onFocus={() => setIsHideNavBar(true)}
                  onBlur={() => setIsHideNavBar(false)}
                  enterKeyHint="send"
                  rounded
                  placeholder="来评论一下"
                  width="100%"
                  height="100%"
                  size="md"
                  contentRightStyling={false}
                  contentRight={
                    isCommentSubmitLoading ? (
                      <Loading
                        size="xs"
                        css={{ color: '#648CFF' }}
                        className="-translate-x-2"
                      />
                    ) : (
                      <motion.button
                        onTap={handleSubmitComment}
                        whileTap={{ scale: 0.9 }}
                        className="-translate-x-1 translate-y-0.5 text-gray-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </motion.button>
                    )
                  }
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
        <LikeButton isActive={liked} onTap={handleLikeButton} />
      </>
    </section>
  )
}

function LikeButton({
  isActive,
  onTap,
}: {
  isActive: boolean
  onTap: () => Promise<void>
}) {
  return (
    <motion.button
      whileTap={{ scale: 0.8 }}
      onTap={onTap}
      className="w-10 h-10 rounded-full bg-white grid place-items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        className="w-6 h-6"
      >
        {isActive ? (
          <path
            fill="url(#paint0_linear_1156_5121)"
            d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"
          />
        ) : (
          <path
            stroke="url(#paint0_linear_1156_5121)"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
          />
        )}
        <defs>
          <linearGradient
            id="paint0_linear_1156_5121"
            x1="5.01719"
            y1="-19.843"
            x2="27.1769"
            y2="-18.7139"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8CA5FF" />
            <stop offset="1" stopColor="#2B99FF" />
          </linearGradient>
        </defs>
      </svg>
    </motion.button>
  )
}

function CommentButton({
  isActive,
  onTap,
}: {
  isActive: boolean
  onTap: () => void
}) {
  return (
    <motion.button
      whileTap={{ scale: 0.8 }}
      onTap={onTap}
      className="w-10 h-10 rounded-full !bg-white grid place-items-center outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className="w-6 h-6"
      >
        {isActive ? (
          <path
            fill="url(#paint0_linear_797_7720)"
            fillRule="evenodd"
            d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
            clipRule="evenodd"
          />
        ) : (
          <path
            stroke="url(#paint0_linear_797_7720)"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
          />
        )}
        <defs>
          <linearGradient
            id="paint0_linear_797_7720"
            x1="5.4625"
            y1="-22.0454"
            x2="29.5915"
            y2="-20.8405"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8CA5FF" />
            <stop offset="1" stopColor="#2B99FF" />
          </linearGradient>
        </defs>
      </svg>
    </motion.button>
  )
}
