
import clsx from "clsx"
import dayjs from "dayjs"
import duration from 'dayjs/plugin/duration'
import { motion } from "framer-motion"
import { useCallback, useEffect, useState } from "react"

const SHOW_REAL_TIME_UNTIL = '2024-08-05T21:10:00.000+08:00'  // 北京时间 2024.8.5 21:10
const START_AT = '2024-08-05T21:30:00.000+08:00'  // 北京时间 2024.8.5 21:30
const END_AT = '2024-08-06T21:30:00.000+08:00'    // 北京时间 2024.8.6 21:30

// const SHOW_REAL_TIME_UNTIL = '2024-08-05T02:29:00.000+08:00'  // 北京时间 2024.8.5 21:10
// const START_AT = '2024-08-05T02:30:00.000+08:00'  // 北京时间 2024.8.5 21:30
// const END_AT = '2024-08-05T02:28:10.000+08:00'    // 北京时间 2024.8.6 21:30

dayjs.extend(duration)

const getRemainTime = (endTime) => dayjs.duration(dayjs(endTime).diff(dayjs()))
const getCurrentTime = () => dayjs().format('HH:mm:ss')

export default function Page() {
  const [stage, setStage] = useState(0)  // 0: 实时时间, 1: 第一个倒计时, 2: 第二个倒计时
  const [timeRemaining, setTimeRemaining] = useState(getRemainTime(START_AT))
  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  const CurrentTime = useCallback(() => {
    const [hours, minutes, seconds] = currentTime.split(':')
    return (
      <div className="flex items-center justify-center gap-2">
        <CountdownText content={hours} />
        <CountdownText content=':' isNumber={false} />
        <CountdownText content={minutes} />
        <CountdownText content=':' isNumber={false} />
        <CountdownText content={seconds} />
      </div>
    )
  }, [currentTime])

  const Countdown = useCallback(() => {
    if (timeRemaining.asSeconds() < 0) return (
      <div className="flex items-center justify-center gap-2">
        <CountdownText content='00' />
        <CountdownText content=':' isNumber={false} />
        <CountdownText content='00' />
        <CountdownText content=':' isNumber={false} />
        <CountdownText content='00' />
      </div>
    )

    const hours = formatWithPadding(timeRemaining.asHours())
    const minutes = formatWithPadding(timeRemaining.minutes())
    const seconds = formatWithPadding(timeRemaining.seconds())

    return (
      <div className="flex items-center justify-center gap-2">
        <CountdownText content={hours} />
        <CountdownText content=':' isNumber={false} />
        <CountdownText content={minutes} />
        <CountdownText content=':' isNumber={false} />
        <CountdownText content={seconds} />
      </div>
    )
  }, [timeRemaining])

  useEffect(() => {
    let timerId:ReturnType<typeof setTimeout>

    const updateState = () => {
      const now = dayjs();
      if (now.isBefore(SHOW_REAL_TIME_UNTIL)) {
        setStage(0);
        setCurrentTime(getCurrentTime());
      } else if (now.isBefore(START_AT)) {
        setStage(1);
        setTimeRemaining(getRemainTime(START_AT));
      } else {
        setStage(2);
        setTimeRemaining(getRemainTime(END_AT));
      }

      // 设置下一次更新
      timerId = setTimeout(updateState, 1000);
    };

    // 立即执行一次更新
    updateState();

    // 组件卸载时清理定时器
    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      <img
        className="pointer-events-none absolute top-0 left-0 w-full h-full object-cover object-[18%] sm:object-left-top"
        style={{ background: "linear-gradient(123deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0) 100%); box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)" }}
        src="/capstone/bg-2024.png"
        alt="bg"
      />
      <main className="absolute top-0 left-0 w-full h-full p-8 sm:p-14 text-white flex flex-col justify-between items-stretch">
        {stage === 0 ? (
          <motion.section
            id="CURRENT"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.2 }}
            transition={{ duration: 1 }}
            className="fixed inset-0 grid place-content-center"
          >
            <CurrentTime />
          </motion.section>
        ) : (
          <section
            id="COUNTDOWN"
            className="duration-300 fixed inset-0 grid place-content-center"
          >
            <Countdown />
          </section>
        )}
      </main>
    </>
  )
}

function CountdownText({ content, isNumber = true }: { content: string, isNumber?: boolean }) {
  return (
    <p
      style={{
        WebkitTextStroke: isNumber ? '2px #FFF' : '3px #FFF',
        WebkitTextFillColor: '#00000050',
      }}
      className={clsx(isNumber ? 'scale-x-125' : 'mb-4 scale-75', "font-neox-mono text-[64px] sm:text-[128px] md:text-[160px] lg:text-[200px] xl:text-[256px] font-medium origin-center")}
    >
      {content
        .replace('0', 'O')
        .replace('0', 'O')
        .replace('0', 'O')
        .replace('0', 'O')
        .replace('0', 'O')
        .replace('0', 'O')
      }
    </p>
  )
}

function formatWithPadding(num: number) {
  const fixedNum = Math.floor(num).toString()
  return fixedNum.length >= 2 ? fixedNum : '0' + fixedNum
}