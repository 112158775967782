import { useEffect, useMemo, useRef, useState } from 'react'
import { motion, useSpring, useInView, useMotionValue } from 'framer-motion'
import clsx from 'clsx'
import useSWR from 'swr/immutable'
import { useStore } from '#/stores'
import QAPair from './components/qa-pair'
import type { QAPairConfig, Message, TagClusters } from './types'
import { UserProfile } from '#/types'
import BoxLoading from '#/components/box-loading'
import { Loading as NextUILoading } from '@nextui-org/react'
import Eggplant from '../../../components/eggplant-blob'
import { useNavigate, useSearchParams } from 'react-router-dom'
import NextImage from '#/components/next-image'
import { useTitle } from '#/utils/useTitle'
import LC from 'leancloud-storage'

export default function Page() {
  useTitle('正在喂养我的 AI 化身...')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const template = searchParams.get('template')

  const [updateProfile, getEditableACL, getReadonlyACL] = useStore(
    state => [
      state.updateProfile,
      state.getEditableACL,
      state.getReadonlyACL,
    ],
  )

  const User = useMemo(() => LC.User.current(), [LC])

  const scrollRef = useRef<HTMLDivElement>(null)

  const [messages, setMessages] = useState<Message[]>([])
  const [chatPageCount, setChatPageCount] = useState(1)
  const [answerCount, setAnswerCount] = useState(0)
  const [skipToFinish, setSkipToFinish] = useState(false)
  const [isConcludeLoading, setIsConcludeLoading] = useState(false)

  const { data: chatPageQuestionsList } = useSWR(
    LC ? 'smart-profile-input' : null,
    chatPageQuestionsListFetcher,
  )

  const totalQuestionsLength = useMemo(
    () => (chatPageQuestionsList ? chatPageQuestionsList.flat().length : null),
    [chatPageQuestionsList],
  )

  const isAllFinished = useMemo(
    () =>
      (totalQuestionsLength && totalQuestionsLength * 2 <= messages.length) ||
      skipToFinish,
    [chatPageQuestionsList, messages, skipToFinish],
  )

  useEffect(() => {
    if (!isAllFinished) return

    uploadConclusionToLC().then(async () => {
      await setProfileCompletedToLC()
      navigate('/profile-settings?first=1', { replace: true })
    })

    async function uploadConclusionToLC() {
      if (!chatPageQuestionsList) return
      setIsConcludeLoading(true)
      const { partialProfile, tagClusters } = await fetchConclusion(
        chatPageQuestionsList,
        messages,
      )
      console.log({ partialProfile, tagClusters })
      await Promise.all([
        updateProfile(partialProfile),
        uploadTagsToLC(tagClusters),
      ])
    }

    async function setProfileCompletedToLC() {
      if (!User.id) return
      const UserQuery = await new LC.Query('_User').get(User.id)
      await UserQuery.set('profileCompleted', true).save()
    }
  }, [isAllFinished])

  const progress = useMemo(() => {
    if (!totalQuestionsLength) return 0
    return answerCount / totalQuestionsLength
  }, [answerCount, totalQuestionsLength])

  const motionProgress = useMotionValue(0)

  useEffect(() => {
    motionProgress.set(progress)
  }, [progress])

  const scaleX = useSpring(motionProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })

  // scroll to bottom
  useEffect(() => {
    if (!scrollRef.current) return
    const target = scrollRef.current.lastChild as any
    if (!target) return
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    })
  }, [chatPageCount])

  useEffect(() => {
    if (!messages.length) return
    setChatPageCount(count => count + 1)
  }, [messages, setChatPageCount])

  async function chatPageQuestionsListFetcher(): Promise<QAPairConfig[][]> {
    const pagesQuery = await new LC.Query('SmartProfileInput')
      .equalTo('template', template)
      .ascending('pageIndex')
      .find()

    const pagesPromise = pagesQuery.map(async page => {
      const questionIds = page.get('questionIds') as string[]
      return await Promise.all(
        questionIds.map(
          async (questionId): Promise<QAPairConfig> =>
            await new LC.Query('SmartProfileQuestion')
              .equalTo('shortId', questionId)
              .first()
              .then(res => res?.toJSON()),
        ),
      )
    })

    return await Promise.all(pagesPromise)
  }

  async function uploadTagsToLC(tagClusters: TagClusters) {
    const User = LC.User.current()
    const editableACL = getEditableACL()
    const readonlyACL = getReadonlyACL()

    const tagClusterPromises = Object.entries(tagClusters).map(
      async ([category, tags]) => {
        const tagPromises = tags.map(async tag => {
          const userTagMap = new LC.Object('UserTagMap')

          let tagQuery = await new LC.Query('Tag')
            .equalTo('category', category)
            .startsWith('title', tag)
            .first()

          if (!tagQuery) {
            tagQuery = await new LC.Object('Tag')
              .set('isSystem', true)
              .set('category', category)
              .set('emoji', tag.split(' ')[1])
              .set('title', tag)
              .setACL(readonlyACL)
              .save()
          }

          return userTagMap
            .set('User', User)
            .set('Tag', tagQuery)
            .setACL(editableACL)
        })

        return await Promise.all(tagPromises)
      },
    )
    const tagsToSave = (await Promise.all(tagClusterPromises)).flat()
    await LC.Object.saveAll(tagsToSave)
  }

  return (
    <main className="w-full h-full pt-safe bg-gradient-to-b from-white via-indigo-50 to-indigo-100">
      <Eggplant
        progress={isConcludeLoading ? -1 : progress}
        className="absolute top-56 -left-20 w-56 aspect-square"
      />
      {!isConcludeLoading && chatPageQuestionsList ? (
        <>
          <section
            ref={scrollRef}
            className="absolute w-full h-full snap-y snap-mandatory overflow-y-scroll overflow-x-hidden scrollbar-hide scroll-smooth"
          >
            {chatPageQuestionsList.slice(0, chatPageCount).map((q, i) => (
              <ChatPage
                key={q[0].shortId}
                pageNumber={i + 1}
                smartQuestions={q}
                messages={messages}
                setMessages={setMessages}
                setSkipToFinish={setSkipToFinish}
                setAnswerCount={setAnswerCount}
                className="snap-start h-screen w-full"
              />
            ))}
          </section>
          <section className="absolute top-0 inset-x-0 z-50 mx-6 h-2 bg-gray-400/40 rounded-full">
            <motion.div
              className="h-full w-full bg-orchid-gradient"
              style={{ scaleX }}
            />
          </section>
          <NextImage
            className="absolute z-0 bottom-10 left-1/2 transform -translate-x-1/2 w-10 h-10"
            src="/logo.svg"
            alt="logo"
          />
        </>
      ) : (
        <div className="fixed inset-0 z-0 flex flex-col justify-center items-center gap-10 text-indigo-400">
          {isConcludeLoading ? (
            <>
              <p className="text-gray-500 animate-pulse">正在生成镜身</p>
              <NextUILoading color="currentColor" type="points" size="md" />
            </>
          ) : (
            <BoxLoading />
          )}
        </div>
      )}
    </main>
  )
}

function ChatPage({
  pageNumber,
  smartQuestions,
  messages,
  setMessages,
  setAnswerCount,
  setSkipToFinish,
  className,
}: {
  pageNumber: number
  messages: Message[],
  smartQuestions: QAPairConfig[]
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
  setAnswerCount: React.Dispatch<React.SetStateAction<number>>
  setSkipToFinish: React.Dispatch<React.SetStateAction<boolean>>
  className: string
}) {
  const inViewRef = useRef(null)
  const isInView = useInView(inViewRef)

  const [currentMessages, setCurrentMessages] = useState<Message[]>([
    { role: 'assistant', content: '' },
  ])

  const [numberAnimation, questionAnimation] = useMemo(
    () => [
      {
        transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        scale: isInView ? 1.5 : 1,
        opacity: isInView ? 1 : 0,
      },
      {
        translateY: isInView ? 0 : 300,
        transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        opacity: isInView ? 1 : 0,
      },
    ],
    [isInView],
  )

  useEffect(() => {
    if (currentMessages.length !== smartQuestions.length * 2) return
    setMessages(prevMsgs => [...prevMsgs, ...currentMessages])
  }, [currentMessages])
  return (
    <main className={clsx('flex flex-col p-6', className)}>
      <motion.section className="fixed top-14 left-14" style={numberAnimation}>
        <p className="font-outline text-gray-600/10 text-4xl scale-[2]">{`0${pageNumber}`}</p>
      </motion.section>

      <motion.section
        className="w-full mt-14 flex flex-col gap-8 text-gray-600/70 text-xl"
        style={questionAnimation}
      >
        {currentMessages
          .filter(item => item.role === 'assistant')
          .map((_, index) => (
            <div
              key={smartQuestions[index].hint}
              className="w-full flex flex-col items-end gap-8"
            >
              <QAPair
                {...{
                  requestBody: {
                    currentQuestion: smartQuestions[index],
                    history: !smartQuestions[index].historyLength
                      ? []
                      : index > 0
                        ? currentMessages.slice(
                          -1 * (smartQuestions[index].historyLength + 1),
                          -1,
                        )
                        : messages.slice(
                          -1 * smartQuestions[index].historyLength,
                        ),
                  },
                  setCurrentMessages,
                  setAnswerCount,
                  isBreakpoint: smartQuestions[index].shortId === 'breakpoint',
                  setSkipToFinish,
                  lengths: {
                    currentMessagesLength: currentMessages.length,
                    totalChatLength: smartQuestions.length * 2,
                  },
                }}
              />
            </div>
          ))}
      </motion.section>
      <div ref={inViewRef} />
    </main>
  )
}

async function fetchConclusion(
  questionsList: QAPairConfig[][],
  messages: Message[],
): Promise<{
  partialProfile: Partial<UserProfile>
  tagClusters: TagClusters
}> {
  return await fetch(
    `${import.meta.env.VITE_PUBLIC_HOST}/bot/conclude`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ questionsList, messages }),
    },
  ).then(res => res.json())
}
