import clsx from 'clsx'
import { CallAIButton, SendImageButton } from './bottom-buttons'
import TextareaWithSendButton from './textarea-with-send-button'
import { PropsWithChildren } from 'react'

export default function InputField({
  isCallAI,
  handleTapAIButton,
  createMessageSender,
  className,
  children,
}: PropsWithChildren<{
  isCallAI: boolean
  handleTapAIButton: () => void
  createMessageSender: (type: 'text' | 'image') => (payload: string) => Promise<void>
  className?: string
}>) {

  return (
    <main className={clsx(
      'flex justify-between items-end gap-2.5',
      className
    )}>
      {children}
      <SendImageButton handleSend={createMessageSender('image')} />
      <CallAIButton
        onTap={handleTapAIButton}
        isActive={isCallAI}
      />
      <section className="flex-1 text-sm">
        <TextareaWithSendButton
          handleSend={createMessageSender('text')}
          placeholder={
            isCallAI ? '正在 @ AI, 可点左侧按钮取消' : '正在跟真人聊天'
          }
        />
      </section>
    </main>
  )
}