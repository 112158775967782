'use client'
import clsx from 'clsx'
import React from 'react'
import { getTimeAgo } from '#/utils'
import Image from '#/components/next-image'
import { Status } from '#/types'
import DeleteButton from './delete-button'
import { Link } from 'react-router-dom'
import LC from 'leancloud-storage';

export default function StatusImage({ status }: { status: Status }) {
  const [tagDisplay, setTagDisplay] = React.useState('flex')
  const currentUser = LC.User.current()

  function handleClick() {
    setTagDisplay(tagDisplay => (tagDisplay === 'flex' ? 'hidden' : 'flex'))
    setTimeout(() => setTagDisplay('flex'), 5000)
  }

  return (
    <main className="relative">
      <Image
        className="w-full h-[calc(100vw-3rem)] rounded-2xl object bg-gradient-to-br from-gray-100 to-[#abd2e1]"
        onClick={handleClick}
        src={status.data.imageURL}
        alt="Status Image"
      />

      <OwnerTag
        className={clsx(
          tagDisplay,
          'absolute top-3 left-3 rounded-full min-w-fit flex gap-[.35rem] items-center justify-center bg-[#8F9BB3]/30 backdrop-blur-sm px-3 py-[.4rem]',
        )}
        username={status.username}
        avatarURL={status.avatarURL}
        createdAt={status.createdAt?.toString()!!}
        userId={status.data.source.objectId}
      />
      {status.data.source.objectId === currentUser.id ? (
        <div className={clsx(tagDisplay, 'absolute top-3 right-3')}>
          <DeleteButton statusId={status.id} />
        </div>
      ) : null}
    </main>
  )
}

function OwnerTag({
  className,
  username,
  avatarURL,
  createdAt,
  userId,
}: {
  className?: string
  username: string
  avatarURL: string
  createdAt: string
  userId: string
}) {
  const timeAgo = getTimeAgo(createdAt)

  return (
    <Link to={`/profile/${userId}`} className={className}>
      <Image
        className="rounded-full w-[30px] h-[30px] object-cover"
        src={avatarURL}
        width={30}
        height={30}
        alt="source user"
      />
      <section className="flex flex-col justify-between text-gray-50">
        <p className="text-xs font-bold text-gray-50">{username}</p>
        <p className="text-xs text-gray-50/90 scale-90 origin-left">{timeAgo}</p>
      </section>
    </Link>
  )
}
