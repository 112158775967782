import Image from '#/components/next-image'
import { Avatar as AvatarComponent } from '@nextui-org/react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { DiscoverInfo, HotItem, StarItem } from './types'
import CreateGroup from './components/create-group'
import useSWRImmutable from 'swr/immutable'
import { fetchSandbox, fetchUserProfile } from '#/utils/server'
import { OtherUser } from '#/utils/leancloud'
import BoxLoading from '#/components/box-loading'
import LC from 'leancloud-storage';
import { sigmoidModel } from '#/utils'
import LogoSingleColor from '#/components/logo-single-color'

export default function Page() {
  const User = LC.User.current()

  const { data: isXAer, isLoading: isLoadingIsXAer } = useSWRImmutable<boolean>(User ? 'isXAer' : null)

  const { data: discoverInfo } = useSWRImmutable(
    !isLoadingIsXAer ? { key: `discover-info`, isXAer } : null,
    discoverInfoFetcher,
  )

  if (!discoverInfo)
    return (
      <main className="fixed inset-0 z-40 flex items-center justify-center text-orchid">
        <BoxLoading />
      </main>
    )

  return (
    <>
      {Sections
        .filter(s => s.kind !== 'hots' || isXAer)
        .map(({ kind, title, component }) => (
          <section key={kind} className="w-full space-y-1 pb-6 overflow-hidden">
            <p className="px-6">{title}</p>
            {component(discoverInfo[kind] as (
              DiscoverInfo['sandboxes'] &
              DiscoverInfo['stars'] &
              DiscoverInfo['hots']
            ))}
          </section>
        ))}
    </>
  )
}

async function discoverInfoFetcher({ isXAer }: { isXAer: boolean }) {
  if (isXAer) {
    const [sandboxes, hots, stars] = await Promise.all([
      fetchSandbox(),
      fetchHots(),
      fetchStars()
    ])
    return { sandboxes, hots, stars }
  } else {
    const [sandboxes, stars] = await Promise.all([
      fetchSandbox(),
      fetchStars()
    ])
    return { sandboxes, stars }
  }
}

// async function fetchKOLs(): Promise<KOLItem[]> {
//   const stars = (await new LC.Query('KOL')
//     .include('User')
//     .include('coverImg')
//     .addAscending('rank')
//     .find())
//     .reduce(async (accPromise, item) => {
//       const acc = await accPromise;
//       const User = item.get('User') as OtherUser;

//       const KOL = {
//         id: User.id!!,
//         displayName: item.get('username'),
//         coverURL: item.get('coverImg')?.url(),
//         description: item.get('description'),
//       }
//       acc.push(KOL);
//       return acc;
//     }, Promise.resolve([] as KOLItem[]))
//   return stars
// }

async function fetchHots(): Promise<HotItem[]> {
  const HLIdQueries = await new LC.Query('HLs').find()
  return (await Promise.all(
    HLIdQueries.map(h => {
      const userId = h.get('User').id
      return fetchUserProfile(userId)
        .then(({ username, avatarURL, follower, tags }) => ({
          id: userId,
          displayName: username,
          coverURL: avatarURL,
          follower: follower!!,
          syncRate: +sigmoidModel(tags ? tags.length : 0).toFixed(1),
        }))
    }))
  ).sort((a, b) => b.syncRate - a.syncRate)
}

async function fetchStars(): Promise<StarItem[]> {
  const starsPromise = (
    await new LC.Query('StarUser')
      .include('User')
      .include('coverImg')
      .greaterThan('rank', 0)
      .addAscending('rank')
      .find()
  ).map(async item => {
    const User = item.get('User') as OtherUser
    const follower =
      (await User.followerQuery().count()) + item.get('addFollower')
    return {
      id: User.id!!,
      displayName: item.get('username'),
      coverURL: item.get('coverImg')?.url(),
      description: item.get('description'),
      follower,
      questions: item.get('questions'),
    }
  })
  return await Promise.all(starsPromise)
}

interface Section<U extends keyof DiscoverInfo> {
  kind: U
  title: string
  component: (list: DiscoverInfo[U]) => JSX.Element
}

type Sections = [
  Section<'sandboxes'>,
  Section<'hots'>,
  Section<'stars'>,
]

const Sections: Sections = [
  {
    kind: 'sandboxes',
    title: '群聊沙盒',
    component: (list: DiscoverInfo['sandboxes']) => {
      return (
        <div className="w-full px-6 grid grid-cols-2 gap-4">
          {list &&
            list.map(
              ({
                id,
                coverURL,
                displayName,
                description,
                AIs,
                welcomeMessages,
              }) => (
                <CreateGroup
                  key={id}
                  groupConfig={{
                    id,
                    coverURL,
                    displayName,
                    description,
                    AIs,
                    welcomeMessages,
                  }}
                  className="flex flex-col justify-center rounded-2xl shadow-neumorphism bg-white bg-gradient-to-tl from-cloud/40 via-white/20 p-2"
                >
                  {coverURL
                    ?<Avatar
                      coverURL={coverURL}
                      displayName={displayName}
                      size="md"
                      className="self-end -mb-2"
                    />
                    : <div className='grid place-content-center text-gray-500 self-end -mb-2 bg-gray-200 h-[40px] aspect-square rounded-full'>
                      <LogoSingleColor className='w-6'/>
                    </div>
                  }
                  <div>
                    <p className="text-sm text-start">{displayName}</p>
                    <p className="text-xs text-[#8F9BB3]">{description}</p>
                  </div>
                </CreateGroup>
              ),
            )}
          <section className="flex flex-col justify-center rounded-2xl shadow-neumorphism bg-gray-100 bg-gradient-to-tl from-cloud/40 via-white/20 p-2 opacity-80">
            <div className="rounded-full w-[40px] aspect-square self-end -mb-2 bg-gray-200 grid place-items-center text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                />
              </svg>
            </div>

            <div>
              <p className="text-sm text-start text-gray-500">创造你的沙盒</p>
              <p className="text-xs text-[#8F9BB3]">即将上线，敬请期待</p>
            </div>
          </section>
        </div>
      )
    },
  },
  // {
  //   kind: 'KOLs',
  //   title: '优质公共化身',
  //   component: (list: DiscoverInfo['KOLs']) => {
  //     return (
  //       <div className='w-full px-6 grid grid-cols-2 gap-4' >
  //         {list && list.map(
  //           ({ id, coverURL, displayName, description }) => (
  //             <Link to={`/profile/${id}`} key={id} className='flex flex-col justify-center rounded-2xl shadow-neumorphism bg-white bg-gradient-to-tl from-cloud/40 via-white/20 p-2'>
  //               <Avatar coverURL={coverURL} displayName={displayName} size='md' className='self-end -mb-2' />
  //               <div>
  //                 <p className='text-sm'>{displayName}</p>
  //                 <p className='text-xs text-[#8F9BB3]'>{description}</p>
  //               </div>
  //             </Link >
  //           )
  //         )}
  //       </div >
  //     )
  //   }
  // },
  {
    kind: 'hots', title: 'House Lead',
    component: (list: DiscoverInfo['hots']) => (
      <div className='grid grid-flow-col auto-cols-min grid-rows-1 gap-4 scroll-pl-6 snap-x scrollbar-hide overflow-x-auto scroll-smooth py-3'>
        {
          list && list.map((item) => (
            <Link to={`/profile/${item.id}`} key={item.id} className='flex flex-col justify-center items-center w-32 shadow-neumorphism bg-white bg-gradient-to-tl from-cloud/40 via-white/20 py-4 rounded-2xl snap-start first:ml-6 last:mr-6'>
              <Avatar
                coverURL={item.coverURL}
                displayName={item.displayName}
                size='xl'
              />
              <p className='text-sm mt-2 text-gray-800'>{item.displayName}</p>
              <p className='text-xs scale-90 text-[#8F9BB3]'>{item.follower} 粉丝</p>
              <div className='rounded-full bg-orchid-gradient shadow-neumorphism scale-90'>
                <p className="px-4 py-1 whitespace-nowrap text-xs text-gray-50">
                  同步率: {`${item.syncRate}%`}
                </p>
              </div>
            </Link>
          ))
        }
      </div>
    )
  },
  {
    kind: 'stars',
    title: '热门镜身',
    component: (list: DiscoverInfo['stars']) => {
      return (
        <div className="w-full space-y-6 px-6">
          {list &&
            list.map(
              ({
                id,
                coverURL,
                displayName,
                follower,
                description,
                questions,
              }) => (
                <div key={id} className="flex gap-4 w-full rounded-2xl">
                  <Link to={`/profile/${id}`} className="relative w-36 h-48">
                    <div className="absolute bottom-0 rounded-2xl w-full h-36 bg-white shadow-neumorphism" />
                    <Image
                      className="absolute bottom-0 w-full rounded-2xl"
                      src={coverURL}
                      alt={displayName}
                      height={500}
                      width={500}
                    />
                    <p className="absolute bottom-2 right-2 text-center align-middle font-mono text-gray-50 mix-blend-difference animate-pulse">
                      AI
                    </p>
                  </Link>
                  <Link
                    to={`/profile/${id}`}
                    className="flex-1 mt-12 flex flex-col justify-between"
                  >
                    <div className='w-full'>
                      <div className="flex justify-between items-center">
                        <p className="font-semibold text-gray-800 text-lg">{displayName}</p>
                        <p className="text-gray-700 text-xs">
                          {follower} 粉丝
                        </p>
                      </div>
                      <p className="text-gray-500 text-xs text-right">{description}</p>
                    </div>
                    {questions.map((question, index) => (
                      <MentionBar
                        key={index}
                        question={question}
                        index={index}
                      />
                    ))}
                    <div></div>
                  </Link>
                </div>
              ),
            )}
        </div>
      )
    },
  },
]

function MentionBar({ question, index }: { question: string; index: number }) {
  return (
    <div
      className={clsx(
        {
          'bg-orchid/50': index === 0,
          'bg-orchid/75': index === 1,
          'bg-orchid': index === 2,
        },
        'flex items-center w-full rounded-2xl h-6 px-1',
      )}
    >
      <Image
        className="w-4 h-4"
        src="/icons/question.png"
        alt="question icon"
      />
      <p className="flex-1 text-gray-50 text-xs text-center">“{question}”</p>
    </div>
  )
}

function Avatar({
  coverURL,
  displayName,
  size,
  className,
}: {
  coverURL: string
  displayName: string
  size: 'md' | 'xl'
  className?: string
}) {
  return (
    <AvatarComponent
      css={{ zIndex: 0 }}
      className={className}
      src={coverURL}
      alt={displayName}
      color="gradient"
      size={size}
      zoomed
    />
  )
}
