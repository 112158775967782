import WebcamPage from './@webcam/wrapped'
import TimelinePage from './@timeline/wrapped'
import { useTitle } from '#/utils/useTitle'

export default function MomentPage() {
  useTitle('瞬时动态')

  return (
    <main className="w-full h-screen overflow-y-scroll scroll-pt-6 snap-y snap-mandatory pb-28 scrollbar-hide select-none">
      <section className="w-full h-screen pb-20 snap-always snap-start select-none pt-safe">
        <WebcamPage />
      </section>
      <TimelinePage />
    </main>
  )
}
