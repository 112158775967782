import { useStore } from '#/stores'
import type { UserIdentity } from '#/types'
import { XML2JSON } from '#/utils'
import { fetchSandbox, fetchUserIdentity } from '#/utils/server'
import { Event, TextMessage } from 'leancloud-realtime'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import useSWRImmutable from 'swr/immutable'
import useSWRInfinite from 'swr/infinite'
import GroupBackground from './components/group-background'
import type {
  BaseMessage,
  ImageMessage,
  MessageUnion,
  NotificationMessage,
  PrimaryMessage,
  Quote,
  ReferMessage,
} from './types'
import { useTitle } from '#/utils/useTitle'
import LC from 'leancloud-storage'
import InputField from './components/input-field'
import GroupManagement from './components/group-management'
import BackButton from './components/back-button'
import IntimacyDisplay from './components/intimacy-display'
import Dialogs from './components/dialogs'
import { AnimatePresence, motion } from 'framer-motion'
import { Loading } from '@nextui-org/react'
import clsx from 'clsx'
import { XCircleIcon } from '@heroicons/react/24/solid'

const HISTORY_PER_PAGE = 10

export default function Page() {
  const { chatType, paramId } = useParams() as {
    chatType: string
    paramId: string
  }

  const isChatWithMirro = paramId === 'self'
  const noNeedAI = chatType === 'pure'
  const currentUser = LC.User.current()

  const realtimeClient = useStore(state => state.realtimeClient)

  const [isCallAI, setIsCallAI] = useState(!noNeedAI)
  const [isReplying, setIsReplying] = useState(false)
  const [quote, setQuote] = useState<Quote | null>(null)

  const { data: conversation } = useSWRImmutable(
    realtimeClient && currentUser ? `conversation-${chatType}-${paramId}` : null,
    conversationFetcher,
    { suspense: true },
  )

  const { data: userIdentities, mutate: mutateUserIdentity } = useSWRImmutable(
    conversation ? `user-identities-${conversation.id}` : null,
    userIdentitiesFetcher,
    { suspense: true },
  )

  const pageTitle = useMemo(() => {
    switch (true) {
      case isChatWithMirro:
        return '和镜身对话 💬'
      case chatType !== 'private' &&
        !!conversation:
        return conversation.name as string
      case chatType === 'private' &&
        !!userIdentities &&
        paramId in userIdentities:
        return userIdentities[paramId].username
      default:
        return '加載中...'
    }
  }, [chatType, conversation, isChatWithMirro, userIdentities])

  useTitle(pageTitle)

  const {
    data: history,
    setSize: setHistorySize,
    isValidating: isHistoryValidating
  } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (!conversation || !userIdentities) return null
      if (
        previousPageData &&
        previousPageData.messages.length < HISTORY_PER_PAGE
      )
        return null
      return `${pageIndex === 0 ? '' : previousPageData.timestamp
        }-history-${chatType}-${paramId}`
    },
    historyFetcher,
    {
      suspense: true,
      revalidateOnFocus: false
    },
  )

  const historyMessages = useMemo(() => {
    return history?.map(({ messages }) => messages).flat()
  }, [history])

  const { data: messageCount, mutate: mutateMessageCount } = useSWRImmutable(
    conversation && chatType === 'private' && !isChatWithMirro
      ? `message-count-${chatType}-${paramId}`
      : null,
    messageCountFetcher,
    { suspense: false },
  )

  const [localMessages, setLocalMessages] = useState<MessageUnion[]>([])

  const { data: sandbox } = useSWRImmutable(
    conversation && chatType !== 'private' ? `sandbox-${chatType}` : null,
    async () => await fetchSandbox(chatType),
    { suspense: true },
  )

  // receive messages from others
  useEffect(() => {
    if (!conversation) return

    conversation.on(
      Event.MESSAGE,
      async (message: TextMessage) => {
        await printLocalMessage(message)
      })
    return () => {
      conversation?.off(Event.MESSAGE)
    }
  }, [conversation])

  useEffect(() => {
    conversation && conversation.read()
  }, [history, localMessages])

  function createMessageSender(type: 'text' | 'image') {
    const messageToSendMaker = { text: TextToSend, image: ImageToSend }
    return async function (payload: string) {
      if (!conversation) return
      const messageToSend = new messageToSendMaker[type](payload)
      await messageToSend.handleSend()
    }
  }

  abstract class MessageToSend {
    protected xmlParams: string[] = []
    protected isNeedAIReply = isCallAI || isChatWithMirro
    protected async sendMessage(message: TextMessage) {
      if (!conversation) return

      try {
        const rawMessage = await conversation.send(message)
        if (quote) setQuote(null)
        await printLocalMessage(rawMessage)

        if (chatType === 'private' && paramId !== 'self') {
          await messageCountMutator()
        }
      } catch (error: unknown) {
        handleSendingError(error)
      }
    }
  }

  class TextToSend extends MessageToSend {
    constructor(private _payload: string) {
      super()
    }

    get payload() {
      if (this.isNeedAIReply) {
        if (chatType === 'private') {
          this.xmlParams.push(`need-ai-reply="true"`)
        } else {
          this.xmlParams.push(
            `need-group-ai-reply="${sandbox.AIs}"`,
            `conversation-id="${conversation.id}"`,
          )
        }
      }

      return new TextMessage(`
      <UserMessage ${this.xmlParams.join(
        ' ',
      )}>${this._payload}
        ${quote ? '<quote>' + JSON.stringify(quote) + '</quote >' : ''}
        </UserMessage>`,
      )
    }

    async handleSend() {
      await this.sendMessage(this.payload)
      if (isCallAI) {
        setTimeout(() => {
          setIsReplying(true)
        }, 1000)
      }
    }
  }

  class ImageToSend extends MessageToSend {
    constructor(private _payload: string) {
      super()
    }

    get payload() {
      if (quote) this.xmlParams.push(`quote="${JSON.stringify(quote)}"`)

      return new TextMessage(
        `<Image>${this._payload}${quote ? '<quote>' + JSON.stringify(quote) + '</quote >' : ''}</Image>`
      )
    }

    async handleSend() {
      await this.sendMessage(this.payload)
    }
  }

  function handleSendingError(error: unknown) {
    const err = error as {
      appCode?: number
      detail?: string
      message: string
    }
    toast.error(
      err.appCode === 403
        ? '你已被对方拉黑，无法发送消息'
        : err.detail ?? '发送失败：' + err.message,
    )
    console.error(err)
  }

  async function printLocalMessage(messageFromLC: TextMessage) {
    const messageToDisplay = await formatMessageFromLCToDisplay(messageFromLC)
    if (!messageToDisplay) return
    setLocalMessages(prev => [messageToDisplay, ...(prev || [])])
  }

  useEffect(() => {
    if (!localMessages || !localMessages?.length) return

    animateScroll.scrollToBottom({
      duration: 800,
      delay: 100,
      smooth: true,
      containerId: 'scroll-container',
    })
  }, [localMessages])

  async function conversationFetcher() {
    const conversation =
      chatType !== 'private'
        ? await realtimeClient.getConversation(paramId)
        : await realtimeClient.createConversation({
          members: [isChatWithMirro ? `${currentUser.id}--ai` : paramId],
          unique: true,
          attributes: { type: 'private' },
        })
    return conversation
  }

  async function userIdentitiesFetcher() {
    const userIdentity = await conversation.members.reduce(
      async (accPromise, memberId) => {
        const acc = await accPromise

        if (memberId !== currentUser.id || isChatWithMirro) {
          const userIdentity = await fetchUserIdentity(
            memberId.replace('--ai', ''),
          )
          acc[memberId] = userIdentity
        }
        return acc
      },
      Promise.resolve({} as { [memberId: string]: UserIdentity }),
    )
    return userIdentity
  }

  async function historyFetcher(params: string) {
    const [timestamp] = params.split('-')

    const messagesFromLC = (
      (await conversation.queryMessages({
        limit: HISTORY_PER_PAGE,
        startTime: timestamp ? new Date(timestamp) : undefined,
      })) as TextMessage[]
    ).reverse()

    if (messagesFromLC.length === 0) return { messages: [], timestamp: new Date() }

    const messages = await Promise.all(
      messagesFromLC.map(m => formatMessageFromLCToDisplay(m))
    )
    return { messages, timestamp: messagesFromLC[messagesFromLC.length - 1].timestamp }
  }

  async function messageCountFetcher() {
    const messageCountQuery = await new LC.Query('ConversationCount')
      .equalTo('convId', conversation.id)
      .first()
    if (messageCountQuery) {
      return messageCountQuery.get('count') as number
    } else {
      const Count = LC.Object.extend('ConversationCount')
      const count = new Count()
      count.set('convId', conversation.id)
      count.set('count', 0)
      await count.save()
      return 0
    }
  }

  async function messageCountMutator() {
    await mutateMessageCount(updateMessageCount)
    async function updateMessageCount() {
      if (typeof messageCount !== 'number') return 0
      const messageCountQuery = await new LC.Query('ConversationCount')
        .equalTo('convId', conversation.id)
        .first()
      await messageCountQuery?.set('count', messageCount + 1).save()
      return messageCount + 1
    }
  }

  async function formatMessageFromLCToDisplay({
    text: raw,
    id,
    from: sender,
    timestamp,
  }: TextMessage
  ) {
    const { text: content, attrs } = XML2JSON(raw)
    const baseMessage: BaseMessage = {
      id,
      content,
      timestamp,
      hide: !!(attrs && attrs.hide),
      conversation: {
        id: conversation.id,
        chatType
      }
    }

    switch (true) {
      // notification
      case raw.endsWith('</Notification>'):
        const notificationMessage: NotificationMessage = {
          ...baseMessage,
          type: 'notification',
          kind: attrs!.kind as NotificationMessage['kind'],
        }
        return notificationMessage

      // refer
      case raw.endsWith('</ReferTimeline>') ||
        (attrs && ('statusId' in attrs) && ('imageURL' in attrs)):
        const referMessage: ReferMessage = {
          ...baseMessage,
          type: 'refer',
          sender,
          referTo: {
            type: 'status',
            targetId: attrs!.statusId as string,
            imageURL: attrs!.imageURL as string,
          },
        }
        return referMessage

      // image
      case raw.endsWith('</Image>'):
        const imageMessage: ImageMessage = {
          ...baseMessage,
          type: 'image',
          sender,
          quote: (attrs && attrs.quote) ? JSON.parse(attrs.quote) : undefined,
        }
        return imageMessage

      // primary
      default:
        const primaryMessage: PrimaryMessage = {
          ...baseMessage,
          type: 'primary',
          sender,
          quote: (attrs && attrs.quote) ? JSON.parse(attrs.quote) : undefined,
        }

        const isFromAI = raw.endsWith('</AIMessage>')

        if (isFromAI) setIsReplying(false)

        if (isFromAI) {
          primaryMessage.tag = 'fromAI'
        } else if (
          attrs && (
            'need-ai-reply' in attrs ||
            'need-group-ai-reply' in attrs
          )
        ) {
          primaryMessage.tag = 'toAI'
        }

        if (isChatWithMirro) return primaryMessage

        if (!(sender in userIdentities) && sender !== currentUser.id) {
          await mutateUserIdentity(
            async () => {
              const userIdentity = await fetchUserIdentity(
                sender.replace('--ai', ''),
              )
              userIdentities[sender] = userIdentity
              return userIdentities
            },
            { revalidate: false },
          )
        }
        return primaryMessage
    }
  }

  async function fetchMoreMessages() {
    await setHistorySize(size => size + 1)
    setLocalMessages([])
  }

  return (
    <main className="relative w-full h-full overflow-hidden">

      <GroupBackground
        className='fixed inset-0 z-0 pointer-events-none'
        disable={chatType === 'private'}
        backgroundURL={sandbox?.skybox ? sandbox.skybox.url : undefined}
      />

      <IntimacyDisplay
        className='absolute inset-0 grid place-content-center pointer-events-none'
        disable={chatType !== 'private' || isChatWithMirro}
        messageCount={messageCount}
      />

      <Dialogs
        chatType={chatType}
        userIdentities={userIdentities}
        localMessages={localMessages}
        historyMessages={historyMessages}
        isHistoryValidating={isHistoryValidating}
        fetchMoreMessages={fetchMoreMessages}
        setQuote={setQuote}
      />

      <InputField
        className='fixed bottom-0 inset-x-0 z-10 p-2 m-2 mb-safe rounded-full backdrop-blur-sm'
        isCallAI={isChatWithMirro || isCallAI}
        handleTapAIButton={
          () => isChatWithMirro || noNeedAI || setIsCallAI(bool => !bool)
        }
        createMessageSender={createMessageSender}
      >
        <AnimatePresence>
          {isReplying ? (
            <div className="absolute -top-3 inset-x-0 grid place-content-center text-orchid">
              <Loading type="points" color="currentColor" />
            </div>
          ) : null}
          {quote ? (
            <QuoteBlock
              quote={quote}
              handleCancel={() => setQuote(null)}
              className='absolute -top-12 inset-x-0 px-2'
            />
          ) : null}
        </AnimatePresence>
      </InputField>

      <section className='absolute top-5 inset-x-0 z-50 px-4 mt-safe flex items-center justify-between'>
        <BackButton />
        <GroupManagement
          disable={chatType === 'private' || !conversation}
          conversation={conversation}
          printLocalMessage={printLocalMessage}
        />
      </section>

    </main>
  )
}

function QuoteBlock({
  handleCancel,
  quote,
  className
}: {
  handleCancel: () => void
  quote: Quote
  className: string
}) {
  return (
    <motion.div
      initial={{ x: '100vw', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: '100vw', opacity: 0 }}
      transition={{ duration: .3, ease: 'easeOut' }}
      className={clsx('flex', className)}
    >
      <div className='flex-1' />
      <div className='px-3 py-2 flex items-center gap-2 bg-gray-50 rounded-2xl shadow-neumorphism text-gray-800'>
        <motion.div
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 1 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: .5, delay: .5 }}
          className='h-5 w-1.5 bg-orchid rounded-full ring-1 ring-orchid/10' />
        {quote.type === 'image' ? (
          <img className='h-8 object-cover rounded-lg' src={quote.content} alt="quote" />
        ) : (
          <p className='truncate max-w-[60vw] text-sm'>{quote.content}</p>
        )}
        <motion.button
          onTap={handleCancel}
          whileTap={{ scale: .9, opacity: .5 }}
          className='w-5 grid place-content-center'
        >
          <XCircleIcon className='w-full text-gray-400' />
        </motion.button>
      </div>
    </motion.div>
  )
}