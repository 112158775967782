import { useCallback, useMemo, useRef, useState } from "react"
import useFetchMore from "#/hooks/use-fetch-more"
import { AnimatePresence, motion } from "framer-motion"
import DialogBox from "./dialog-box"
import FullscreenImageModal from "./fullscreen-image-modal"
import FetchMoreLoading from "#/components/fetch-more-loading"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import LC from 'leancloud-storage'
import type { UserIdentity } from "#/types"
import type { MessageUnion, NotificationMessage, Quote } from "../../types"
import DialogWhilePressed from "./dialog-while-pressed"

type Datatype = 'avatar' | 'fullscreen-image' | 'dialog'

const longPressThreshold = 400

export default function Dialogs({
  historyMessages,
  localMessages,
  isHistoryValidating,
  fetchMoreMessages,
  setQuote
}: {
  chatType: string
  userIdentities: { [memberId: string]: UserIdentity }
  historyMessages: MessageUnion[] | undefined
  localMessages: MessageUnion[]
  isHistoryValidating: boolean
  fetchMoreMessages: () => Promise<void>
  setQuote: (quote: Quote) => void
}) {
  const navigate = useNavigate()
  const currentUser = LC.User.current()

  const [fullscreenImage, setFullscreenImage] = useState('')
  const [pressedMessage, setPressedMessage] = useState<Exclude<MessageUnion, NotificationMessage>>()

  const fetchMoreRef = useFetchMore(fetchMoreMessages)

  const longPressTimer = useRef<NodeJS.Timeout>()

  const interactionOfDialog = useMemo(() => ({
    onDoubleClick(e: React.MouseEvent<HTMLElement>) {
      dialogInteractionModifier(e, (datatype, element) => {
        if (datatype !== 'dialog') return
        const messageData = element.getAttribute('data-message')
        if (!messageData) return
        const targetMessage = JSON.parse(messageData) as Exclude<MessageUnion, NotificationMessage>
        setPressedMessage(targetMessage)
      })
    },
    onPointerDown(e: React.PointerEvent<HTMLElement>) {
      dialogInteractionModifier(e, (datatype, element) => {
        if (datatype !== 'dialog') return
        clearTimeout(longPressTimer.current)
        longPressTimer.current = setTimeout(() => {
          const messageData = element.getAttribute('data-message')
          if (!messageData) return
          const targetMessage = JSON.parse(messageData) as Exclude<MessageUnion, NotificationMessage>
          setPressedMessage(targetMessage)
        }, longPressThreshold)
      })
    },
    onPointerMove(e: React.PointerEvent<HTMLElement>) {
      dialogInteractionModifier(e, (datatype) => {
        if (datatype !== 'dialog') return
        clearTimeout(longPressTimer.current)
      })
    },
    onPointerUp(e: React.PointerEvent<HTMLElement>) {
      dialogInteractionModifier(e, (datatype, element) => {
        switch (datatype) {
          case 'avatar':
            const userId = element.getAttribute('data-user')?.replace('--ai', '')
            if (!userId) return
            navigate(`/profile/${userId}`)
            return
          case 'fullscreen-image':
            if (fullscreenImage) return
            const imageURL = element.getAttribute('data-src')
            if (!imageURL) return
            setFullscreenImage(imageURL)
            return
          case 'dialog':
            clearTimeout(longPressTimer.current)
            const hasImage = element.getAttribute('data-kind') === 'image'
            if (hasImage) {
              const imageURL = element.firstElementChild?.getAttribute('data-src')
              imageURL && setFullscreenImage(imageURL)
            }
            return
        }
      })
    }
  }), [setPressedMessage, setFullscreenImage])

  const DialogBoxesWithTimeBlock = useCallback(({ messages }: { messages: MessageUnion[] | undefined }) => {
    return <>{
      messages?.map((message, index, messages) => {
        const isShowTime = index &&
          messages &&
          (index === messages.length - 1 ||
            dayjs(message.timestamp)
              .diff(dayjs(messages[index + 1].timestamp), 'minutes') > 2)

        if (message.hide) return null

        const isNotification = message.type === 'notification'
        const initial = {
          x: isNotification ? 0 : message.sender === currentUser.id ? 20 : -20,
          opacity: .4
        }
        return (
          <div key={message.id}>
            {isShowTime ? <TimeBlock time={message.timestamp} /> : null}
            <motion.div
              initial={initial}
              transition={{ duration: .4 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              layoutId={message.id}
            >
              <DialogBox message={message} />
            </motion.div>
          </div>
        )
      })
    }</>
  }, [currentUser, DialogBox])

  function dialogInteractionModifier(
    e: React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement>,
    callback: (
      datatype: Datatype,
      element: HTMLElement
    ) => void
  ) {
    e.preventDefault()
    const element = e.target as HTMLElement
    const datatype = element.getAttribute('datatype') as Datatype | null
    if (!datatype) return
    callback(datatype, element)
  }
  return (
    <section
      className="w-full h-full flex flex-col-reverse gap-4 px-4 pt-16 pb-[calc(env(safe-area-inset-top)+6rem)] mt-safe overflow-y-auto overflow-x-hidden"
      id="scroll-container"
      onContextMenu={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      {...interactionOfDialog}
    >
      <AnimatePresence mode="popLayout" presenceAffectsLayout>
        <div className='flex-1' />
        <DialogBoxesWithTimeBlock key='local' messages={localMessages} />
        <DialogBoxesWithTimeBlock key='remote' messages={historyMessages} />
      </AnimatePresence>
      {
        historyMessages && historyMessages.length > 1 ? (
          <div className="pt-safe grid place-content-center" ref={fetchMoreRef} >
            {isHistoryValidating ? <FetchMoreLoading /> : null}
          </div>
        ) : null
      }
      <DialogWhilePressed
        pressedMessage={pressedMessage}
        handleCancel={() => { setPressedMessage(undefined) }}
        handleSetQuote={(quote: Quote) => {
          setQuote(quote)
          setPressedMessage(undefined)
        }}
      />

      <FullscreenImageModal
        imageURL={fullscreenImage}
        handleClose={() => setFullscreenImage('')}
      />
    </section>
  )
}

function TimeBlock({ time }: { time: Date }) {
  const text = dayjs(time).format('MM-DD HH:mm')
  return (
    <div className="w-full pb-2 grid place-content-center scale-90">
      <p className="text-center text-xs text-gray-50 rounded-full bg-gray-500/50 backdrop-blur px-2">
        {text}
      </p>
    </div>
  )
}