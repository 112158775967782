export default function RedButton({ isPressed }: { isPressed: boolean }) {
  return (
    <>
      {isPressed ? (
        <svg
          width="122"
          height="122"
          viewBox="0 0 122 122"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2574_6889)">
            <circle cx="59.5" cy="59.5" r="40.5" fill="#38393A" />
            <circle
              cx="59.5"
              cy="59.5"
              r="39.7172"
              stroke="url(#paint0_linear_2574_6889)"
              strokeWidth="1.56565"
            />
          </g>
          <circle cx="59.5007" cy="59.5" r="35.0573" fill="#1D1D1E" />
          <g filter="url(#filter1_ii_2574_6889)">
            <circle cx="59.3399" cy="59.3401" r="32.336" fill="#9E221A" />
          </g>
          <circle
            cx="59.3399"
            cy="59.3401"
            r="30.836"
            stroke="black"
            strokeOpacity="0.3"
            strokeWidth="3"
          />
          <defs>
            <filter
              id="filter0_d_2574_6889"
              x="0.957065"
              y="0.565652"
              width="121"
              height="121"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1.95706" dy="1.56565" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2574_6889"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2574_6889"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_ii_2574_6889"
              x="27.0039"
              y="23.0042"
              width="64.6719"
              height="68.6719"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="9"
                operator="erode"
                in="SourceAlpha"
                result="effect1_innerShadow_2574_6889"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_2574_6889"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_innerShadow_2574_6889"
                result="effect2_innerShadow_2574_6889"
              />
            </filter>
            <linearGradient
              id="paint0_linear_2574_6889"
              x1="96.3638"
              y1="109.404"
              x2="24.0155"
              y2="28.6548"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#595959" />
              <stop offset="1" stopColor="#595959" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="122"
          height="122"
          viewBox="0 0 122 122"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2574_6885)">
            <circle cx="59.5" cy="59.5" r="40.5" fill="#38393A" />
            <circle
              cx="59.5"
              cy="59.5"
              r="39.7172"
              stroke="url(#paint0_linear_2574_6885)"
              strokeWidth="1.56565"
            />
          </g>
          <circle cx="59.5007" cy="59.5" r="35.0573" fill="#1D1D1E" />
          <circle cx="59.3399" cy="59.3401" r="32.336" fill="#B82F26" />
          <circle
            cx="59.3399"
            cy="59.3401"
            r="30.8368"
            stroke="url(#paint1_linear_2574_6885)"
            strokeOpacity="0.5"
            strokeWidth="2.99825"
          />
          <defs>
            <filter
              id="filter0_d_2574_6885"
              x="0.957065"
              y="0.565652"
              width="121"
              height="121"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1.95706" dy="1.56565" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.38 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2574_6885"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2574_6885"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_2574_6885"
              x1="96.3638"
              y1="109.404"
              x2="24.0155"
              y2="28.6548"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#595959" />
              <stop offset="1" stopColor="#595959" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2574_6885"
              x1="62.7015"
              y1="103.522"
              x2="59"
              y2="45.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  )
}
