import { useEffect, useMemo, useRef, useState } from 'react'
import type {
  QAPairConfig,
  TextQAPair,
  SingleSelectionQAPair,
  MultiSelectionQAPair,
  DateQAPair,
  QuestionRequestBody,
  Message,
  QuestionBotAPIRequestBody,
} from '../types'
import clsx from 'clsx'
import { Input, Textarea } from '@nextui-org/react'
import { motion, AnimatePresence } from 'framer-motion'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { toast } from 'react-hot-toast'
import useSWRImmutable from 'swr/immutable'

type HandleSubmit = (value: string) => Promise<void>

export default function QAPair({
  requestBody,
  setCurrentMessages,
  setAnswerCount,
  setSkipToFinish,
  isBreakpoint,
  lengths,
}: {
  requestBody: QuestionRequestBody
  setCurrentMessages: React.Dispatch<React.SetStateAction<Message[]>>
  setAnswerCount: React.Dispatch<React.SetStateAction<number>>
  setSkipToFinish: React.Dispatch<React.SetStateAction<boolean>>
  isBreakpoint: boolean
  lengths: {
    totalChatLength: number
    currentMessagesLength: number
  }
}) {
  const { hint } = requestBody.currentQuestion
  const defaultQuestion = requestBody.currentQuestion.defaultQuestion && requestBody.currentQuestion.defaultQuestion.trim()

  const [done, setDone] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [question, setQuestion] = useState<string>('')

  useSWRImmutable(
    !defaultQuestion ? 'smart-input-' + hint : null,
    async () => {
      await questionStreamFetcher(
        "/bot/question",
        {
          promptId: 'mirro-ask',
          history: requestBody.history,     
          currentQuestionHint: hint,
        },
        message => {
          setQuestion(q => q + message.token)
        },
        () => setDone(true),
      )
    },
    {
      suspense: false,
    },
  )

  // Only execute when default value is presented
  useEffect(() => {
    if (!defaultQuestion || done) return

    setTimeout(() => {
      if (question.length < defaultQuestion.length) {
        setQuestion(pre => pre + defaultQuestion[pre.length])
      } else {
        setDone(true)
      }
    }, 50)
  }, [question])

  useEffect(() => {
    if (!done) return
    setCurrentMessages(list => {
      const oldList = list.slice(0, -1)
      return [...oldList, { role: 'assistant', content: question }]
    })
  }, [done])

  async function handleSubmit(lastAnswer: string) {
    const skipToFinish = isBreakpoint && lastAnswer === 'no'

    const isTheLast =
      lengths.currentMessagesLength + 1 === lengths.totalChatLength ||
      skipToFinish
    setCurrentMessages(prevMsgs => {
      const updatedMessages: Message[] = [
        ...prevMsgs,
        { role: 'user', content: `我的${hint}是：${lastAnswer}` },
      ]
      if (!isTheLast) updatedMessages.push({ role: 'assistant', content: '' })
      return updatedMessages
    })

    setConfirmed(true)

    if (skipToFinish) {
      setSkipToFinish(true)
      return
    }
    setAnswerCount(count => (count += 1))
  }

  return (
    <>
      <main className="flex items-start self-start">
        <motion.div
          className="text-orchid"
          style={{
            opacity: done && confirmed ? 0 : 1,
            transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1s',
          }}
        >
          <svg
            className="w-8 h-8 -ml-2"
            fill="currentColor"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="20" />
            <circle
              cx="50"
              cy="50"
              r="20"
              className="animate-ping origin-center"
            />
          </svg>
        </motion.div>
        <p className="leading-relaxed">{question}</p>
      </main>
      <AnimatePresence mode="popLayout" presenceAffectsLayout>
        {done ? (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="w-full flex justify-end items-center pl-10 -mt-2"
          >
            <AnswerComponent
              answerInput={requestBody.currentQuestion}
              handleSubmit={handleSubmit}
            />
          </motion.section>
        ) : null}
      </AnimatePresence>
    </>
  )
}

function AnswerComponent({
  answerInput,
  handleSubmit,
}: {
  answerInput: QAPairConfig
  handleSubmit: HandleSubmit
}) {
  switch (answerInput.inputType) {
    case 'single-line-input':
      return <TextInput {...answerInput} handleSubmit={handleSubmit} />
    case 'multi-line-input':
      return <TextInput {...answerInput} handleSubmit={handleSubmit} multi />
    case 'date-input':
      return <DateInput {...answerInput} handleSubmit={handleSubmit} />
    case 'single-select':
      return <SingleSelect {...answerInput} handleSubmit={handleSubmit} />
    case 'multi-select':
      return <MultiSelect {...answerInput} handleSubmit={handleSubmit} />
  }
}

function TextInput({
  placeholder,
  handleSubmit,
  multi,
}: TextQAPair & {
  handleSubmit: HandleSubmit
  multi?: boolean
}) {
  function handleConfirm(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key !== 'Enter') return
    const target = e.currentTarget
    if (!target.value) return
    target.blur()
    target.readOnly = true
    handleSubmit(target.value.trim())
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key !== 'Enter') return
    e.preventDefault()
  }

  return (
    <div className="shadow-neumorphism overflow-hidden rounded-2xl">
      <Textarea
        className="contrast-150"
        placeholder={placeholder}
        width="60vw"
        minRows={multi ? 4 : 1}
        size="lg"
        onKeyUp={handleConfirm}
        onKeyDown={handleKeyDown}
        {...{ enterKeyHint: 'send' }}
      />
    </div>
  )
}

function DateInput({
  handleSubmit,
}: DateQAPair & { handleSubmit: HandleSubmit }) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [visible, setVisible] = useState(false)

  function handleChange() {
    if (visible) return
    setVisible(true)
  }

  async function handleConfirm() {
    if (!inputRef.current) return
    if (!inputRef.current.value) return
    inputRef.current.blur()
    inputRef.current.readOnly = true
    setVisible(false)
    await handleSubmit(inputRef.current.value.trim())
  }

  return (
    <div className="w-full flex flex-col justify-center items-end gap-2.5">
      <div className="shadow-neumorphism overflow-hidden rounded-2xl">
        <Input
          className="contrast-150"
          ref={inputRef}
          onChange={handleChange}
          width="40vw"
          type="date"
          size="lg"
          min="1950-01-01"
          max="2020-01-01"
        />
      </div>
      <ConfirmButton visible={visible} handleConfirm={handleConfirm} />
    </div>
  )
}

function SingleSelect({
  options,
  handleSubmit,
}: SingleSelectionQAPair & { handleSubmit: HandleSubmit }) {
  const [selected, setSelected] = useState('')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const visible = useMemo(
    () => !!selected && !isConfirmed,
    [selected, isConfirmed],
  )

  function handleSelect(e) {
    if (isConfirmed) return
    if (!e || e.target.nodeName !== 'LI') return
    const value = e.target.getAttribute('value') as string
    setSelected(value)
  }

  async function handleConfirm() {
    setIsConfirmed(true)
    await handleSubmit(selected)
  }

  return (
    <div className="w-full flex flex-col justify-center items-end gap-4">
      <ul
        onPointerDown={handleSelect}
        className="w-full min-h-fit flex flex-wrap justify-end items-stretch gap-2"
      >
        {options.map(({ display, value }, _, { length }) => (
          <motion.li
            key={value}
            whileTap={{ scale: 0.9 }}
            value={value}
            className={clsx(
              selected === value
                ? 'bg-orchid text-gray-100'
                : 'bg-white text-gray-500',
              display.length > 5
                ? display.length > 10
                  ? 'text-sm'
                  : 'text-base'
                : 'text-lg',
              length > 2 ? 'whitespace-nowrap' : 'whitespace-pre-wrap',
              'rounded-2xl flex-1 grid place-content-center px-3 py-2 shadow-neumorphism duration-300',
            )}
          >
            {display}
          </motion.li>
        ))}
      </ul>
      <ConfirmButton visible={visible} handleConfirm={handleConfirm} />
    </div>
  )
}

function MultiSelect({
  options,
  handleSubmit,
}: MultiSelectionQAPair & { handleSubmit: HandleSubmit }) {
  const [selectedList, setSelectedList] = useState<string[]>([])
  const [isConfirmed, setIsConfirmed] = useState(false)
  const visible = useMemo(
    () => selectedList.length > 0 && !isConfirmed,
    [selectedList, isConfirmed],
  )

  function handleSelect(e) {
    if (isConfirmed) return
    if (!e || e.target.nodeName !== 'LI') return
    const value = e.target.getAttribute('value') as string
    setSelectedList(preValue => {
      if (preValue.includes(value)) {
        return preValue.filter(item => item !== value)
      } else {
        return [...preValue, value]
      }
    })
  }

  async function handleConfirm() {
    setIsConfirmed(true)
    await handleSubmit(selectedList.join(','))
  }

  return (
    <div className="w-full flex flex-col justify-center items-end gap-4">
      <ul
        onPointerUp={handleSelect}
        className="w-full  rounded-2xl p-4 grid grid-flow-col-dense grid-rows-4 gap-2 overflow-x-scroll scroll-smooth scrollbar-hide"
      >
        {options.map(option => (
          <motion.li
            key={option}
            whileTap={{ scale: 0.9 }}
            value={option}
            className={clsx(
              selectedList.includes(option)
                ? 'bg-orchid text-gray-100'
                : 'bg-white text-gray-500',
              {
                'col-span-2': option.length <= 2,
                'col-span-3': option.length === 3,
                'col-span-4': option.length === 4,
                'col-span-5': option.length === 5,
                'col-span-6': option.length >= 6,
              },
              'rounded-2xl grid place-content-center whitespace-nowrap px-3 py-2 text-base shadow-neumorphism duration-300',
            )}
          >
            {option}
          </motion.li>
        ))}
      </ul>
      <ConfirmButton visible={visible} handleConfirm={handleConfirm} />
    </div>
  )
}

function ConfirmButton({
  visible,
  handleConfirm,
}: {
  visible: boolean
  handleConfirm: () => void
}) {
  if (!visible) return null
  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      onPointerDown={handleConfirm}
      className="rounded-full bg-orchid-gradient p-3 shadow-neumorphism duration-300"
    >
      <p className="text-sm text-gray-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
            clipRule="evenodd"
          />
        </svg>
      </p>
    </motion.button>
  )
}

export const questionStreamFetcher = async (
  route: string,
  body: QuestionBotAPIRequestBody,
  onmessage: (data: any) => void,
  onclose: () => void,
) => {
  return await fetchEventSource(
    `${import.meta.env.VITE_PUBLIC_HOST}${route}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      openWhenHidden: true,
      onmessage: ev => {
        // console.info('QuestionStream: SSE message received', ev)

        if (!ev.data) return // ping message

        let data = JSON.parse(ev.data)

        // console.info('QuestionStream: parsed event source message', data)

        onmessage(data)
      },
      // onclose() {
      //   // if the server closes the connection unexpectedly, DO NOT retry
      //   throw new Error('QuestionStream: SSE connection closed unexpectedly')
      // },
      onclose,
      onerror: ev => {
        toast.error('获取回答时出现了一个问题：' + ev)
        throw new Error('QuestionStream: SSE error: ' + ev)
      },
      async onopen(response) {
        if (response.ok) {
          return // everything's good
        }

        // if the server responds with an error, DO NOT retry
        throw new Error(
          'QuestionStream: SSE connection failed: ' +
            response.status +
            ' ' +
            response.statusText,
        )
      },
    },
  )
}
