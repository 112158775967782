
import { Suspense } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTitle } from '#/utils/useTitle'
import { useCurrentUserId } from '#/utils/useUser'
import { useAPIResponse } from '#/utils/useAPIResponse'
import MemeSlider from './components/meme-slide'
import ToHomeButton from './components/to-home-button'
import ShareProfileButton from './components/share-profile-button'
import UserProfileCard from '#/components/user-profile-card'
import RequestFriendButton from '#/components/request-friend-button'
import { UserProfile } from '#/types'

export default function Page() {
  const { id } = useParams() as { id: string }
  const [searchParams] = useSearchParams()

  const noRequestButton = searchParams.get('no-button') === 'true'

  const currentUserId = useCurrentUserId()
  const idForFetch = id === 'self' ? currentUserId : id

  const { data: userProfile } = useAPIResponse<UserProfile>(idForFetch ? '/profile/' + idForFetch : null)

  useTitle(userProfile?.username ?? '资料卡')

  const { data: userMemes } = useAPIResponse(idForFetch ? '/moment/meme/' + idForFetch : null)

  if (!userProfile || !userMemes) return null

  return (
    <div className="h-full w-full overflow-y-scroll scroll-smooth scrollbar-hide">
      <UserProfileCard userProfile={userProfile} userId={id}>
        {userMemes.length > 0 ? <MemeSlider userMemes={userMemes} /> : null}
      </UserProfileCard>
      <section className="absolute flex bottom-0 inset-x-0 m-8 gap-3">
        <ToHomeButton />
        {!noRequestButton && id === idForFetch ? (
          <Suspense fallback={null}>
            <RequestFriendButton className="flex-1" userId={id} />
          </Suspense>
        ) : null}
        {id === 'self' ? <ShareProfileButton className="flex-1" /> : null}
      </section>
    </div>
  )
}
