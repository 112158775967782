import HistoryList from "./@history-list/wrapped"
import FriendList from "./@friend-list/wrapped"
import { useCallback, useState } from "react"
import clsx from "clsx"
import ShareButton from './components/share-button'
import MomentButton from "./components/moment-button"

export type Tag = 'friend' | 'history'

export default function Page() {
  const [tag, setTag] = useState<Tag>('history')

  const HeaderTab = useCallback(() => {
    return (
      <main className="w-full pl-6 pt-3 pb-1 flex gap-1 items-center text-gray-800 text-lg">
        {
          [
            ['聊天', 'history'],
            ['好友', 'friend']
          ].map(([text, value]) => (
            <button
              key={value}
              onPointerUp={() => setTag(value as Tag)}
              className={
                clsx(
                  tag === value ? 'font-semibold' : 'text-gray-500',
                  'px-2 py-1'
                )
              }
            >{text}</button>
          )
          )
        }
      </main>
    )
  }, [tag, setTag])

  return (
    <main className="w-full h-full flex flex-col items-stretch overflow-hidden pt-safe">
      <section className="sticky top-0 inset-x-0 z-50 backdrop-blur">
        <ShareButton className="h-36 -mb-10 -mt-4 px-4 grid place-content-center" />
        <HeaderTab />
      </section>
      {tag === 'history' && <HistoryList />}
      {tag === 'friend' && <FriendList />}
      <MomentButton className="fixed bottom-28 right-6 mb-safe" />
    </main>
  )
}