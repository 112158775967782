import clsx from 'clsx'
import * as PANOLENS from 'panolens'
import { useEffect, useRef } from 'react'

// const Images: { src: string }[] = [
//   {
//     src: "https://mirro-file.hyperex.cc/SUDohWQGlFxuzh0WqkRu8jTbPzywyWbE/scene2.jpeg",
//   },
//   {
//     src: "https://mirro-file.hyperex.cc/XSxQD0peinpMab5Vh55ySUdbPqovWtVB/scene5.jpeg",
//   },
//   {
//     src: "https://mirro-file.hyperex.cc/X1gyYtqlRH4JSEeRfy5icn9YTunfU1E1/018e97611f743b11013f47203eb4cf%20%281%29.webp",
//   },
//   {
//     src: "https://mirro-file.hyperex.cc/q8U2dCxeaP7yJgEDHRNymizWyHRevOmo/scene4.jpeg",
//   },
//   {
//     src: "https://mirro-file.hyperex.cc/X5YOVNPx2ByfVMk0IcWm873Cp9c4Rqav/scene3.jpeg",
//   },
//   {
//     src: "https://mirro-file.hyperex.cc/fxeBMjSHlAvpylTdo8nBQJg8RTUSKCX4/scenic_on_a_beautiful_night_on_the_mar_s_human_bas-2.jpeg",
//   },
//   {
//     src: "https://mirro-file.hyperex.cc/AmwhDLJY8dzgfL1eUtiC5R1GEUMHQjtj/scene6.jpeg",
//   },
// ];

export default function GroupBackground(
  {
    disable,
    backgroundURL,
    className
  }: {
    disable: boolean
    backgroundURL?: string
    className: string
  }
) {
  return !disable ? <Panolens {...{backgroundURL,className}} /> : null
}

function Panolens({
  backgroundURL = 'https://mirro-file.hyperex.cc/X1gyYtqlRH4JSEeRfy5icn9YTunfU1E1/018e97611f743b11013f47203eb4cf%20%281%29.webp',
  className
}: {
  backgroundURL?: string
  className: string
}) {
  const panolensContainer = useRef<HTMLDivElement>(null)

  // 根据当前小时数选择一个对象
  // const currentHour = new Date().getHours();
  // const selectedImage = Images[currentHour % Images.length];

  useEffect(() => {
    if (!panolensContainer.current) return

    const panorama = new PANOLENS.ImagePanorama(backgroundURL)
    const view = new PANOLENS.Viewer({
      container: panolensContainer.current,
      controlBar: false,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      autoRotateActivationDuration: 5000,
      cameraFov: 140,
    })

    view.renderer.setClearColor(0xffffff)
    view.add(panorama)
    view.setCameraControl()

    return () => {
      panorama.reset()
      view.destroy()
    }
  }, [panolensContainer])

  return (
    <div ref={panolensContainer} className={clsx("panolens-container", className)} />
  )
}
