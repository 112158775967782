import React, { useState } from 'react'
import { Modal } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { requestAPI } from '#/utils/useAPIResponse'
import { useMutateStatus } from '../../status-swr'

export default function DeleteButton({ statusId }: { statusId: string }) {
  const [progress, setProgress] = useState<'display' | 'loading' | null>(null)

  const mutateStatus = useMutateStatus()

  async function handleDelete() {
    if (progress === 'loading') return
    setProgress('loading')

    await requestAPI(
      '/moment/' + statusId,
      {
        init: { method: 'DELETE' },
        needSession: true
      }
    )
    await mutateStatus()
    setProgress(null)
  }

  return (
    <main>
      <motion.button
        className="bg-[#8F9BB3]/30 backdrop-blur-sm rounded-full w-[30px] h-[30px] grid place-content-center"
        onClick={() => setProgress('display')}
        whileTap={{ scale: 0.9 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          />
        </svg>
      </motion.button>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={!!progress}
        onClose={() => setProgress(null)}
        css={{
          marginLeft: '2rem',
          marginRight: '2rem',
        }}
      >
        <Modal.Body>
          {
            progress === 'display'
              ? <p className="text-lg grid place-content-center">确认要删除此动态吗?</p>
              : <p className="text-lg grid place-content-center">正在删除...</p>
          }
        </Modal.Body>
        <Modal.Footer>
          {
            progress === 'display'
              ? (
                <motion.button
                  onTap={handleDelete}
                  whileTap={{ scale: 0.9 }}
                  className="min-w-fit bg-red-300/70 text-red-600 rounded-2xl px-4 py-2"
                >
                  删除
                </motion.button>
              ) : null
          }
        </Modal.Footer>
      </Modal>
    </main>
  )
}
